import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext } from "../../../context/auth";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { Add, Edit, ExpandMore } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import NewSectionModal from "./NewSectionModal";
import NewArticleModal from "./NewArticleModal";
import parse from "html-react-parser";
import EditArticleModal from "./EditArticleModal";
import EditSectionModal from "./EditSectionModal";
import ConfirmationDialogActivator from "../../../components/confirmation-dialog/confirmation-dialog-activator";
import RoleTypes from "../../../enums/role-types";
import ProfileTypes from "../../../enums/profile-types";
import { useHistory } from "react-router-dom";

const EditHelp = () => {
  const { user, userRole } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [sections, setSections] = useState(null as any);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [activeArticleId, setActiveArticleId] = useState("");
  const [newSectionModalOpened, setNewSectionModalOpened] = useState(false);
  const [newArticleModalOpened, setNewArticleModalOpened] = useState(false);
  const [editArticleModalOpened, setEditArticleModalOpened] = useState(false);
  const [articleBeingEdited, setArticleBeingEdited] = useState(null);
  const [editSectionModalOpened, setEditSectionModalOpened] = useState(false);
  const [sectionBeingEdited, setSectionBeingEdited] = useState(null);
  const [profile, setProfile] = useState<any>(null);
  const history = useHistory();
  const [showEditHelpPage, setShowEditHelpPage] = useState(false);

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name !== ProfileTypes.SONGWRITER;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowEditHelpPage(true);
      startGlobalLoader();
      API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
        (response) => {
          setProfile(response.data);
          stopGlobalLoader();
        },
        (error) => {
          initSnackbarError(error);
          stopGlobalLoader();
        }
      );
    }
  }, []);

  useEffect(() => {
    if (sections) return;
    else {
      startGlobalLoader();
      API.get(
        ApiUri.PROFILE + "/" + user?.last_accessed_profile?.id + ApiUri.HELPER,
        {
          params: {
            limit: 100000,
            page: 1,
          },
        }
      ).then(
        (response) => {
          const allSections = response.data.collection;
          const activeSections = allSections.filter(
            (section) => section.status === true
          );
          setSections(activeSections);
          stopGlobalLoader();
        },
        (err) => {
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    }
  }, [sections]);

  const handleChangeSection = (
    event: React.SyntheticEvent,
    newActiveSectionIndex: number
  ) => {
    setActiveSectionIndex(newActiveSectionIndex);
  };

  const handleArticleChange =
    (articleId: string) => (event: React.SyntheticEvent) => {
      activeArticleId === articleId
        ? setActiveArticleId("")
        : setActiveArticleId(articleId);
    };

  const handleDeleteSection = () => {
    startGlobalLoader();
    const activeSection = sections[activeSectionIndex];
    const formData = activeSection;
    formData.status = false;
    API.put(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        "/helper" +
        "/" +
        activeSection.id,
      formData
    ).then(
      (response) => {
        initSnackbarSuccess("Section deleted");
        stopGlobalLoader();
        setActiveArticleId("");
        setActiveSectionIndex(0);
        setSections(null);
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleDeleteArticle = (articleId) => {
    const activeSection = sections[activeSectionIndex];
    const articleIndex = activeSection.articles.findIndex(
      (article) => article.id === articleId
    );
    startGlobalLoader();
    const formData = activeSection;
    formData.articles[articleIndex].status = false;
    API.put(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        "/helper" +
        "/" +
        activeSection.id,
      formData
    ).then(
      (response) => {
        initSnackbarSuccess("Article deleted");
        stopGlobalLoader();
        setActiveArticleId("");
        setSections(null);
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const validArticles =
    sections && sections[activeSectionIndex]
      ? sections[activeSectionIndex].articles.filter(
          (article) => article.status === true
        )
      : null;

  const switchArticlesOrder = (article, otherArticle) => {
    startGlobalLoader();
    API.put(
      "/helper" +
        "/" +
        sections[activeSectionIndex].id +
        "/article" +
        "/" +
        article.id +
        "/change-order",
      { new_order: otherArticle.order_number }
    ).then(
      (response) => {
        initSnackbarSuccess("Article moved");
        stopGlobalLoader();
        setSections(null);
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const currentUserRole = profile?.profile_rbac.find(
    (profile) => profile.user.id === user?.id
  )?.role.name;

  const disableEverything =
    currentUserRole !== RoleTypes.OWNER &&
    currentUserRole !== RoleTypes.ADMIN &&
    !user?.is_mmpz_service_admin;

  return (
    showEditHelpPage && (
      <React.Fragment>
        <Stack spacing={2} marginBottom={2} maxWidth={"70vw"}>
          <ActionButtonSmall
            onClick={() => setNewSectionModalOpened(true)}
            disabled={disableEverything}
          >
            <Add />
            Add new section
          </ActionButtonSmall>
          {sections && (
            <Stack spacing={5}>
              <Tabs
                value={activeSectionIndex}
                onChange={handleChangeSection}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {sections.map((section) => (
                  <Tab
                    label={section.title}
                    key={section.id}
                    disabled={disableEverything}
                  />
                ))}
              </Tabs>
              <Stack spacing={2}>
                {activeSectionIndex !== null &&
                  sections[activeSectionIndex] &&
                  validArticles.map((article, index) => {
                    return (
                      <Accordion
                        key={index}
                        expanded={activeArticleId === article.id}
                        onChange={handleArticleChange(article.id)}
                        disabled={disableEverything}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          disabled={disableEverything}
                        >
                          {article.title}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Divider sx={{ marginBottom: "10px" }} />
                          {parse(article.content)}
                          <Stack direction={"row"} spacing={2}>
                            <ActionButtonSmall
                              onClick={() => {
                                setArticleBeingEdited(article);
                                setEditArticleModalOpened(true);
                              }}
                              disabled={disableEverything}
                            >
                              Edit Article
                            </ActionButtonSmall>
                            {index > 0 && (
                              <ActionButtonSmall
                                disabled={disableEverything}
                                onClick={() =>
                                  switchArticlesOrder(
                                    article,
                                    validArticles[index - 1]
                                  )
                                }
                              >
                                Move Up
                              </ActionButtonSmall>
                            )}
                            {index < validArticles.length - 1 && (
                              <ActionButtonSmall
                                onClick={() =>
                                  switchArticlesOrder(
                                    article,
                                    validArticles[index + 1]
                                  )
                                }
                                disabled={disableEverything}
                              >
                                Move down
                              </ActionButtonSmall>
                            )}
                            <ConfirmationDialogActivator
                              disabled={disableEverything}
                              actionName="Delete Article"
                              content="Are you sure you want to delete this article?"
                              type="button"
                              onClick={() => handleDeleteArticle(article.id)}
                            />
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                {activeSectionIndex !== null &&
                  sections[activeSectionIndex] && (
                    <Stack direction={"row"} spacing={2}>
                      <ActionButtonSmall
                        disabled={disableEverything}
                        onClick={() => setNewArticleModalOpened(true)}
                      >
                        <Add />
                        Add new Article
                      </ActionButtonSmall>
                      <ActionButtonSmall
                        disabled={disableEverything}
                        onClick={() => {
                          setSectionBeingEdited(sections[activeSectionIndex]);
                          setEditSectionModalOpened(true);
                        }}
                      >
                        Edit Section
                      </ActionButtonSmall>
                      <ConfirmationDialogActivator
                        disabled={disableEverything}
                        actionName="Delete Section"
                        content="Are you sure you want to delete this section?"
                        type="button"
                        onClick={handleDeleteSection}
                      />
                    </Stack>
                  )}
              </Stack>
            </Stack>
          )}
        </Stack>
        {newSectionModalOpened && (
          <NewSectionModal
            modalOpened={newSectionModalOpened}
            handleClose={() => setNewSectionModalOpened(false)}
            setSections={setSections}
          />
        )}
        {newArticleModalOpened && (
          <NewArticleModal
            modalOpened={newArticleModalOpened}
            handleClose={() => setNewArticleModalOpened(false)}
            setSections={setSections}
            section={sections ? sections[activeSectionIndex] : null}
          />
        )}
        {editArticleModalOpened && (
          <EditArticleModal
            modalOpened={editArticleModalOpened}
            handleClose={() => setEditArticleModalOpened(false)}
            setSections={setSections}
            section={sections ? sections[activeSectionIndex] : null}
            articleBeingEdited={articleBeingEdited}
          />
        )}
        {editSectionModalOpened && (
          <EditSectionModal
            modalOpened={editSectionModalOpened}
            handleClose={() => setEditSectionModalOpened(false)}
            setSections={setSections}
            sectionBeingEdited={sectionBeingEdited}
          />
        )}
      </React.Fragment>
    )
  );
};

export default EditHelp;
// MOVE ARTICLE NOT WORKING ON THE BACKEND

// LEFT TO DO: DRAFT/PUBLISHED ARTICLES, REARANGING ARTICLES AND SECTIONS
