import Axios from "axios";
import ApiUri from "./api-uri";
import * as SnackBar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";

console.log("process.env.REACT_APP_API_URL: ", process.env.REACT_APP_API_URL);

const testSite =
  window.location.hostname.includes("test") ||
  window.location.hostname.includes("localhost");

const axios = Axios.create({
  baseURL: `https://api.mmpz.${testSite ? "test." : ""}myclientzone.com/api/v1`,
  withCredentials: true,
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (window.location.pathname === "/accounts-app-registration") {
      return Promise.reject(error);
    }

    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/sub-profiles/new"
    ) {
      SnackBar.show({
        showAction: false,
        text: "User unauthorized, redirecting...",
        pos: "bottom-center",
      });
      window.location.replace(
        `https://mmaz.${testSite ? "test." : ""}myclientzone.com` + ApiUri.MMPZ
      );
    }

    if (error?.response?.status === 403) {
      if (error.response.data.error === "Token issue") {
        SnackBar.show({
          showAction: false,
          text: "Session expired, reauthenticating ...",
          pos: "bottom-center",
        });
        window.location.replace(
          `https://api.mmpz.${testSite ? "test." : ""}myclientzone.com/api/v1` +
            ApiUri.TOKEN_REFRESH
        );
      } else {
        // redirecting to mmaz if 403
        window.location.replace(
          `https://mmaz.${testSite ? "test." : ""}myclientzone.com`
        );
        // return Promise.reject(error);
      }
    }

    if (error?.response?.status === 409) {
      if (
        error.response.data.error ===
        "You are not allowed to access this application"
      ) {
        SnackBar.show({
          showAction: false,
          text: "User revoked, redirecting...",
          pos: "bottom-center",
        });
        window.location.replace(
          `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
            "/dashboard?ref=mmpz&error=no_auth"
        );
      } else {
        return Promise.reject(error);
      }
    }

    if (error?.response?.status === 503) {
      window.location.replace(
        `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
          "/site-maintenance-mode"
      );
    }
    return Promise.reject(error);
  }
);

export default axios;
