import React, { useContext, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import { Product } from "../../../../models/products/product";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

interface IContextMenu {
  ps: Product;
  packages: any;
  setPackages: any;
  setTotalRows: any;
}

export default function ContextMenu({
  ps,
  packages,
  setPackages,
  setTotalRows,
}: IContextMenu) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    startGlobalLoader();
    API.delete(ApiUri.PACKAGE + "/" + ps.id).then(
      (response) => {
        initSnackbarSuccess("Succesfully deleted ");
        const newPackages = packages.filter((row) => row.id !== ps.id);
        setPackages(newPackages);
        setTotalRows(newPackages.length);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      },
    );
    return () => {
      handleClose();
    };
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </React.Fragment>
  );
}
