import { Badge, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import ColorPicker from "./ColorPicker";
import { CustomisationContext } from "../../../context/customisation";
import { GlobalLoaderContext } from "../../../context/global-loader";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import { darkenColor, getContrastColor } from "./utils";
import Colors from "../../../enums/colors";
import { Info } from "@mui/icons-material";
import FQCN from "../../../enums/FQCN";
import RoleTypes from "../../../enums/role-types";
import { getFile } from "../../../api/utils/get/getFile";
import { getCompleteProfile } from "../../../api/utils/get/getCompleteProfile";
import { putProfile } from "../../../api/utils/put/putProfile";
import { postFile } from "../../../api/utils/post/postFile";

const Customization = ({ currentUserRole }) => {
  const { customisation, setCustomisation } = useContext(CustomisationContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { user } = useContext(AuthContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [logoURL, setLogoURL] = useState(undefined as any);
  const [newLogo, setNewLogo] = useState(null as any);

  const [colors, setColors] = useState([
    {
      name: "Primary Color",
      hex: customisation?.mainColor,
      title: "The color assigned to the sidebar and the topbar",
    },
    {
      name: "Button Color",
      hex: customisation?.buttonColor,
      title: "The color assigned to every button",
    },
  ]);

  useEffect(() => {
    const imageId = customisation?.logo?.id;
    const getLogo = async () => {
      startGlobalLoader();
      try {
        const logoResponse = await getFile({
          fileId: imageId,
          responseType: "blob",
        });
        const logo = logoResponse.data;
        setLogoURL(URL.createObjectURL(logo));
      } catch (err) {
        initSnackbarError(err);
      } finally {
        stopGlobalLoader();
      }
    };

    if (imageId) {
      getLogo();
    }
  }, []);

  const handleImageChange = function loadFile(event) {
    if (event.target.files.length > 0) {
      const url = URL.createObjectURL(event.target.files[0]);
      setNewLogo({
        url: url,
        name: event.target.files[0].name,
        file: event.target.files[0],
      });
    }
  };

  const updateColor = (colorName, newColorValue) => {
    const newColors = colors.map((color) => {
      if (color.name === colorName) {
        const newColor = { ...color, hex: newColorValue };
        return newColor;
      } else {
        return color;
      }
    });
    setColors(newColors);
  };

  const handleSave = async () => {
    startGlobalLoader();

    try {
      if (!newLogo) {
        // there is no new logo, so we do the normal update

        const newProfileResponse = await getCompleteProfile({
          profileId: user?.last_accessed_profile.id,
        });
        const newProfile = newProfileResponse.data;
        newProfile.profile_information.main_color = colors[0].hex;
        newProfile.profile_information.text_color = getContrastColor(
          colors[0].hex
        );
        newProfile.profile_information.background_color = darkenColor(
          colors[0].hex,
          20
        );
        newProfile.profile_information.button_color = colors[1].hex;

        await putProfile({
          profileId: user?.last_accessed_profile.id,
          formData: newProfile,
        });
        initSnackbarSuccess("Profile successfully edited");
        setCustomisation((oldCustomization) => {
          return {
            ...oldCustomization,
            mainColor: colors[0].hex,
            textColor: getContrastColor(colors[0].hex),
            backgroundColor: darkenColor(colors[0].hex, 20),
            buttonColor: colors[1].hex,
          };
        });
      } else {
        const formData = new FormData();
        formData.append("name", newLogo.file.name);
        formData.append("file", newLogo.file);

        const fileResponse = await postFile({
          formData,
          params: {
            object_fqcn: FQCN.PROFILE,
            object_id: user?.last_accessed_profile.id,
          },
        });
        // image posted, got complete file object
        const file = fileResponse.data;
        const completeProfileResponse = await getCompleteProfile({
          profileId: user?.last_accessed_profile.id,
        });
        const completeProfile = completeProfileResponse.data;
        completeProfile.profile_information.main_color = colors[0].hex;
        completeProfile.profile_information.text_color = getContrastColor(
          colors[0].hex
        );
        completeProfile.profile_information.background_color = darkenColor(
          colors[0].hex,
          20
        );
        completeProfile.profile_information.button_color = colors[1].hex;
        completeProfile!.profile_information.file = file;
        await putProfile({
          profileId: completeProfile!.id,
          formData: completeProfile,
        });
        setCustomisation((oldCustomization) => {
          return {
            ...oldCustomization,
            mainColor: colors[0].hex,
            textColor: getContrastColor(colors[0].hex),
            backgroundColor: darkenColor(colors[0].hex, 20),
            buttonColor: colors[1].hex,
            logo: completeProfile?.profile_information.file,
          };
        });
        initSnackbarSuccess(`Edited Succesfully`);
      }
    } catch (error) {
      initSnackbarError(error);
    } finally {
      stopGlobalLoader();
    }

    // Initializing save process
  };

  return (
    <Grid container spacing={4}>
      <Grid item container xs={12} sm={4}>
        <Stack
          spacing={3}
          direction="row"
          justifyContent="space-around"
          paddingTop={3}
        >
          {colors.map((color, index) => {
            return (
              <ColorPicker
                updateColor={updateColor}
                color={color}
                key={index}
              />
            );
          })}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} marginTop={3}>
        <Stack spacing={1} width="300px">
          <Stack spacing={1}>
            <Tooltip
              title="The logo used on the top bar of the website"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <Stack alignItems={"center"}>
              <Typography variant="h5">Logo</Typography>
            </Stack>

            {newLogo ? (
              <img
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "contain",
                }}
                src={newLogo.url as any}
              />
            ) : customisation?.logo ? (
              <img
                style={{
                  width: "300px",
                  height: "150px",
                  objectFit: "contain",
                }}
                src={logoURL}
              />
            ) : (
              <Stack
                width="300px"
                height="150px"
                sx={{ backgroundColor: Colors.grey }}
                justifyContent="center"
                alignItems="center"
              >
                {" "}
                300x150{" "}
              </Stack>
            )}
          </Stack>

          <ActionButtonSmall
            component="label"
            disabled={
              currentUserRole !== RoleTypes.OWNER &&
              currentUserRole !== RoleTypes.ADMIN &&
              !user?.is_mmpz_service_admin
            }
          >
            Upload Image
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </ActionButtonSmall>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12}>
        <ActionButtonSmall
          onClick={handleSave}
          disabled={
            currentUserRole !== RoleTypes.OWNER &&
            currentUserRole !== RoleTypes.ADMIN &&
            !user?.is_mmpz_service_admin
          }
        >
          Save
        </ActionButtonSmall>
      </Grid>
    </Grid>
  );
};

export default Customization;
