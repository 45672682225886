import { combineComponents } from "./context/combine-components";
import AuthContextProvider from "./context/auth";
import GlobalLoaderContextProvider from "./context/global-loader";
import SnackbarContextProvider from "./context/snackbar";
import CustomisationContextProvider from "./context/customisation";
import { TwoFactorAuthenticationTimerProvider } from "./context/two-factor-authentication-timer";

const providers = [
  AuthContextProvider,
  CustomisationContextProvider,
  GlobalLoaderContextProvider,
  SnackbarContextProvider,
  TwoFactorAuthenticationTimerProvider,
];
export const AppContextProvider = combineComponents(...providers);
