import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function SmallLoader() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <CircularProgress size={40} color="primary" />
    </Box>
  );
}
