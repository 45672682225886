import API from "../../api";
import ApiUri from "../../api-uri";

export const deleteCommunicationSetting = async ({ profileId, itemId }) => {
  try {
    const response = await API.delete(
      ApiUri.PROFILE +
        "/" +
        profileId +
        ApiUri.COMMUNICATION_SETTINGS +
        "/" +
        itemId
    );
    return response;
  } catch (err) {
    throw err;
  }
};
