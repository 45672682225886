import {
  Grid,
  FormControl,
  TextField,
  Card,
  Fab,
  Typography,
  Stack,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import { Performer } from "../../../../models/song/performer";
import { Song } from "../../../../models/song/song";
import { Delete } from "@mui/icons-material";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { Check } from "@material-ui/icons";

interface IPerformerForm {
  performer?: Performer;
  song: Song;
  createPerformerCallback: Function;
  deletePerformerCallback: Function;
}

const newPerformerInitialData: Performer = {
  id: 0,
  name: "",
};

export default function PerformerForm({
  performer,
  song,
  createPerformerCallback,
  deletePerformerCallback,
}: IPerformerForm) {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarGeneric, initSnackbarError } =
    useContext(SnackbarContext);

  const [formData, setFormData] = useState<Performer>(
    performer || newPerformerInitialData
  );

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((previousState) => ({ ...previousState, [name]: value }));
  };

  const handleSaveClick = () => {
    startGlobalLoader();

    if (formData.id) {
      API.put(
        ApiUri.SONG + "/" + song.id + ApiUri.PERFORMER + "/" + formData.id,
        formData
      ).then(
        (response) => {
          setFormData(response.data);
          stopGlobalLoader();
          initSnackbarGeneric("Performer successfully updated");
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    } else {
      API.post(ApiUri.SONG + "/" + song.id + ApiUri.PERFORMER, formData).then(
        (response) => {
          setFormData(response.data);
          stopGlobalLoader();
          initSnackbarGeneric("Performer successfully added");
          createPerformerCallback(response.data);
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    }
  };

  const handleDeleteClick = () => {
    if (formData.id) {
      startGlobalLoader();

      API.delete(
        ApiUri.SONG + "/" + song.id + ApiUri.PERFORMER + "/" + formData.id
      ).then(
        (response) => {
          setFormData(response.data);
          stopGlobalLoader();
          initSnackbarGeneric("Performer successfully removed");
          deletePerformerCallback(formData);
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    } else {
      setFormData((previousState) => ({ ...previousState, name: "" }));
    }
  };

  return (
    <React.Fragment>
      <Card elevation={4} sx={{ p: 2, width: "50%" }}>
        <Grid direction="row" container spacing={2} alignItems={"center"}>
          {!formData.id ? (
            <React.Fragment>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    value={formData.name || ""}
                    onChange={handleFieldChange}
                    required={true}
                    type="text"
                    label="Name"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Fab size="small" color="primary" onClick={handleSaveClick}>
                  <Check />
                </Fab>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={10}>
              <Typography>{formData.name}</Typography>
            </Grid>
          )}

          <Grid item xs={2}>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Fab size="small" onClick={handleDeleteClick}>
                <Delete />
              </Fab>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
