import React, { useEffect, useState } from "react";
import { SongWriter } from "../models/composition/song-writer";
import { Composition } from "../models/composition/composition";
import Constants from "../enums/constants";

export interface ISongWritersPercentageShareContext {
  addShare: Function;
  subtractShare: Function;
  totalShare: number;
  isTotalShareValid: boolean;
  calculateAnaraSongwritersShare: Function;
}

interface ISongWritersPercentageShareContextProps {
  composition: Composition;
  children: any;
}

export const SongWritersPercentageShareContext = React.createContext(
  {} as ISongWritersPercentageShareContext
);

const SongWritersPercentageShareContextProvider = ({
  composition,
  children,
}: ISongWritersPercentageShareContextProps) => {
  const [isTotalShareValid, setIsTotalShareValid] = useState(false);
  const [totalShare, setTotalShare] = useState(0);

  useEffect(() => {
    let total = Number(parseFloat("0").toFixed(2));

    if (!composition.song_writers) {
      return;
    }

    composition.song_writers.forEach((songWriter: SongWriter) => {
      total = +(total + songWriter.share).toFixed(2);
    });

    setTotalShare(total);
    setIsTotalShareValid(total === 100);
  }, []); // eslint-disable-line

  const addShare = (share) => {
    if (!parseFloat(share)) {
      return;
    }

    const total = +(totalShare + parseFloat(share)).toFixed(2);
    setTotalShare(total);
    setIsTotalShareValid(total === 100);
  };

  const subtractShare = (share) => {
    if (!parseFloat(share)) {
      return;
    }

    const total = +(totalShare - parseFloat(share)).toFixed(2);
    setTotalShare(total);
    setIsTotalShareValid(total === 100);
  };

  const calculateAnaraSongwritersShare = () => {
    let percentage = 0;
    composition.song_writers.forEach((songWriter: SongWriter) => {
      if (!songWriter.external_song_writer) {
        percentage += songWriter.share;
      }
    });
    return percentage;
  };

  return (
    <SongWritersPercentageShareContext.Provider
      value={{
        addShare,
        subtractShare,
        totalShare,
        isTotalShareValid,
        calculateAnaraSongwritersShare,
      }}
    >
      {children}
    </SongWritersPercentageShareContext.Provider>
  );
};

export default SongWritersPercentageShareContextProvider;
