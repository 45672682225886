import DateFnsUtils from "@date-io/date-fns";
import {
  Badge,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Info } from "@mui/icons-material";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useContext, useState } from "react";
import { Performer } from "../../../../../models/song/performer";
import { Song } from "../../../../../models/song/song";
import PerformerForm from "../../common-components/performer-form";
import ArtistForm from "../../common-components/ArtistForm";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import { SnackbarContext } from "../../../../../context/snackbar";

interface IFormStepOne {
  song: Song;
  setSong: Function;
}

export default function FormStepOne({ song, setSong }: IFormStepOne) {
  const { initSnackbarError } = useContext(SnackbarContext);

  const [showNewPerformerForm, setShowNewPerformerForm] = useState(false);
  const [showNewArtistForm, setShowNewArtistForm] = useState(false); // State to toggle the new artist form
  const [newArtist, setNewArtist] = useState(""); // To store the new artist name

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const toogleShowNewPerformerForm = () => {
    setShowNewPerformerForm(!showNewPerformerForm);
  };

  const toggleShowNewArtistForm = () => {
    setShowNewArtistForm(!showNewArtistForm);
  };

  const createPerformerCallback = (performer: Performer) => {
    const performers = song.performers;
    performers.push(performer);
    setSong((previousState) => ({ ...previousState, performers: performers }));
    toogleShowNewPerformerForm();
  };

  const deletePerformerCallback = (performer: Performer) => {
    let performers = song.performers;
    performers = performers.filter((item) => performer.id !== item.id);
    setSong((previousState) => ({ ...previousState, performers: performers }));
  };

  const handleFieldChange = (event: any) => {
    let { name, value } = event.target;

    if (name === "is_instrumental") {
      value = event.target.checked;
    }

    if (name === "is_ppl_registered") {
      value = event.target.value === "true";
    }

    setSong((previousState) => ({ ...previousState, [name]: value }));
  };

  const handleArtistNameFieldChange = (event: any) => {
    const recordingArtist = {
      id: null,
      name: event.target.value,
    };
    // change
    setSong((previousState) => ({
      ...previousState,
      recording_artist: recordingArtist,
    }));
  };

  const handleReleaseDateFieldChange = (date) => {
    setSong((previousState) => ({
      ...previousState,
      release_date: date?.toISOString(),
    }));
  };

  const addNewArtist = () => {
    if (newArtist.trim()) {
      const isFirstArtist = (song.artists || []).length === 0;

      // Add the new artist with `main: true` if they are the first artist
      const updatedArtists = [
        ...(song.artists || []),
        { name: newArtist, main: isFirstArtist },
      ];

      // Ensure only the first artist has `main: true`
      updatedArtists.forEach((artist, index) => {
        artist.main = index === 0;
      });

      // Update the state with the new list of artists
      setSong((previousState) => ({
        ...previousState,
        artists: updatedArtists,
      }));

      setNewArtist(""); // Clear the input field after adding
      toggleShowNewArtistForm(); // Hide the new artist form
    }
  };

  // Function to handle changing an artist's name
  const handleArtistNameChange = (event, index) => {
    const updatedArtists = [...song.artists];
    updatedArtists[index].name = event.target.value;

    setSong((previousState) => ({
      ...previousState,
      artists: updatedArtists,
    }));
  };

  // Function to delete an artist
  const deleteArtist = (index) => {
    const updatedArtists = [...song.artists];
    updatedArtists.splice(index, 1);

    // Ensure the first artist is always the main artist
    if (updatedArtists.length > 0) {
      updatedArtists[0].main = true;
      for (let i = 1; i < updatedArtists.length; i++) {
        updatedArtists[i].main = false;
      }
    }

    setSong((previousState) => ({
      ...previousState,
      artists: updatedArtists,
    }));
  };

  const createArtistCallback = (newArtist: { name: string; main: boolean }) => {
    const updatedArtists = [...(song.artists || []), newArtist];

    // Ensure the first artist has main: true
    updatedArtists.forEach((artist, index) => {
      artist.main = index === 0;
    });

    setSong((prevState) => ({
      ...prevState,
      artists: updatedArtists,
    }));

    toggleShowNewArtistForm(); // Hide the new artist form after adding
  };

  const deleteArtistCallback = (artistToDelete: {
    id?: number;
    name: string;
    main: boolean;
  }) => {
    if (artistToDelete.id) {
      startGlobalLoader();
      API.delete(ApiUri.ARTIST + "/" + artistToDelete.id).then(
        (response) => {
          const updatedArtists = song.artists.filter(
            (artist) => artist.name !== artistToDelete.name
          );

          // If an artist is deleted, ensure the first artist is always set to main: true
          updatedArtists.forEach((artist, index) => {
            artist.main = index === 0;
          });

          setSong((prevState) => ({
            ...prevState,
            artists: updatedArtists,
          }));
          stopGlobalLoader();
        },
        (error) => {
          stopGlobalLoader();
        }
      );
    } else {
      const updatedArtists = song.artists.filter(
        (artist) => artist.name !== artistToDelete.name
      );

      // If an artist is deleted, ensure the first artist is always set to main: true
      updatedArtists.forEach((artist, index) => {
        artist.main = index === 0;
      });

      setSong((prevState) => ({
        ...prevState,
        artists: updatedArtists,
      }));
    }
  };

  const checkISRC = () => {
    if (song?.isrc) {
      startGlobalLoader();
      API.get(ApiUri.SONG + ApiUri.BY_ISRC, {
        params: { isrc: song?.isrc },
      }).then(
        (response) => {
          if (response?.data?.id) {
            initSnackbarError("The ISRC has been found in another song");
            setSong((previousState) => ({ ...previousState, isrc: "" }));
          }
          stopGlobalLoader();
        },
        (error) => {
          stopGlobalLoader();
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Tooltip
              title="The name of the single, EP or album release this recording is released on"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              required
              label="Release Name"
              name="release_name"
              value={song.release_name || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Tooltip
              title="ISRC = International Standard Recording Code, a unique 12 digit code assigned to a recording. Formatted with dashes, e.g GB-ABC-11-12345."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              label="ISRC"
              name="isrc"
              value={song.isrc || ""}
              onBlur={checkISRC}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="overline">Artists *</Typography>
          <br></br>
          {song?.artists?.map((artist, index) => {
            return (
              <React.Fragment key={index}>
                <ArtistForm
                  artist={artist}
                  song={song}
                  createArtistCallback={() => {}}
                  deleteArtistCallback={deleteArtistCallback}
                />
                <br></br>
              </React.Fragment>
            );
          })}
          <Fab
            size="small"
            variant="extended"
            color="primary"
            onClick={toggleShowNewArtistForm}
          >
            Add Artist
            <Add />
          </Fab>
          {showNewArtistForm ? (
            <ArtistForm
              song={song}
              createArtistCallback={createArtistCallback}
              deleteArtistCallback={() => {}}
            />
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Tooltip
              title="The date this recording was released"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Release Date"
                format="MMMM d, yyyy"
                value={song.release_date ? song.release_date : null}
                onChange={handleReleaseDateFieldChange}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Enter the name of the record label who released this. Leave blank if there is no label"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              name="label"
              label="Record Label Name"
              value={song.label || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Unique UPC/ERN for the release, usually 13 digits"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              name="barcode"
              label="Barcode"
              value={song.barcode || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Will be automatically detected upon upload of an audio file"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField disabled value={song.duration || ""} label="Duration" />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Will be automatically detected upon upload of an audio file"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              disabled
              type="number"
              value={song.bpm || ""}
              label="BPM"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormGroup row>
            <Typography variant="overline" style={{ marginTop: 10 }}>
              Is this recording registered with PPL in the UK? *
            </Typography>
            <Tooltip
              title="Why are we asking this?

              In order for songs to be put forward to sync opportunities for TV series that broadcast in the UK, the song must be registered with PPL. If the song isn’t registered with PPL, please select 'No' and contact your Publisher for more information."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
          </FormGroup>

          <RadioGroup
            name="is_ppl_registered"
            value={song.is_ppl_registered || null}
            onChange={handleFieldChange}
          >
            <FormControlLabel
              checked={song.is_ppl_registered === false}
              value={false}
              control={<Radio />}
              label="No"
            />
            <FormControlLabel
              checked={song.is_ppl_registered === true}
              value={true}
              control={<Radio />}
              label="Yes"
            />
          </RadioGroup>
        </Grid>

        {song.is_ppl_registered ? (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Tooltip
                title="Please enter the 9 digit PPL ID for this recording"
                placement="top-end"
              >
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={<Info color="disabled" />}
                />
              </Tooltip>
              <TextField
                type="text"
                name="ppl_id"
                value={song.ppl_id || ""}
                onChange={handleFieldChange}
                label="PPL ID"
                required
              />
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant="overline">Performers *</Typography>
          <br></br>
          {song?.performers?.map((performer: Performer) => {
            return (
              <React.Fragment key={performer.id}>
                <PerformerForm
                  performer={performer}
                  song={song}
                  createPerformerCallback={() => {}}
                  deletePerformerCallback={deletePerformerCallback}
                />
                <br></br>
              </React.Fragment>
            );
          })}
          <Fab
            size="small"
            variant="extended"
            color="primary"
            style={{ marginBottom: 10 }}
            onClick={toogleShowNewPerformerForm}
          >
            Add Performer
            <Add />
          </Fab>
          {showNewPerformerForm ? (
            <PerformerForm
              song={song}
              createPerformerCallback={createPerformerCallback}
              deletePerformerCallback={() => {}}
            />
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

