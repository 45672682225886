import React, { useContext } from "react";
import { AuthContext } from "./context/auth";
import NavigationContextProvider from "./context/navigation";
import Breadcrumbs from "./layout/breadcrumbs";
import { Route } from "react-router-dom";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props: any) =>
        user ? (
          <React.Fragment>
            <NavigationContextProvider>
              <Breadcrumbs />
            </NavigationContextProvider>
            <Component {...props} />
          </React.Fragment>
        ) : (
          window.location.replace(
            `https://mmaz.${testSite ? "test." : ""}myclientzone.com`
          )
        )
      }
    />
  );
}

export default PrivateRoute;
