import React from "react";
import SongsTable from "./songs-table/index";

export default function ManageSongs() {
  return (
    <React.Fragment>
      <SongsTable />
    </React.Fragment>
  );
}
