import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Song } from "../../../../../models/song/song";
import FileUpload from "../../../../../components/utilities/file-upload";
import FQCN from "../../../../../enums/FQCN";
import ApiUri from "../../../../../api/api-uri";
import "react-h5-audio-player/lib/styles.css";
import FileDownload from "../../../../../components/utilities/file-download";
import FileRemove from "../../../../../components/utilities/file-remove";
import { SnackbarContext } from "../../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import API from "../../../../../api/api";

interface IFormStepThree {
  song: Song;
  setSong: Function;
}

export default function FormStepThree({ song, setSong }: IFormStepThree) {
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const buildSongArtworkUploadUri = () => {
    return ApiUri.SONG + "/" + song.id + ApiUri.ARTWORK;
  };

  const handleUploadSuccess = (data: any) => {
    setSong((previousState) => ({ ...previousState, artwork: data }));
  };

  const handleRemoveImage = () => {
    startGlobalLoader();
    API.delete(ApiUri.SONG + "/" + song.id + ApiUri.ARTWORK).then(
      (response) => {
        setSong((previousState) => ({
          ...previousState,
          artwork: null,
        }));
        initSnackbarSuccess("Artwork removed");
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="overline">Song Artwork *</Typography>
        </Grid>
        <Grid item>
          {song.artwork ? (
            <React.Fragment>
              <FileUpload
                objectFQCN={FQCN.SONG}
                objectId={song.id}
                callback={handleUploadSuccess}
                showBackground={true}
                customBackground={song?.artwork?.file?.url}
                uri={buildSongArtworkUploadUri()}
                accept=".jpg, .jpeg"
              />
              <Stack p={1} spacing={1} direction={"row"}>
                <FileDownload file={song.artwork?.file} />
                <FileRemove callback={() => handleRemoveImage()} />
              </Stack>
            </React.Fragment>
          ) : (
            <FileUpload
              objectFQCN={FQCN.SONG}
              objectId={song.id}
              callback={handleUploadSuccess}
              showBackground={true}
              uri={buildSongArtworkUploadUri()}
              accept=".jpg, .jpeg, .png"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Requirements: 1500 x 1500 pixels, .jpg, .png
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
