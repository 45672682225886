import API from "../../api";
import ApiUri from "../../api-uri";

export const getAllSongs = async ({ profileId }) => {
  try {
    const response = await API.get(
      ApiUri.PROFILE + "/" + profileId + ApiUri.SONG,
      {
        params: {
          limit: 100000000,
          page: 1,
          search_term: "",
        },
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};
