import React, { useContext, useState, useEffect } from "react";
import { MessagesContext } from "../context/messages";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import axios from "axios";
import { Message } from "../models/message/notification";
import { AuthContext } from "../context/auth";

const PopupMessages = () => {
  const { messages, setMessages } = useContext(MessagesContext);
  const { user } = useContext(AuthContext);
  const [currentMessage, setCurrentMessage] = useState<Message | null>(null);

  useEffect(() => {
    const currentDate = new Date();
    const popupMessages = messages.filter(
      (message) =>
        message.popup &&
        !message.readers.find(
          (reader) => reader.id === user?.last_accessed_profile.id
        ) &&
        currentDate >= new Date(message.visible_from) &&
        currentDate <= new Date(message.visible_until)
    );

    if (popupMessages.length > 0) {
      setCurrentMessage(popupMessages[0]);
    }
  }, [messages]);

  const handleClose = async () => {
    if (currentMessage) {
      try {
        await axios.post("/api/notifications/mark-as-read", {
          id: currentMessage.id,
        });
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === currentMessage.id ? { ...msg, read: true } : msg
          )
        );
      } catch (error) {
        console.error("Error updating notification status", error);
      }
    }
    setCurrentMessage(null);
  };

  if (!currentMessage) return null;

  return (
    <Dialog open={Boolean(currentMessage)} onClose={handleClose} fullWidth>
      <DialogTitle>
        <Typography>
          <b>Notification</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{currentMessage.content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupMessages;
