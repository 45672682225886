import React from "react";
import { Box } from "@mui/material";

export default function NotFound() {
  return (
    <Box
      fontSize={50}
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <h1>404</h1>
      <span>PAGE NOT FOUND!</span>
    </Box>
  );
}
