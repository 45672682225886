import React, { useContext, useEffect, useState } from "react";
import PackagesTable from "./packages-table/index";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import ProfileTypes from "../../../enums/profile-types";
import RoleTypes from "../../../enums/role-types";
import { useHistory } from "react-router-dom";

export default function ManagePackages() {
  const [showManagePackagesPage, setShowManagePackagesPage] = useState(false);
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();
  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name !== ProfileTypes.SONGWRITER &&
    user?.last_accessed_profile.profile_type.name !== ProfileTypes.PUBLISHER;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowManagePackagesPage(true);
    }
  }, []);
  return (
    showManagePackagesPage && (
      <React.Fragment>
        <PackagesTable />
      </React.Fragment>
    )
  );
}
