import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import ProfileContextProvider from "../../../../../context/profile-context";
import { Profile } from "../../../../../models/profile/profile";
import ProfileDeliverySplits from "./profile-delivery-splits";
import ProfileTypes from "../../../../../enums/profile-types";
import ProfileRoyaltySplits from "./ProfileRoyaltySplits";

interface IRoyaltySplits {
  profile: Profile;
  setProfile: Function;
}

export default function RoyaltySplits({ profile, setProfile }: IRoyaltySplits) {
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleStepClick = (step: number) => {
    setActiveStep(step);
  };

  return (
    <ProfileContextProvider profile={profile} setProfile={setProfile}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ width: "100%" }}
      >
        <Step key={0} completed={false} onClick={() => handleStepClick(0)}>
          <StepLabel style={{ cursor: "pointer" }}>Delivery Splits</StepLabel>
          <StepContent>
            <ProfileDeliverySplits />
          </StepContent>
        </Step>
        <Step key={1} completed={false} onClick={() => handleStepClick(1)}>
          <StepLabel style={{ cursor: "pointer" }}>Royalty Splits</StepLabel>
          <StepContent>
            <ProfileRoyaltySplits />
          </StepContent>
        </Step>
      </Stepper>
    </ProfileContextProvider>
  );
}
