import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useContext } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import ProfileTypes from "../../../enums/profile-types";
import { ContentCopy } from "@mui/icons-material";
import { SnackbarContext } from "../../../context/snackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

const Links = ({ profile }) => {
  const classes = useStyles();
  const { initSnackbarGeneric } = useContext(SnackbarContext);

  const copyAndAlert = (elementId) => {
    const textField: any = document.getElementById(elementId);
    if (textField) {
      const textFieldValue = textField.value;
      navigator.clipboard.writeText(textFieldValue);
      initSnackbarGeneric("Copied to clipboard");
    }
  };

  const handleCopyLoginClick = () => {
    copyAndAlert("loginURL");
  };

  const handleCopySongwriterRegistrationClick = () => {
    copyAndAlert("songwriterRegistrationURL");
  };

  const handleCopyPublisherRegistrationClick = () => {
    copyAndAlert("publisherRegistrationURL");
  };

  return (
    <Stack direction={"row"} spacing={2} marginTop={2}>
      <TextField
        id="loginURL"
        fullWidth
        className={classes.textField}
        label="Login URL"
        value={`${
          `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
          "/mmpz" +
          "/" +
          profile.url_name
        }`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={handleCopyLoginClick}
                aria-label="copy"
              >
                <ContentCopy />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {profile.profile_type.name !== ProfileTypes.SONGWRITER && (
        <React.Fragment>
          <TextField
            fullWidth
            id="songwriterRegistrationURL"
            className={classes.textField}
            label="Songwriter Registration URL"
            value={`${
              `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
              "/mmpz" +
              "/signup" +
              "/" +
              profile.url_name +
              "?type=" +
              "songwriter"
            }`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleCopySongwriterRegistrationClick}
                    aria-label="copy"
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {profile.profile_type.name !== ProfileTypes.PUBLISHER && (
            <TextField
              fullWidth
              id="publisherRegistrationURL"
              className={classes.textField}
              label="Publisher Registration URL"
              value={`${
                `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
                "/mmpz" +
                "/signup" +
                "/" +
                profile.url_name +
                "?type=" +
                "publisher"
              }`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleCopyPublisherRegistrationClick}
                      aria-label="copy"
                    >
                      <ContentCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </React.Fragment>
      )}
    </Stack>
  );
};

export default Links;
