import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { createContext, useRef, useState } from "react";
import ActionButtonSmall from "../components/layout/buttons/action-button-small";

export interface IGlobalLoaderContext {
  showConfirmation: any;
  setDialogTitle: any;
  setDialogContent: any;
  onCancel: any;
}

export const ConfirmationDialogContext = createContext(
  {} as IGlobalLoaderContext
);

const ConfirmationDialogContextProvider = ({ children }) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [title, setTitle] = useState("Please confirm this action");
  const [content, setContent] = useState("Are you sure you want to proceed?");
  const resolver = useRef<any>();
  const onCancel = useRef<any>();

  const showConfirmation = () => {
    setShowConfirmationDialog(true);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const setDialogTitle = (title) => {
    setTitle(title);
  };

  const setDialogContent = (content) => {
    setContent(content);
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setShowConfirmationDialog(false);
  };

  const handleCancel = () => {
    onCancel.current && onCancel.current();
    resolver.current && resolver.current(false);
    setShowConfirmationDialog(false);
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{
        showConfirmation,
        setDialogTitle,
        setDialogContent,
        onCancel,
      }}
    >
      {children}
      <Dialog
        disableEscapeKeyDown
        open={showConfirmationDialog}
        onClose={handleCancel}
        fullWidth
      >
        <DialogTitle>
          <Typography>
            <b>{title}</b>
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box padding={2} display="flex" justifyContent="center">
            <Typography>{content}</Typography>
          </Box>
        </DialogContent>
        <DialogActions style={{ marginBottom: "10px" }}>
          <Grid container justifyContent="space-evenly">
            <ActionButtonSmall onClick={handleCancel}>Cancel</ActionButtonSmall>
            <ActionButtonSmall color="primary" onClick={handleOk}>
              Ok
            </ActionButtonSmall>
          </Grid>
        </DialogActions>
      </Dialog>
    </ConfirmationDialogContext.Provider>
  );
};

export default ConfirmationDialogContextProvider;
