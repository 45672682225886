import React, { createContext } from "react";
import { Profile } from "../models/profile/profile";

export interface IProfileContext {
  profile: Profile;
  setProfile: Function;
}

export const ProfileContext = createContext({} as IProfileContext);

const ProfileContextProvider = ({ children, profile, setProfile }: any) => {
  return (
    <ProfileContext.Provider
      value={{
        profile,
        setProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;
