import React, { useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { IAuthContext, AuthContext } from "../../context/auth";
import { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";

interface IMmpzProfilesAutocomplete {
  autocompleteOnChange: Function;
  profileType?: string;
}

interface MmpzProfile {
  id: number;
  profile_information: {
    name: string;
  };
}

const filter = createFilterOptions<MmpzProfile>();

export default function MmpzProfilesAutocomplete({
  autocompleteOnChange,
  profileType,
}: IMmpzProfilesAutocomplete) {
  const { user } = useContext<IAuthContext>(AuthContext);
  const [mmpzProfiles, setMmpzProfiles] = useState<MmpzProfile[]>([]);
  const [value, setValue] = useState<MmpzProfile | null>(null);
  const [textFieldInputValue, setTextFieldInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      user?.is_mmpz_service_admin &&
      textFieldInputValue &&
      textFieldInputValue.length > 3
    ) {
      setLoading(true);
      API.get(
        ApiUri.SEARCH_PROFILE +
          "?search_term=" +
          textFieldInputValue +
          "&profile_type=" +
          profileType
      ).then(
        (response) => {
          setMmpzProfiles(response.data.collection);
          setLoading(false);
        },
        (error) => setLoading(false)
      );
    }
  }, [textFieldInputValue]); // eslint-disable-line

  const onTextFieldInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTextFieldInputValue(event.target.value);
    autocompleteOnChange(event.target.value);
    setMmpzProfiles([]);
  };

  return (
    <Autocomplete
      value={value}
      inputValue={textFieldInputValue}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setTextFieldInputValue(newValue);
          setValue({
            id: 0,
            profile_information: {
              name: newValue,
            },
          });
          autocompleteOnChange({
            profileInformation: {
              name: newValue,
            },
          });
        } else if (newValue && newValue.profile_information.name) {
          setTextFieldInputValue(newValue.profile_information.name);
          setValue({
            id: newValue.id,
            profile_information: newValue.profile_information,
          });
          autocompleteOnChange(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      clearIcon={null}
      options={mmpzProfiles}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option?.profile_information?.name;
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.profile_information.name}
        </Box>
      )}
      // freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Profile Name"
          onChange={onTextFieldInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
