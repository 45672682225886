import DateFnsUtils from "@date-io/date-fns";
import {
  Badge,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Info } from "@mui/icons-material";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import { Performer } from "../../../../../models/song/performer";
import { Song } from "../../../../../models/song/song";
import PerformerForm from "../../common-components/performer-form";

interface IFormStepOne {
  song: Song;
  setSong: Function;
}

export default function FormStepOne({ song, setSong }: IFormStepOne) {
  const [showNewPerformerForm, setShowNewPerformerForm] = useState(false);

  const toogleShowNewPerformerForm = () => {
    setShowNewPerformerForm(!showNewPerformerForm);
  };

  const createPerformerCallback = (performer: Performer) => {
    const performers = song.performers;
    performers.push(performer);
    setSong((previousState) => ({ ...previousState, performers: performers }));
    toogleShowNewPerformerForm();
  };

  const deletePerformerCallback = (performer: Performer) => {
    let performers = song.performers;
    performers = performers.filter((item) => performer.id !== item.id);
    setSong((previousState) => ({ ...previousState, performers: performers }));
  };

  const handleFieldChange = (event: any) => {
    let { name, value } = event.target;

    if (name === "is_instrumental") {
      value = event.target.checked;
    }

    if (name === "is_ppl_registered") {
      value = event.target.value === "true";
    }

    setSong((previousState) => ({ ...previousState, [name]: value }));
  };

  const handleArtistNameFieldChange = (event: any) => {
    const recordingArtist = {
      id: null,
      name: event.target.value,
    };
    setSong((previousState) => ({
      ...previousState,
      recording_artist: recordingArtist,
    }));
  };

  const handleReleaseDateFieldChange = (date) => {
    setSong((previousState) => ({
      ...previousState,
      release_date: date?.toISOString(),
    }));
  };

  return (
    <React.Fragment>
      <Grid container spacing={4} alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Tooltip
              title="The name of the single, EP or album release this recording is released on"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              required
              label="Release Name"
              name="release_name"
              value={song.release_name || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="ISRC = International Standard Recording Code, a unique 12 digit code assigned to a recording. Formatted with dashes, e.g GB-ABC-11-12345."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              label="ISRC"
              name="isrc"
              value={song.isrc || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="The name of the artist who released this recording"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              required
              label="Artist Name"
              value={song.recording_artist?.name || ""}
              onChange={handleArtistNameFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Tooltip
              title="The date this recording was released"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Release Date"
                format="MMMM d, yyyy"
                value={song.release_date ? song.release_date : null}
                onChange={handleReleaseDateFieldChange}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Enter the name of the record label who released this. Leave blank if there is no label"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              name="label"
              label="Record Label Name"
              value={song.label || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Unique UPC/ERN for the release, usually 13 digits"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              name="barcode"
              label="Barcode"
              value={song.barcode || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Will be automatically detected upon upload of an audio file"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField disabled value={song.duration || ""} label="Duration" />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Tooltip
              title="Will be automatically detected upon upload of an audio file"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              disabled
              type="number"
              value={song.bpm || ""}
              label="BPM"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormGroup row>
            <Typography variant="overline" style={{ marginTop: 10 }}>
              Is this recording registered with PPL in the UK? *
            </Typography>
            <Tooltip
              title="Why are we asking this?

              In order for songs to be put forward to sync opportunities for TV series that broadcast in the UK, the song must be registered with PPL. If the song isn’t registered with PPL, please select 'No' and contact your Publisher for more information."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
          </FormGroup>

          <RadioGroup
            name="is_ppl_registered"
            value={song.is_ppl_registered || null}
            onChange={handleFieldChange}
          >
            <FormControlLabel
              checked={song.is_ppl_registered === false}
              value={false}
              control={<Radio />}
              label="No"
            />
            <FormControlLabel
              checked={song.is_ppl_registered === true}
              value={true}
              control={<Radio />}
              label="Yes"
            />
          </RadioGroup>
        </Grid>

        {song.is_ppl_registered ? (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Tooltip
                title="Please enter the 9 digit PPL ID for this recording"
                placement="top-end"
              >
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={<Info color="disabled" />}
                />
              </Tooltip>
              <TextField
                type="text"
                name="ppl_id"
                value={song.ppl_id || ""}
                onChange={handleFieldChange}
                label="PPL ID"
                required
              />
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant="overline">Performers *</Typography>
          <br></br>
          {song.performers.map((performer: Performer) => {
            return (
              <React.Fragment key={performer.id}>
                <PerformerForm
                  performer={performer}
                  song={song}
                  createPerformerCallback={() => {}}
                  deletePerformerCallback={deletePerformerCallback}
                />
                <br></br>
              </React.Fragment>
            );
          })}
          <Fab
            size="small"
            variant="extended"
            color="primary"
            style={{ marginBottom: 10 }}
            onClick={toogleShowNewPerformerForm}
          >
            Add Performer
            <Add />
          </Fab>
          {showNewPerformerForm ? (
            <PerformerForm
              song={song}
              createPerformerCallback={createPerformerCallback}
              deletePerformerCallback={() => {}}
            />
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
