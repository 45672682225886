import API from "../../api";
import ApiUri from "../../api-uri";

export const getFile = async ({ fileId, responseType }) => {
  try {
    const response = await API.get(ApiUri.FILE + "/" + fileId, {
      responseType,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
