import { Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { AuthContext } from "../../context/auth";
import { useHistory } from "react-router-dom";
import API from "../../api/api";
import ProfileTypes from "../../enums/profile-types";
import ApiUri from "../../api/api-uri";
import { GlobalLoaderContext } from "../../context/global-loader";
import ReactEcharts from "echarts-for-react";
import Colors from "../../enums/colors";
import { CustomisationContext } from "../../context/customisation";
import { SnackbarContext } from "../../context/snackbar";
import MissingFieldModal from "./MissingFieldModal";
import Compositions from "../../enums/compositions";
import { getAllSongs } from "../../api/utils/get/getAllSongs";

export default function Dashboard() {
  const { user } = useContext(AuthContext);
  const { customisation } = useContext(CustomisationContext);
  const history = useHistory();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const profile = user?.last_accessed_profile;

  const [draftSongs, setDraftSongs] = useState([] as any);
  const [nonDraftSongs, setNonDraftSongs] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([] as any);
  const [graphOption, setGraphOption] = useState({});
  const [showMissingFieldsModal, setShowMissingFieldsModal] = useState(false);
  const [missingFieldsState, setMissingFieldsState] = useState<string[]>([]);

  const { initSnackbarError } = useContext(SnackbarContext);

  const checkForMissingField = () => {
    const missingFields: string[] = [];
    if (!profile?.profile_information.name) {
      missingFields.push("name");
    }
    if (!profile?.profile_information.email) {
      missingFields.push("email");
    }
    if (!profile?.profile_information.address) {
      missingFields.push("address");
    }
    if (!profile?.profile_information.city) {
      missingFields.push("city");
    }
    if (!profile?.profile_information.country) {
      missingFields.push("country");
    }
    if (!profile?.profile_information.phone_number) {
      missingFields.push("phone_number");
    }
    if (!profile?.cae_number && profile?.has_cae_number) {
      missingFields.push("cae_number");
    }
    if (
      !profile?.profile_information.company_registration_number &&
      profile?.profile_type.name === ProfileTypes.PUBLISHER_ADMINISTRATOR
    ) {
      missingFields.push("company_registration_number");
    }
    return missingFields;
  };

  useEffect(() => {
    const missingFields = checkForMissingField();
    setMissingFieldsState(missingFields);
    if (missingFields.length > 0) {
      setShowMissingFieldsModal(true);
    }
    const fetchAndFilterSongs = async () => {
      startGlobalLoader();
      try {
        const allSongsResponse = await getAllSongs({ profileId: profile?.id });
        const allSongs = allSongsResponse.data.collection;
        const draftSongs = allSongs.filter(
          (song) => song.composition.status === Compositions.STATUS_DRAFT
        );
        setDraftSongs(draftSongs);
        const nonDraftSongs = allSongs.filter(
          (song) => song.composition.status !== Compositions.STATUS_DRAFT
        );
        setNonDraftSongs(nonDraftSongs);
        setLoading(false);
        stopGlobalLoader();
      } catch (err) {
        initSnackbarError(err);
      } finally {
        stopGlobalLoader();
      }
    };

    fetchAndFilterSongs();
  }, []);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const dates = nonDraftSongs
      .filter((song) => song.release_date)
      .map((song) => song.release_date.slice(0, 10));
    setDates(dates);
  }, [nonDraftSongs]);

  const updateGraphOption = async () => {
    const result = dates.reduce((acc, date) => {
      const index = acc.findIndex(([d]) => d === date);
      if (index >= 0) {
        acc[index][1]++;
      } else {
        acc.push([date, 1]);
      }
      return acc;
    }, []);

    result.sort(([dateA], [dateB]) => dateA.localeCompare(dateB)); // sort by date in ascending order
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      year: "numeric",
    });
    const datesArray = result.map(([date]) =>
      dateFormatter.format(Date.parse(date))
    );
    const countsArray = result.map(([, count]) => count); // extract second item of each sub-array
    let resultArray: any = [];

    let cumulativeSum = 0;
    for (let i = 0; i < countsArray.length; i++) {
      cumulativeSum += countsArray[i];
      resultArray.push(cumulativeSum);
    }
    setGraphOption({
      xAxis: {
        type: "category",
        data: datesArray,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: resultArray,
          type: "bar",
          itemStyle: {
            barBorderRadius: 5,
            color: customisation?.buttonColor,
          },
        },
      ],
    });
  };

  const loadGraph = async () => {
    await updateGraphOption();
    // setLoading(false);
  };

  useEffect(() => {
    loadGraph();

    // updateGraphOption().then(() => {
  }, [dates]);

  return (
    <>
      <Grid item xs={12} md={12} container spacing={2} sx={{ height: "80vh" }}>
        {loading ? (
          ""
        ) : (
          <>
            <Grid item xs={12} md={12}>
              <h1>Welcome, {profile?.profile_information.name}</h1>
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper
                elevation={3}
                sx={{ paddingTop: 4, paddingX: 2, height: "100%" }}
              >
                <Stack>
                  <Typography alignSelf={"center"}>
                    Until today, you have uploaded {nonDraftSongs.length} song
                    {nonDraftSongs.length !== 1 && "s"}
                  </Typography>
                  {draftSongs.length > 0 && (
                    <Typography alignSelf={"center"}>
                      You have {draftSongs.length} song
                      {draftSongs.length !== 1 && "s"} in draft
                    </Typography>
                  )}
                  <ReactEcharts
                    option={graphOption}
                    style={{ height: 300 }} // Add a height to the chart
                    notMerge={true}
                    lazyUpdate={true}
                  />
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper elevation={3} sx={{ height: "100%" }}>
                <Stack
                  height={"100%"}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {profile?.profile_type.name === ProfileTypes.SONGWRITER && (
                    <ActionButtonSmall
                      onClick={() => history.push("/songs/new")}
                    >
                      Add New Song
                    </ActionButtonSmall>
                  )}
                  <ActionButtonSmall
                    onClick={() => history.push("/songs/manage")}
                  >
                    Manage Songs
                  </ActionButtonSmall>
                  <ActionButtonSmall
                    onClick={() => history.push("/edit-profile")}
                  >
                    Edit profile
                  </ActionButtonSmall>
                  {profile?.profile_type.name !== ProfileTypes.SONGWRITER && (
                    <ActionButtonSmall
                      onClick={() => history.push("/sub-profiles/manage")}
                    >
                      Manage Sub Profiles
                    </ActionButtonSmall>
                  )}
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12}>
              <Paper elevation={3} sx={{ width: "100%" }}>
                <Stack
                  width={"100%"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <h3>Analytics coming soon</h3>
                </Stack>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
      {!loading && (
        <MissingFieldModal
          modalOpened={showMissingFieldsModal}
          handleClose={() => setShowMissingFieldsModal(false)}
          missingFields={missingFieldsState}
        />
      )}
    </>
  );
}
