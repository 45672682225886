import {
  AppBar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import parse from "html-react-parser";
import { AuthContext } from "../../context/auth";
import { GlobalLoaderContext } from "../../context/global-loader";
import { SnackbarContext } from "../../context/snackbar";
import CustomToolbar from "../../components/layout/titles/CustomToolbar";
import { contentTypesLabels } from "../settings/contracts/types";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MissingFieldModal = ({ modalOpened, handleClose, missingFields }) => {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    const missingFieldsParameters = missingFields;
    const paramString = missingFieldsParameters.join("&");
    history.push(`/edit-profile?${paramString}`);
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        open={modalOpened}
        onClose={() => handleClose(false)}
        TransitionComponent={Transition}
        // fullWidth
        maxWidth="xl"
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Missing Information
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => handleClose(false)}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
          margin={3}
        >
          <p>
            You currently have some information missing from your profile,
            please update this before you can continue.
          </p>
          <Stack direction={"row"} spacing={2}>
            <ActionButtonSmall onClick={handleClick}>
              Add information
            </ActionButtonSmall>
            <ActionButtonSmall
              customColor="grey"
              onClick={() => handleClose(false)}
            >
              Cancel
            </ActionButtonSmall>
          </Stack>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default MissingFieldModal;
