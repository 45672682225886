import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import ProfileTypes from "../../enums/profile-types";

export default function usePublisherAdministratorRbac() {
  const { user } = useContext(AuthContext);
  const [canCreatePublisherAdministrator, setCanCreatePublisherAdministrator] =
    useState(false);

  useEffect(() => {
    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE
    ) {
      setCanCreatePublisherAdministrator(true);
    }

    if (
      user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR
    ) {
      setCanCreatePublisherAdministrator(false);
    }

    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.PUBLISHER
    ) {
      setCanCreatePublisherAdministrator(false);
    }

    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SONGWRITER
    ) {
      setCanCreatePublisherAdministrator(false);
    }

    return () => {
      setCanCreatePublisherAdministrator(false);
    };
  }, []); // eslint-disable-line
  return canCreatePublisherAdministrator;
}
