import React, { useContext, useState } from "react";
import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import { SnackbarContext } from "../../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import useCountries from "../../../../../hooks/country/countries.hook";
import { Country } from "../../../../../models/country/country";
import { Profile } from "../../../../../models/profile/profile";
import ProfileTypes from "../../../../../enums/profile-types";

export default function GeneralSettings({ profile }) {
  const countries = useCountries();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [formData, setFormData] = useState<Profile>(profile);

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "profile_information.name") {
      formData.profile_information.name = value;
    }

    if (name === "profile_information.email") {
      formData.profile_information.email = value;
    }

    if (name === "profile_information.country.id") {
      formData.profile_information.country.id = value;
    }

    if (name === "profile_information.address") {
      formData.profile_information.address = value;
    }

    if (name === "profile_information.city") {
      formData.profile_information.city = value;
    }

    if (name === "signup_private") {
      formData.signup_private = event.target.checked;
    }

    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    startGlobalLoader();

    API.put(ApiUri.PROFILE + "/" + profile.id, formData).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Profile successfully edited");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      },
    );
  };

  return formData ? (
    <form autoComplete="off" onSubmit={handleOnSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_information.name"
              value={formData.profile_information.name}
              required={true}
              type="text"
              label="Profile Name"
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_information.email"
              value={formData.profile_information.email}
              required={true}
              type="email"
              label="Email Address"
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_type.name"
              value={formData.profile_type.name}
              type="text"
              label="Profile Type"
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_information.address"
              value={formData.profile_information.address || ""}
              type="text"
              label="Address"
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_information.city"
              value={formData.profile_information.city || ""}
              type="text"
              label="City"
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <InputLabel>Country *</InputLabel>
            <Select
              name="profile_information.country.id"
              value={formData.profile_information.country.id}
              required={true}
              onChange={handleFieldChange}
            >
              {countries.map((country: Country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {formData.profile_type.name !== ProfileTypes.SONGWRITER ? (
          <Grid item xs={12} sm={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.signup_private}
                    onChange={handleFieldChange}
                    name="signup_private"
                    color="primary"
                  />
                }
                label="Sign up under this profile should be private?"
              />
            </FormGroup>
          </Grid>
        ) : null}
      </Grid>

      <Grid style={{ marginTop: 40 }} container direction="row">
        <ActionButtonSmall type="submit">Save</ActionButtonSmall>
      </Grid>
    </form>
  ) : null;
}
