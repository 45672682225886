import React from "react";
import { Button, IconButton, Link } from "@mui/material";
import { File } from "../../models/app/file";
import { Delete } from "@mui/icons-material";
import ApiUri from "../../api/api-uri";

interface IFileRemove {
  callback: any;
}

export default function FileRemove({ callback }: IFileRemove) {
  return (
    <Delete
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={callback}
      fontSize="large"
    />
  );
}
