import {
  Grid,
  Chip,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import ConnectExternalSongwriter from "./connect-external-songwriter";

interface IExternalSongwriter {
  songWriter: SongWriter;
}

export default function ExternalSongwriter({
  songWriter,
}: IExternalSongwriter) {
  return (
    <Grid container spacing={2} style={{ marginTop: 40, marginBottom: 20 }}>
      <Grid item>
        <Typography variant="h6">
          {songWriter.external_song_writer.first_name +
            " " +
            songWriter.external_song_writer.last_name}
          <Chip
            size="small"
            color="primary"
            label="External Songwriter"
            style={{ marginBottom: 20, marginLeft: 10 }}
          />
        </Typography>
      </Grid>

      <ConnectExternalSongwriter songWriter={songWriter} />

      <Grid item xs={12} style={{ marginTop: 20 }}>
        <InputLabel style={{ fontSize: 12 }}>Contributions</InputLabel>
        <FormGroup row>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={songWriter.music_contribution}
                color="primary"
              />
            }
            label="Music"
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={songWriter.lyrics_contribution}
                color="primary"
              />
            }
            label="Lyrics"
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled
          value={songWriter.songwriter_cae_number || ""}
          type="text"
          label="Songwriter CAE Number"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.publisher_name || ""}
          type="text"
          label="Publisher Name"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.publisher_cae_number || ""}
          type="text"
          label="Publisher CAE Number"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.pro_affiliation?.name_code || ""}
          type="text"
          label="PRO Affiliation"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.share || ""}
          type="number"
          label="Share"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
}
