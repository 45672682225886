import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import TextEditor from "./TextEditor";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditArticleModal = ({
  modalOpened,
  handleClose,
  setSections,
  section,
  articleBeingEdited,
}) => {
  const classes = useStyles();
  const [articleTitle, setArticleTitle] = useState(articleBeingEdited.title);
  const [articleContent, setArticleContent] = useState(
    articleBeingEdited.content
  );
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closeEditArticleModal = () => {
    setArticleTitle("");
    setArticleContent("");
    handleClose();
  };

  const handleSave = () => {
    // CHANGE TO PUT
    if (articleTitle.length === 0 || articleContent.length === 0) {
      initSnackbarError("Please enter valid article title and content");
    } else {
      startGlobalLoader();
      const index = section.articles.findIndex(
        (article) => article.id === articleBeingEdited.id
      );
      const formData = section;
      formData.articles[index].title = articleTitle;
      formData.articles[index].content = articleContent;
      API.put(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          "/helper" +
          "/" +
          section.id,
        formData
      ).then(
        (response) => {
          initSnackbarSuccess("Article edited");
          stopGlobalLoader();
          setSections(null);
          setArticleTitle("");
          setArticleContent("");
          handleClose();
        },
        (error) => {
          initSnackbarError(error);
          stopGlobalLoader();
          setArticleTitle("");
          setArticleContent("");
          handleClose();
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeEditArticleModal}
        TransitionComponent={Transition}
        disableEnforceFocus={true}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Article
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeEditArticleModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={10} marginX={20}>
          <Stack spacing={2}>
            <TextField
              value={articleTitle}
              onChange={(event) => setArticleTitle(event.target.value)}
              label="Title"
            />
            <TextEditor
              value={articleContent}
              setValue={setArticleContent}
              label="Content"
            />
          </Stack>
          <ActionButtonSmall onClick={handleSave}>Save</ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default EditArticleModal;
