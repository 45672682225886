import React, { useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { IAuthContext, AuthContext } from "../../../../context/auth";
import { useState } from "react";
import { Badge, CircularProgress, FormControl, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

interface MmdzProfile {
  id?: number;
  profile_information: {
    name: string;
  };
  type?: {
    name: string;
  };
  urlName?: string;
}

const filter = createFilterOptions<MmdzProfile>();

export default function MmdzProfilesAutocomplete({
  autocompleteOnChange,
  profileType,
}) {
  const { user } = useContext<IAuthContext>(AuthContext);
  const [mmdzProfiles, setMmdzProfiles] = useState<MmdzProfile[]>([]);
  const [value, setValue] = useState<MmdzProfile | null>(null);
  const [textFieldInputValue, setTextFieldInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      user?.is_mmpz_service_admin &&
      textFieldInputValue &&
      textFieldInputValue.length > 3
    ) {
      setLoading(true);
      API.get(
        ApiUri.MMDZ + ApiUri.PROFILE + "?name=" + textFieldInputValue
      ).then(
        (response) => {
          setMmdzProfiles(response.data);
          setLoading(false);
        },
        (error) => setLoading(false)
      );
    }
  }, [textFieldInputValue]); // eslint-disable-line

  const onTextFieldInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTextFieldInputValue(event.target.value);
    autocompleteOnChange(event.target.value);
    setMmdzProfiles([]);
  };

  return (
    <FormControl fullWidth>
      {profileType === "Songwriter" && (
        <Tooltip
          title="Please provide first name and last name"
          placement="top-end"
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={<Info color="disabled" />}
          />
        </Tooltip>
      )}
      <Autocomplete
        value={value}
        inputValue={textFieldInputValue}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTextFieldInputValue(newValue);
            setValue({
              profile_information: {
                name: newValue,
              },
            });
            autocompleteOnChange({
              profile_information: {
                name: newValue,
              },
            });
          } else if (newValue && newValue.profile_information?.name) {
            setTextFieldInputValue(newValue.profile_information?.name);
            setValue({
              id: newValue.id,
              profile_information: newValue.profile_information,
            });
            autocompleteOnChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        clearIcon={null}
        options={mmdzProfiles}
        groupBy={(option) => (option.id ? "Connect to MMDZ Profile?" : "")}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option?.profile_information?.name;
        }}
        renderOption={(props, option) =>
          option.profile_information?.name + " (" + option.type?.name + ")"
        }
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Profile Name"
            onChange={onTextFieldInputChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
