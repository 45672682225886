import React, { useContext, useState } from "react";
import { Divider, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import API from "../../../../api/api";
import ApiUri from "../../../../api/api-uri";
import { SnackbarContext } from "../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import ConfirmationDialogActivator from "../../../../components/confirmation-dialog/confirmation-dialog-activator";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context/auth";
import ProfileTypes from "../../../../enums/profile-types";
import Compositions from "../../../../enums/compositions";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

export default function ContextMenu({ profile, reload }) {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectProfile = () => {
    handleClose();
    startGlobalLoader();

    API.get(ApiUri.SELECT_PROFILE + "/" + profile.id).then(
      (response) => {
        window.location.href = "/";
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleEditProfile = () => {
    handleClose();
    history.push("/sub-profiles/edit/" + profile.id);
  };

  const handleCloseProfile = () => {
    handleClose();
    startGlobalLoader();

    API.post(ApiUri.PROFILE + "/" + profile.id + ApiUri.CLOSE).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Profile successfully closed");
        reload();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleSuspendProfile = () => {
    handleClose();
    startGlobalLoader();

    API.delete(ApiUri.PROFILE + "/" + profile.id + ApiUri.DISABLE).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Profile successfully suspended");
        reload();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleTerminateProfile = () => {
    handleClose();
    startGlobalLoader();

    API.post(ApiUri.PROFILE + "/" + profile.id + ApiUri.TERMINATE).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Profile successfully terminated");
        reload();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleActivateProfile = () => {
    handleClose();
    startGlobalLoader();

    API.post(ApiUri.PROFILE + "/" + profile.id + ApiUri.ENABLE).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Profile successfully activated");
        reload();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const hasPublishedOrTakenDownComposition = (songs) => {
    return songs.some(
      (song) =>
        song.composition &&
        (song.composition.status === Compositions.STATUS_PUBLISHED ||
          song.composition.status === Compositions.STATUS_TAKEN_DOWN)
    );
  };

  const handleDeleteProfile = () => {
    handleClose();
    startGlobalLoader();
    API.get(ApiUri.PROFILE + "/" + profile?.id + ApiUri.SONG, {
      params: {
        limit: 100000000,
        page: 1,
        search_term: "",
      },
    }).then(
      (response) => {
        stopGlobalLoader();
        const songs = response.data.collection;
        if (hasPublishedOrTakenDownComposition(songs)) {
          initSnackbarError("There must be no published or taken down songs");
          stopGlobalLoader();
        } else {
          // add check for royalty data (task 26805)
          API.delete(ApiUri.PROFILE + "/" + profile.id + ApiUri.DELETE, {
            params: { flag: "none" },
          }).then(
            (response) => {
              stopGlobalLoader();
              initSnackbarSuccess("Profile successfully deleted");
              reload();
            },
            (error) => {
              initSnackbarError(error);
              stopGlobalLoader();
            }
          );
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleDeleteProfileAndUsers = () => {
    handleClose();
    startGlobalLoader();
    API.get(ApiUri.PROFILE + "/" + profile?.id + ApiUri.SONG, {
      params: {
        limit: 100000000,
        page: 1,
        search_term: "",
      },
    }).then(
      (response) => {
        stopGlobalLoader();
        const songs = response.data.collection;
        if (hasPublishedOrTakenDownComposition(songs)) {
          initSnackbarError("There must be no published or taken down songs");
          stopGlobalLoader();
        } else {
          // add check for royalty data (task 26805)
          API.delete(ApiUri.PROFILE + "/" + profile.id + ApiUri.DELETE, {
            params: { flag: "user" },
          }).then(
            (response) => {
              stopGlobalLoader();
              initSnackbarSuccess("Profile and Users successfully deleted");
              reload();
            },
            (error) => {
              initSnackbarError(error);
              stopGlobalLoader();
            }
          );
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelectProfile}>Select</MenuItem>
        <MenuItem onClick={handleEditProfile}>Edit</MenuItem>
        {user?.is_mmpz_service_admin &&
        user.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE
          ? [
              <Divider key="divider1" />,
              <MenuItem key={"activate"}>
                <ConfirmationDialogActivator
                  onClick={handleActivateProfile}
                  type="link"
                  actionName="Activate"
                />
              </MenuItem>,
              <MenuItem key={"close"}>
                <ConfirmationDialogActivator
                  onClick={handleCloseProfile}
                  type="link"
                  actionName="Close"
                />
              </MenuItem>,
              <MenuItem key={"suspend"}>
                <ConfirmationDialogActivator
                  onClick={handleSuspendProfile}
                  type="link"
                  actionName="Suspend"
                />
              </MenuItem>,
              <MenuItem key={"terminate"}>
                <ConfirmationDialogActivator
                  onClick={handleTerminateProfile}
                  type="link"
                  actionName="Terminate"
                />
              </MenuItem>,

              <Divider key="divider2" />,
              <MenuItem key="delete profile">
                <ConfirmationDialogActivator
                  onClick={handleDeleteProfile}
                  type="link"
                  actionName="Delete"
                />
              </MenuItem>,
              // <MenuItem key="delete profile and user">
              //   <ConfirmationDialogActivator
              //     onClick={handleDeleteProfileAndUsers}
              //     type="link"
              //     actionName="Delete Profile and User(s)"
              //   />
              // </MenuItem>,
            ]
          : null}
      </Menu>
    </React.Fragment>
  );
}
