import React, { useContext, useState } from "react";
import { Box, Divider, Link, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ConfirmationDialogActivator from "../../../components/confirmation-dialog/confirmation-dialog-activator";
import { Edit, LinkOff, Person } from "@mui/icons-material";
import RoleTypes from "../../../enums/role-types";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { Profile } from "../../../models/profile/profile";
import { ProfileRbac } from "../../../models/profile/profile-rbac";
import { AuthContext } from "../../../context/auth";
import ViewPaymentGatewayModal from "./ViewPaymentGatewayModal";
import EditPaymentGatewayModal from "./EditPaymentGatewayModal";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

export default function ContextMenu({ paymentGateway, setPaymentGateways }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { user } = useContext(AuthContext);
  const [paymentTypes, setPaymentTypes] = useState([]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    startGlobalLoader();
    API.get("/payment-type").then(
      (response) => {
        setPaymentTypes(response.data.collection);
        setEditModalOpen(true);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleDelete = () => {
    startGlobalLoader();
    paymentGateway.status = false;
    API.put(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        "/payment-gateway/" +
        paymentGateway.id,
      paymentGateway
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Products Payment Provider successfully deleted");
        setPaymentGateways(null);
        handleClose();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        handleClose();
      }
    );
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setViewModalOpen(true)}>View</MenuItem>
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem>
          <ConfirmationDialogActivator
            onClick={handleDelete}
            type="link"
            actionName="Delete"
          />
        </MenuItem>
      </Menu>
      <ViewPaymentGatewayModal
        paymentGateway={paymentGateway}
        modalOpened={viewModalOpen}
        handleClose={() => setViewModalOpen(false)}
      />
      <EditPaymentGatewayModal
        modalOpened={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        setPaymentGateways={setPaymentGateways}
        paymentGateway={paymentGateway}
        paymentTypes={paymentTypes}
      />
    </React.Fragment>
  );
}
