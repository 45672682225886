import React, { createContext, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "../enums/colors";
import { ThreeDots } from "react-loader-spinner";
import ActionButtonSmall from "../components/layout/buttons/action-button-small";

export interface IGlobalLoaderContext {
  startGlobalLoader: any;
  stopGlobalLoader: any;
}

const useStyles = makeStyles(() => {
  return {
    contentEnabled: {
      display: "inital",
    },
    contentDisabled: {
      pointerEvents: "none",
      opacity: 0.2,
      transition: "opacity .6s ease-in-out",
    },
  };
});

type LoaderType = "Circular" | "Dots";

export const GlobalLoaderContext = createContext({} as IGlobalLoaderContext);

const GlobalLoaderContextProvider = ({ children }: any) => {
  const classes = useStyles();
  const [loaderActive, setLoaderActive] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [loaderType, setLoaderType] = useState<LoaderType>("Circular");
  const [refreshButton, setRefreshButton] = useState(false);

  const startGlobalLoader = (
    loaderType: LoaderType = "Circular",
    message: string = "",
    refreshButton: boolean = false
  ) => {
    setLoaderActive(true);
    setLoaderMessage(message);
    setLoaderType(loaderType ? loaderType : "Circular");
    setRefreshButton(refreshButton);
  };

  const stopGlobalLoader = () => {
    setLoaderActive(false);
    setLoaderMessage("");
    setLoaderType("Circular");
  };

  return (
    <GlobalLoaderContext.Provider
      value={{
        startGlobalLoader,
        stopGlobalLoader,
      }}
    >
      <React.Fragment>
        <div
          className={
            loaderActive ? classes.contentDisabled : classes.contentEnabled
          }
        >
          {children}
        </div>

        {loaderActive ? (
          loaderType === "Circular" ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // This will center the content
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 9999999,
              }}
            >
              <CircularProgress
                size={100}
                style={{
                  color: Colors.black,
                }}
              />
            </div>
          ) : (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // This will center the content
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 999999,
              }}
            >
              {loaderMessage}
              <ThreeDots
                height={100}
                width={100}
                radius={10}
                color={Colors.black}
                ariaLabel="three-dots-loading"
                visible={true}
              />
              {refreshButton && (
                <ActionButtonSmall
                  onClick={() => (window.location = window.location)}
                >
                  Cancel
                </ActionButtonSmall>
              )}
            </div>
          )
        ) : null}
      </React.Fragment>
    </GlobalLoaderContext.Provider>
  );
};

export default GlobalLoaderContextProvider;
