import {
  AppBar,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewPaymentGatewayModal = ({
  modalOpened,
  handleClose,
  paymentTypes,
  setPaymentGateways,
  existingPaymentGateways,
}) => {
  const activePaymentGateways = existingPaymentGateways?.filter(
    (paymentGateway) => paymentGateway.status
  );
  const hasPaymentTypePaypal = activePaymentGateways?.some(
    (paymentGateway) => paymentGateway.payment_type.id === 1
  );
  const hasPaymentTypeStripe = activePaymentGateways?.some(
    (paymentGateway) => paymentGateway.payment_type.id === 2
  );

  const testSite =
    window.location.href.includes("test") ||
    window.location.href.includes("localhost");

  const initialPaymentGateway = {
    name: "",
    website: hasPaymentTypePaypal ? "stripe.com" : "paypal.com",
    provider_params: testSite
      ? hasPaymentTypePaypal
        ? {
            publishable_key: "",
            secret_key: "",
            mode: "test",
          }
        : {
            client_id: "",
            secret: "",
            mode: "sandbox",
          }
      : {
          publishable_key: "",
          secret_key: "",
          mode: "live",
        },
    payment_type:
      paymentTypes.length > 0
        ? hasPaymentTypePaypal
          ? paymentTypes[1]
          : paymentTypes[0]
        : undefined,
  };

  const classes = useStyles();
  const [paymentGateway, setPaymentGateway] = useState<any>(
    initialPaymentGateway
  );
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closeNewPaymentGatewayModal = () => {
    setPaymentGateway(initialPaymentGateway);
    handleClose();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "name":
        setPaymentGateway({ ...paymentGateway, name: value });
        break;
      case "clientID":
        setPaymentGateway({
          ...paymentGateway,
          provider_params: {
            ...paymentGateway.provider_params,
            client_id: value,
          },
        });
        break;
      case "secret":
        setPaymentGateway({
          ...paymentGateway,
          provider_params: { ...paymentGateway.provider_params, secret: value },
        });
        break;
      case "publishableKey":
        setPaymentGateway({
          ...paymentGateway,
          provider_params: {
            ...paymentGateway.provider_params,
            publishable_key: value,
          },
        });
        break;
      case "secretKey":
        setPaymentGateway({
          ...paymentGateway,
          provider_params: {
            ...paymentGateway.provider_params,
            secret_key: value,
          },
        });
        break;
      case "paymentType":
        const newPaymentType = paymentTypes.find(
          (paymentType) => paymentType.id === value
        );

        const newProviderParams = paymentGateway.provider_params;
        newProviderParams.mode =
          newPaymentType.id === 1
            ? "sandbox"
            : newPaymentType.id === 2
            ? "test"
            : "";
        setPaymentGateway({
          ...paymentGateway,
          website:
            newPaymentType.id === 1
              ? "paypal.com"
              : newPaymentType.id === 2
              ? "stripe.com"
              : "",
          payment_type: newPaymentType,
          provider_params: newProviderParams,
        });
        break;
    }
  };

  const handleSave = () => {
    // Saving logic
    startGlobalLoader();
    API.post(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        "/payment-gateway",
      paymentGateway
    ).then(
      (response) => {
        initSnackbarSuccess("Products Payment Provider created");
        stopGlobalLoader();
        setPaymentGateways(null);
        closeNewPaymentGatewayModal();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        closeNewPaymentGatewayModal();
      }
    );
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeNewPaymentGatewayModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              New Products Payment Provider
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeNewPaymentGatewayModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={10} marginX={20}>
          <Stack spacing={2}>
            <TextField
              value={paymentGateway.name}
              onChange={handleChange}
              name="name"
              label="Name"
            />
            <FormControl fullWidth>
              <InputLabel>Provider</InputLabel>
              <Select
                name="paymentType"
                value={paymentGateway?.payment_type?.id}
                required={true}
                onChange={handleChange}
                label="Provider"
              >
                {paymentTypes.map((paymentType) => (
                  <MenuItem
                    key={paymentType.id}
                    value={paymentType.id}
                    disabled={
                      (hasPaymentTypePaypal && paymentType.id === 1) ||
                      (!hasPaymentTypePaypal &&
                        hasPaymentTypeStripe &&
                        paymentType.id === 2)
                    }
                  >
                    {paymentType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {paymentGateway.payment_type.id === 1 && (
              <React.Fragment>
                <TextField
                  value={paymentGateway.provider_params.client_id}
                  onChange={handleChange}
                  name="clientID"
                  label="Client ID"
                />
                <TextField
                  value={paymentGateway.provider_params.secret}
                  onChange={handleChange}
                  name="secret"
                  label="Secret"
                />
              </React.Fragment>
            )}
            {paymentGateway.payment_type.id === 2 && (
              <React.Fragment>
                <TextField
                  value={paymentGateway.provider_params.publishable_key}
                  name="publishableKey"
                  label="Publishable Key"
                  onChange={handleChange}
                />
                <TextField
                  value={paymentGateway.provider_params.secret_key}
                  name="secretKey"
                  label="Secret Key"
                  onChange={handleChange}
                />
              </React.Fragment>
            )}
          </Stack>
          <ActionButtonSmall onClick={handleSave}>Save</ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default NewPaymentGatewayModal;
