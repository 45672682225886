import { Toolbar } from "@mui/material";
import React, { useContext } from "react";
import { CustomisationContext } from "../../../context/customisation";

const CustomToolbar = (props: any) => {
  const { customisation } = useContext(CustomisationContext);
  return (
    <Toolbar
      {...props}
      sx={{
        backgroundColor: customisation?.mainColor,
        color: customisation?.textColor,
      }}
    >
      {props.children}
    </Toolbar>
  );
};

export default CustomToolbar;
