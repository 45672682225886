import API from "../../api";
import ApiUri from "../../api-uri";

export const putProfile = async ({ profileId, formData }) => {
  try {
    const response = await API.put(ApiUri.PROFILE + "/" + profileId, formData);
    return response;
  } catch (err) {
    throw err;
  }
};
