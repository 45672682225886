import React, { useCallback, useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../components/loader";
import API from "../../../api/api";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import DateTime from "../../../components/utilities/date-time";
import { Box, debounce, InputAdornment, TextField } from "@mui/material";
import { CheckCircle, RemoveCircle, Search } from "@mui/icons-material";
// import ContextMenu from "./context-menu";
import { Profile } from "../../../models/profile/profile";
import ProfileTypes from "../../../enums/profile-types";
import ContextMenu from "./context-menu";
// import AboutSubProfileModal from "about-sub-profile-modal/index";

const formatDate = (date) => {
  if (date) {
    const [year, month, day] = date.split("T")[0].split("-");
    const formattedDate = `${day.padStart(2, "0")}/${month.padStart(
      2,
      "0"
    )}/${year}`;
    return formattedDate;
  } else {
    return "";
  }
};

const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default function ActiveSubscriptionsTable({
  subscriptions,
  setSubscriptions,
}) {
  const { user } = useContext<IAuthContext>(AuthContext);

  return (
    <React.Fragment>
      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Price</b>
                </TableCell>
                <TableCell>
                  <b>Songwriters Royalty Percentage</b>
                </TableCell>
                <TableCell>
                  <b>Start Date</b>
                </TableCell>
                <TableCell>
                  <b>End Date</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(subscriptions as any).map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.package?.name}</TableCell>
                    <TableCell>
                      £{" "}
                      {row.package?.discount
                        ? (
                            row.package?.price *
                            (1 - row.package?.discount)
                          ).toFixed(2)
                        : row.package?.price.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {(1 - row.package?.admin_percentage) * 100}%
                    </TableCell>
                    <TableCell>{formatDate(row.avialable_on)}</TableCell>
                    <TableCell>{formatDate(row.avialable_off)}</TableCell>
                    <TableCell>{capitalizeFirstLetter(row.status)}</TableCell>
                    <TableCell>
                      <ContextMenu
                        subscriptionId={row.package.id}
                        setSubscriptions={setSubscriptions}
                        transactionId={row.package_transaction.id}
                        status={capitalizeFirstLetter(row.status)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}
