import API from "../../api";
import ApiUri from "../../api-uri";

export const getProduct = async ({ productId }) => {
  try {
    const response = API.get(ApiUri.PACKAGE + "/" + productId);
    return response;
  } catch (err) {
    throw err;
  }
};
