import React, { useCallback, useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../../components/loader";
import API from "../../../../api/api";
import ApiUri from "../../../../api/api-uri";
import { Stack } from "@mui/material";
import { Product } from "../../../../models/products/product";
import ContextMenu from "./context-menu";
import PaginationActions from "../../../../components/utilities/pagination-actions";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import CreateProductModal from "../../components/CreateProductModal";
import AboutProductModal from "../../components/AboutProductModal";
import { reverse } from "dns";
import { AuthContext } from "../../../../context/auth";
import { SnackbarContext } from "../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import RoleTypes from "../../../../enums/role-types";
import ProfileTypes from "../../../../enums/profile-types";

export default function SubscriptionsTable() {
  const { user } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const [subscriptions, setSubscriptions] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [aboutModalOpened, setAboutModalOpened] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<Product>();
  const [currentUserRole, setCurrentUserRole] = useState(null);

  useEffect(() => {
    startGlobalLoader();
    API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
      (response) => {
        const profile = response.data;
        const userRole = profile?.profile_rbac?.find(
          (profile) => profile.user.id === user?.id
        )?.role.name;
        setCurrentUserRole(userRole);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (subscriptions) {
      return;
    }

    API.get(ApiUri.PACKAGE).then((response) => {
      if (isMounted) {
        const enabledRows = response.data.collection
          .reverse()
          .filter(
            (row) =>
              row.enabled &&
              row.type === "Subscription" &&
              (row.visible || row.profile.id === user?.last_accessed_profile.id)
          );
        setSubscriptions(enabledRows);
        setTotalRows(enabledRows.length);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [subscriptions]); // eslint-disable-line

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setSubscriptions(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setSubscriptions(null);
  };

  const handleRowCellClick = (ps: Product) => {
    setSelectedSubscription(ps);
    setAboutModalOpened(true);
  };

  const handleAboutModalClose = () => {
    setAboutModalOpened(false);
  };

  const handleCreateModalClose = () => {
    setCreateModalOpened(false);
  };

  const hasAtLeastOneSubscriptionForPublishers = subscriptions?.some(
    (sub) => sub.profile_type.name === ProfileTypes.PUBLISHER
  );

  return (
    <React.Fragment>
      <Stack spacing={3}>
        <ActionButtonSmall
          onClick={() => setCreateModalOpened(true)}
          disabled={
            currentUserRole !== RoleTypes.OWNER &&
            currentUserRole !== RoleTypes.ADMIN &&
            !user?.is_mmpz_service_admin
          }
        >
          Create Subscription
        </ActionButtonSmall>

        <Paper elevation={3} sx={{ width: "100%" }}>
          <TableContainer sx={{ height: "55vh" }}>
            <Table stickyHeader={rowsPerPage > 5}>
              <TableHead style={{ borderBottom: "2px solid" }}>
                <TableRow hover>
                  <TableCell width="29%">
                    <b>Name</b>
                  </TableCell>
                  <TableCell width="11%" align="center">
                    <b>Profile Type</b>
                  </TableCell>
                  <TableCell width="11%" align="center">
                    <b>Price</b>
                  </TableCell>
                  <TableCell width="11%" align="center">
                    <b>Discount</b>
                  </TableCell>
                  <TableCell width="11%" align="center">
                    <b>Composition Limit</b>
                  </TableCell>
                  <TableCell width="11%" align="center">
                    <b>Duration</b>
                  </TableCell>
                  <TableCell width="11%" align="center">
                    <b>Quantity Sold</b>
                  </TableCell>
                  <TableCell width="10%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ cursor: "pointer" }}>
                {subscriptions ? (
                  (subscriptions as any)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Product) => {
                      return (
                        <TableRow hover key={row.id || row.name}>
                          <TableCell onClick={() => handleRowCellClick(row)}>
                            {row.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleRowCellClick(row)}
                          >
                            {row.profile_type.name || "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleRowCellClick(row)}
                          >
                            £{row.price}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleRowCellClick(row)}
                          >
                            {(row.discount * 100).toFixed(2)}%
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleRowCellClick(row)}
                          >
                            {row.composition_limit}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleRowCellClick(row)}
                          >
                            {row.duration} day{row.duration > 1 ? "s" : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleRowCellClick(row)}
                          >
                            {row.quantity_sold}
                          </TableCell>
                          {(currentUserRole === RoleTypes.OWNER ||
                            currentUserRole === RoleTypes.ADMIN ||
                            user?.is_mmpz_service_admin) && (
                            <TableCell align="right">
                              <ContextMenu
                                ps={row}
                                subscriptions={subscriptions}
                                setSubscriptions={setSubscriptions}
                                setTotalRows={setTotalRows}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                      <Stack direction="row" justifyContent="center">
                        <Loader message="Loading subscriptions" />
                      </Stack>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </Paper>
      </Stack>
      {createModalOpened && (
        <CreateProductModal
          modalOpened={createModalOpened}
          handleClose={handleCreateModalClose}
          setProducts={setSubscriptions}
          type="Subscription"
          basicSubMode={
            !hasAtLeastOneSubscriptionForPublishers &&
            user?.last_accessed_profile.profile_type.name ===
              ProfileTypes.PUBLISHER_ADMINISTRATOR
          }
        />
      )}

      <AboutProductModal
        modalOpened={aboutModalOpened}
        handleClose={handleAboutModalClose}
        setProducts={setSubscriptions}
        product={selectedSubscription}
        type="Subscription"
        currentUserRole={currentUserRole}
      />
    </React.Fragment>
  );
}
