import API from "../../api";
import ApiUri from "../../api-uri";

export const postCommunicationSettings = async ({ profileId, data }) => {
  try {
    const response = await API.post(
      ApiUri.PROFILE + "/" + profileId + ApiUri.COMMUNICATION_SETTINGS,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};
