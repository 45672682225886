export const productTypes = ["Primary", "Subscription"];

export const profileTypes = [
  { id: 3, name: "Publisher" },
  { id: 4, name: "Songwriter" },
];

export const buildNewContract = (product: any = "Primary", profileType: any = profileTypes[0]) => {
  return {
    publishing_agreement_content: "",
    privacy_policy_content: "",
    terms_of_use_content: "",
    display_publishing_agreement: false,
    product: product,
    profile_type: profileType,
    status: true,
  };
};

export const contentTypes = [
    "publishing_agreement_content",
    // "privacy_policy_content",
    // "terms_of_use_content",
]

export const contentTypesLabels = [
    "Publishing Agreement",
    // "Privacy Policy",
    // "Terms Of Use",
]
