import React, { createContext } from "react";
import { Composition } from "../models/composition/composition";

export interface ICompositionContext {
  composition: Composition;
  setComposition: Function;
}

export const CompositionContext = createContext({} as ICompositionContext);

const CompositionContextProvider = ({
  children,
  composition,
  setComposition,
}: any) => {
  return (
    <CompositionContext.Provider
      value={{
        composition,
        setComposition,
      }}
    >
      {children}
    </CompositionContext.Provider>
  );
};

export default CompositionContextProvider;
