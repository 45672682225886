import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { Box, Chip, Divider, Grid } from "@mui/material";
import { SnackbarContext } from "../../../../context/snackbar";
import SongwritersCard from "./songwriters-card";
import ArtworkCard from "./artwork-card";
import Tags from "./tags";
import { Composition } from "../../../../models/composition/composition";
import { Song } from "../../../../models/song/song";
import CompositionCard from "./composition-card";
import SongsCard from "./songs-card";
import { PlayCircleOutline } from "@mui/icons-material";
import CustomToolbar from "../../../../components/layout/titles/CustomToolbar";
import Compositions from "../../../../enums/compositions";
import ProfileTypes from "../../../../enums/profile-types";
import { AuthContext } from "../../../../context/auth";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import ConfirmationDialogActivator from "../../../../components/confirmation-dialog/confirmation-dialog-activator";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AboutSongModal({
  modalOpened,
  handleClose,
  compositionId,
  disableEdit,
  song,
}) {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [composition, setComposition] = useState({} as Composition);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    let isMounted = true;
    if (!modalOpened) {
      return;
    }

    startGlobalLoader();

    API.get(ApiUri.COMPOSITION + "/" + compositionId).then(
      (response) => {
        if (isMounted) {
          setComposition(response.data);
          stopGlobalLoader();
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
    return () => {
      handleClose();
    };
  }, [modalOpened]); // eslint-disable-line

  const closeSongModal = () => {
    handleClose();
    setComposition({} as Composition);
  };
  const showDownloadCSVButton =
    (user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE &&
      (song?.composition.status === Compositions.STATUS_TAKEN_DOWN ||
        song?.composition.status === Compositions.STATUS_SUBMITTED ||
        song?.composition.status === Compositions.STATUS_PROCESSING ||
        song?.composition.status === Compositions.STATUS_PUBLISHED)) ||
    song?.composition.status === Compositions.STATUS_PROCESSING ||
    song?.composition.status === Compositions.STATUS_PUBLISHED;

  const showProcessButton =
    song?.composition?.status === Compositions.STATUS_SUBMITTED &&
    user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE;

  const testSite =
    window.location.href.includes("test") ||
    window.location.href.includes("localhost");

  const handleProcessClick = () => {
    startGlobalLoader();

    API.post(
      ApiUri.COMPOSITION + "/" + song.composition.id + ApiUri.PROCESS
    ).then(
      (response) => {
        stopGlobalLoader();
        history.push("/songs/process/" + song.id);
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  return (
    <React.Fragment>
      {composition.id ? (
        <Dialog
          className={classes.dialog}
          fullScreen
          open={modalOpened}
          onClose={closeSongModal}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <CustomToolbar>
              <Typography variant="h6" className={classes.title}>
                About Song
              </Typography>
              <IconButton
                color="inherit"
                onClick={closeSongModal}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </CustomToolbar>
          </AppBar>

          <Grid container direction="row">
            <Box marginTop={2} padding={3}>
              <Typography variant="h6" color="primary">
                Composition
              </Typography>
            </Box>

            <CompositionCard
              composition={composition}
              disableEdit={disableEdit}
            />
            <SongwritersCard composition={composition} />

            <Box marginTop={2} padding={3}>
              <Typography variant="h6" color="primary">
                Master Recordings
              </Typography>
            </Box>

            {composition.songs.map((song: Song) => {
              return (
                <React.Fragment key={song.id}>
                  <Grid item xs={12} style={{ marginTop: 20, padding: 20 }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} style={{ padding: 20 }}>
                    <Chip
                      label={song.release_name ?? "No Release Name"}
                      color="primary"
                      icon={<PlayCircleOutline />}
                      size="medium"
                    />
                  </Grid>

                  <ArtworkCard song={song} />
                  <Tags song={song} />
                  <SongsCard song={song} composition={composition} />
                </React.Fragment>
              );
            })}

            {showDownloadCSVButton && (
              <Grid item xs={3} style={{ padding: 20 }}>
                <ActionButtonSmall
                  href={
                    `https://api.mmpz.${
                      testSite ? "test." : ""
                    }myclientzone.com/api/v1` +
                    ApiUri.SONG +
                    "/" +
                    song.id +
                    ApiUri.DOWNLOAD
                  }
                >
                  Download CSV
                </ActionButtonSmall>
              </Grid>
            )}

            {showProcessButton && (
              <Grid item xs={3} style={{ padding: 20 }}>
                <ConfirmationDialogActivator
                  actionName="Process"
                  content="Are you sure you want to pick up this song for processing?"
                  onClick={handleProcessClick}
                />
              </Grid>
            )}
          </Grid>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
}
