import { Paper, Grid, TextField } from "@mui/material";
import React from "react";
import H2 from "../../../../components/layout/titles/h2";
import { Composition } from "../../../../models/composition/composition";

interface ICompositionCard {
  composition: Composition;
}

export default function CompositionCard({ composition }: ICompositionCard) {
  const languagesNames = composition.composition_languages?.map(
    (obj) => obj.language.name
  );

  const languagesNamesString =
    languagesNames.length > 0 ? languagesNames.join(", ") : "";

  return (
    <Paper style={{ padding: 20 }}>
      <H2>Composition</H2>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled
            label="Title"
            value={composition.title || ""}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            label="Tunecode"
            value={composition.tunecode || ""}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            label="ISWC"
            value={composition.iswc || ""}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            label="Territory name"
            value={
              composition.composition_territories[0]?.territory?.name || ""
            }
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            label="Territory percentage"
            value={
              composition.composition_territories[0]?.percentage
                ? composition.composition_territories[0]?.percentage + "%"
                : ""
            }
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            label="Languages"
            value={languagesNamesString}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            disabled
            label="Title Language"
            value={composition?.title_language?.name || " "}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <TextField
            fullWidth
            label="Lyrics"
            name="lyrics"
            variant="outlined"
            multiline
            rows={10}
            disabled
            value={composition.lyrics ?? ""}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
