import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ProfileRbac } from "../../../../../models/profile/profile-rbac";
import ContextMenu from "./context-menu";
import { Profile } from "../../../../../models/profile/profile";

interface IManageUsers {
  profile: Profile;
  reload: any;
}

export default function ManageUsers({ profile, reload }: IManageUsers) {
  return profile ? (
    <Table>
      <TableHead style={{ borderBottom: "2px solid" }}>
        <TableRow>
          <TableCell>
            <b>First Name</b>
          </TableCell>
          <TableCell>
            <b>Last Name</b>
          </TableCell>
          <TableCell>
            <b>Email Address</b>
          </TableCell>
          <TableCell>
            <b>Role</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {profile.profile_rbac.map((row: ProfileRbac) => {
          return (
            <TableRow hover key={row.id}>
              <TableCell>{row.user.user_profile.first_name}</TableCell>
              <TableCell>{row.user.user_profile.last_name}</TableCell>
              <TableCell>{row.user.email}</TableCell>
              <TableCell>{row.role.name}</TableCell>
              <TableCell>
                <ContextMenu
                  profile={profile}
                  profileRbac={row}
                  reload={reload}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  ) : null;
}
