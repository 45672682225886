import { Paper, Stack, Typography, TextField, Fab } from "@mui/material";
import React from "react";
import Compositions from "../../../enums/compositions";
import { Delete } from "@mui/icons-material";

const CompositionBox = ({ composition, index, handleDelete }) => {
  const songwriterNames = composition?.composition?.songwriter_names
    ? composition?.composition?.songwriter_names.join(", ")
    : "";
  return (
    <Paper
      elevation={3}
      sx={{ padding: 2, minWidth: "200px" }}
      key={composition.id}
    >
      <Stack spacing={3}>
        <Stack
          direction={"row"}
          spacing={1}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Typography variant="overline">
            {composition?.composition?.title}
          </Typography>
          <Fab
            size="small"
            onClick={() => {
              handleDelete(index);
            }}
          >
            <Delete />
          </Fab>
        </Stack>

        <Stack spacing={2}>
          <TextField
            fullWidth
            disabled
            size="small"
            label="ISRC"
            value={composition.isrc || " "}
            InputProps={{ readOnly: true }}
          />

          <TextField
            fullWidth
            disabled
            size="small"
            label="Songwriter(s)"
            value={songwriterNames}
            InputProps={{ readOnly: true }}
          />

          <TextField
            fullWidth
            disabled
            size="small"
            label="Status"
            value={
              Compositions.getStatusDisplayName(
                composition?.composition?.status
              ) || " "
            }
            InputProps={{ readOnly: true }}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default CompositionBox;
