import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import RoleTypes from "../../../enums/role-types";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { useHistory } from "react-router-dom";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import CompositionBox from "../../administration/feeds-generator/CompositionBox";
import CompositionsAutocomplete from "../../administration/feeds-generator/CompositionsAutocomplete";
import Compositions from "../../../enums/compositions";
import ProfileTypes from "../../../enums/profile-types";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";

const ExportSongsAsCsv = () => {
  const { user, userRole } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const history = useHistory();
  const [showPage, setShowPage] = useState(false);

  const [publishedStatus, setPublishedStatus] = useState("");
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null);
  const [exportAll, setExportAll] = useState(false);
  const [reportType, setReportType] = useState("");

  const handleSeeThisPage = (seeThisPage) => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowPage(true);
    }
  };

  useEffect(() => {
    if (
      user?.last_accessed_profile?.profile_type?.name === ProfileTypes.SERVICE
    ) {
      const seeThisPage =
        userRole === RoleTypes.ADMIN ||
        userRole === RoleTypes.OWNER ||
        user?.is_mmpz_service_admin;
      handleSeeThisPage(seeThisPage);
    } else {
      startGlobalLoader();
      const parentProfileId = user?.last_accessed_profile?.parent?.id;
      API.get(ApiUri.PROFILE + "/" + parentProfileId).then((response) => {
        const parentProfile = response.data;
        const userRoleForParentProfileFromRbac =
          parentProfile?.profile_rbac.find(
            (profile) => profile.user.id === user?.id
          )?.role.name;
        const userRoleForParentProfile =
          userRoleForParentProfileFromRbac ?? RoleTypes.READ_ONLY;
        const seeThisPage =
          userRole === RoleTypes.ADMIN ||
          userRole === RoleTypes.OWNER ||
          userRoleForParentProfile === RoleTypes.ADMIN ||
          userRoleForParentProfile === RoleTypes.OWNER ||
          user?.is_mmpz_service_admin;
        stopGlobalLoader();
        handleSeeThisPage(seeThisPage);
      });
    }
  }, []);

  const handlePublishedStatusChange = (event) => {
    const { value } = event.target;
    setPublishedStatus(value);
  };

  const handleStartDateChange = (event) => {
    const newDate = event;
    setStartDate(newDate);
  };
  const handleEndDateChange = (event) => {
    const newDate = event.$d;
    setEndDate(newDate);
  };

  const handleExportAllChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      setStartDate(null);
      setEndDate(null);
    }
    setExportAll(checked);
  };

  const handleReportTypeChange = (event) => {
    const { value } = event.target;
    setReportType(value);
  };

  const formatDate = (dateObj) => {
    let date = dateObj;

    // let year = date.getFullYear();
    // let month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    // let day = ("0" + date.getDate()).slice(-2);
    // let hours = ("0" + date.getHours()).slice(-2);
    // let minutes = ("0" + date.getMinutes()).slice(-2);
    // let seconds = ("0" + date.getSeconds()).slice(-2);

    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      12 // Set a fixed time to avoid timezone offsets
    ).toISOString();

    // // Construct the ISO 8601 formatted string
    // let isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    // return isoDateString;
  };

  function formatDateToFileName(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const processFileNameForSongs = (
    user,
    publishedStatus,
    fromDate,
    toDate,
    reportType,
    all
  ) => {
    if (all) {
      return (
        "All Songs " + user?.last_accessed_profile?.profile_information?.name
      );
    } else {
    }
    const formattedFromDate = formatDateToFileName(fromDate);
    const formattedToDate = formatDateToFileName(toDate);
    return (
      Compositions.getStatusDisplayName(publishedStatus) +
      (reportType === "Compositions Only"
        ? " Compositions "
        : " Compositions and Master Recordings ") +
      user?.last_accessed_profile?.profile_information?.name +
      " from " +
      formattedFromDate +
      " to " +
      formattedToDate
    );
  };

  const handleSave = () => {
    const payload = {
      status: publishedStatus,
      from: startDate ? formatDate(startDate?.$d) : null,
      to: endDate ? formatDate(endDate) : null,
      report_type: reportType,
      all: exportAll,
    };
    startGlobalLoader();
    // console.log("🚀 ~ handleSave ~ payload:", payload);
    API.post(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile?.id +
        ApiUri.DOWNLOAD_SONGS,
      payload
    ).then(
      (response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
          // type: "text/csv",
        });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = processFileNameForSongs(
          user,
          publishedStatus,
          startDate?.$d,
          endDate,
          reportType,
          exportAll
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(downloadUrl);
        stopGlobalLoader();
      },
      (error) => {
        // initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const formIsValid =
    exportAll || (startDate && endDate && publishedStatus && reportType);

  return (
    showPage && (
      <Stack marginTop={2} padding={3} spacing={2}>
        <FormControl>
          <InputLabel>Published Status</InputLabel>
          <Select
            value={publishedStatus}
            onChange={handlePublishedStatusChange}
            label="Published Status"
            disabled={exportAll}
          >
            {Compositions.ALL_STATUSES.map((status) => (
              <MenuItem value={status} key={status}>
                {Compositions.getStatusDisplayName(status)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction={"row"} spacing={2}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                format="DD/MM/YYYY"
                disabled={exportAll}
                disableFuture
                sx={{ width: "100%" }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY"
                disableFuture
                disabled={exportAll}
                sx={{ width: "100%" }}
                minDate={startDate}
              />
            </Stack>
          </LocalizationProvider>
        </>

        <FormControl>
          <InputLabel>Report Type</InputLabel>
          <Select
            value={reportType}
            onChange={handleReportTypeChange}
            label="Report Type"
            disabled={exportAll}
          >
            <MenuItem value={"Compositions Only"} key={"Compositions Only"}>
              Compositions Only
            </MenuItem>
            <MenuItem
              value={"Compositions and Master Recording Details"}
              key={"Compositions and Master Recording Details"}
            >
              Compositions and Master Recording Details
            </MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={exportAll}
              onChange={handleExportAllChange}
              color="primary"
            />
          }
          label="All"
        />

        <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
          Export
        </ActionButtonSmall>
      </Stack>
    )
  );
};

export default ExportSongsAsCsv;

