import { Info } from "@mui/icons-material";
import {
  Badge,
  FormControl,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

interface TotalShareBoxProps {
  totalShares: number;
}

const TotalSharesBox: React.FC<TotalShareBoxProps> = ({ totalShares }) => {
  return (
    <Grid item xs={12}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        marginRight={4}
      >
        <FormControl>
          <Tooltip
            title="Total shares for each songwriter added to the song must equal 100% before you can continue to the next step. Once you've reached 100%, you won't be able to add any new songwriters to the song"
            placement="top-end"
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={<Info color="disabled" />}
            />
          </Tooltip>
          <Typography>Total Shares Percentage</Typography>
          <TextField
            helperText={totalShares === 100 ? "" : "Total should sum up to 100"}
            error={totalShares !== 100}
            disabled
            value={totalShares}
            InputProps={{
              endAdornment: "%",
            }}
          />
        </FormControl>
      </Stack>
    </Grid>
  );
};

export default TotalSharesBox;
