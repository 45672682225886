import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import TextEditor from "./TextEditor";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewArticleModal = ({
  modalOpened,
  handleClose,
  setSections,
  section,
}) => {
  const classes = useStyles();
  const [newArticleTitle, setNewArticleTitle] = useState("");
  const [newArticleContent, setNewArticleContent] = useState("");
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closeNewArticleModal = () => {
    setNewArticleTitle("");
    setNewArticleContent("");
    handleClose();
  };

  const handleSave = () => {
    if (newArticleTitle.length === 0 || newArticleContent.length === 0) {
      initSnackbarError("Please enter valid article title and content");
    } else {
      startGlobalLoader();
      const formData = section;
      formData.articles.push({
        title: newArticleTitle,
        content: newArticleContent,
        status: true,
      });
      API.put(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          "/helper" +
          "/" +
          section.id,
        formData
      ).then(
        (response) => {
          initSnackbarSuccess("Article created");
          stopGlobalLoader();
          setSections(null);
          setNewArticleTitle("");
          setNewArticleContent("");
          handleClose();
        },
        (error) => {
          initSnackbarError(error);
          stopGlobalLoader();
          setNewArticleTitle("");
          setNewArticleContent("");
          handleClose();
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeNewArticleModal}
        TransitionComponent={Transition}
        disableEnforceFocus={true}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              New Article
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeNewArticleModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={10} marginX={20}>
          <Stack spacing={2}>
            <TextField
              value={newArticleTitle}
              onChange={(event) => setNewArticleTitle(event.target.value)}
              label="Title"
            />
            <TextEditor
              value={newArticleContent}
              setValue={setNewArticleContent}
              label="Content"
            />
          </Stack>
          <ActionButtonSmall onClick={handleSave}>Save</ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default NewArticleModal;
