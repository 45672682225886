import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext } from "../../../context/auth";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { ExpandMore } from "@mui/icons-material";
import parse from "html-react-parser";
import ProfileTypes from "../../../enums/profile-types";

const ViewHelp = () => {
  const { user } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const [sections, setSections] = useState([] as any);
  const [activeSection, setActiveSection] = useState(null as any);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [activeArticleId, setActiveArticleId] = useState("");

  useEffect(() => {
    startGlobalLoader();
    if (
      user?.last_accessed_profile?.profile_type.name ===
        ProfileTypes.SONGWRITER ||
      user?.last_accessed_profile?.profile_type.name === ProfileTypes.PUBLISHER
    ) {
      API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
        (response) => {
          const profile = response.data;
          API.get(ApiUri.PROFILE + "/" + profile.parent.id + ApiUri.HELPER, {
            params: {
              limit: 100000,
              page: 1,
            },
          }).then(
            (response) => {
              const allSections = response.data.collection;
              const activeSections = allSections.filter(
                (section) => section.status === true
              );
              setSections(activeSections);
              setActiveSection(activeSections[0]);
              stopGlobalLoader();
            },
            (err) => {
              initSnackbarError(err);
              stopGlobalLoader();
            }
          );
        },
        (err) => {
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    } else {
      API.get(
        ApiUri.PROFILE + "/" + user?.last_accessed_profile?.id + ApiUri.HELPER,
        {
          params: {
            limit: 100000,
            page: 1,
          },
        }
      ).then(
        (response) => {
          const allSections = response.data.collection;
          const activeSections = allSections.filter(
            (section) => section.status === true
          );
          setSections(activeSections);
          setActiveSection(activeSections[0]);
          stopGlobalLoader();
        },
        (err) => {
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    }
  }, []);

  const handleChangeSection = (
    event: React.SyntheticEvent,
    newActiveSectionIndex: number
  ) => {
    setActiveSection(sections[newActiveSectionIndex]);
    setActiveSectionIndex(newActiveSectionIndex);
  };

  const handleArticleChange =
    (articleId: string) => (event: React.SyntheticEvent) => {
      activeArticleId === articleId
        ? setActiveArticleId("")
        : setActiveArticleId(articleId);
    };
  return sections ? (
    <Stack spacing={5} marginBottom={2} maxWidth={"70vw"}>
      <Tabs
        value={activeSectionIndex}
        onChange={handleChangeSection}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {sections.map((section) => (
          <Tab label={section.title} key={section.id} />
        ))}
      </Tabs>
      <Stack>
        {activeSection &&
          activeSection.articles
            .filter((article) => article.status === true)
            .map((article, index) => {
              return (
                <Accordion
                  key={index}
                  expanded={activeArticleId === article.id}
                  onChange={handleArticleChange(article.id)}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <b>{article.title}</b>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider sx={{ marginBottom: "10px" }} />
                    {parse(article.content)}
                  </AccordionDetails>
                </Accordion>
              );
            })}
      </Stack>
    </Stack>
  ) : (
    ""
  );
};

export default ViewHelp;
