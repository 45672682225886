import { Avatar, Chip, Grid } from "@mui/material";
import { MusicNoteOutlined } from "@mui/icons-material";
import React from "react";
import Colors from "../../../../enums/colors";
import Compositions from "../../../../enums/compositions";

interface IFilters {
  status: string | null;
  changeStatus: Function;
  songsHaveLoaded: any;
}

export default function Filters({
  status,
  changeStatus,
  songsHaveLoaded,
}: IFilters) {
  const handleClick = (newFilterStatus?: string) => {
    status !== newFilterStatus &&
      songsHaveLoaded &&
      changeStatus(newFilterStatus);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Chip
          avatar={
            <Avatar
              sx={{
                bgcolor:
                  status === Compositions.STATUS_DRAFT
                    ? Colors.white
                    : Colors.grey,
              }}
            >
              <b>1</b>
            </Avatar>
          }
          label="Draft"
          onClick={() => handleClick(Compositions.STATUS_DRAFT)}
          style={{
            color:
              status === Compositions.STATUS_DRAFT ? Colors.white : Colors.grey,
            backgroundColor: Colors.cherry,
            //  border: status === Compositions.STATUS_DRAFT ? `2px solid ${Colors.grey}` : "none",
            fontWeight: status === Compositions.STATUS_DRAFT ? 800 : "initial",
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          avatar={
            <Avatar
              sx={{
                bgcolor:
                  status === Compositions.STATUS_SUBMITTED
                    ? Colors.white
                    : Colors.grey,
              }}
            >
              <b>2</b>
            </Avatar>
          }
          label="Submitted"
          onClick={() => handleClick(Compositions.STATUS_SUBMITTED)}
          style={{
            color:
              status === Compositions.STATUS_SUBMITTED
                ? Colors.white
                : Colors.grey,
            backgroundColor: Colors.bordeaux,
            //  border: status === Compositions.STATUS_SUBMITTED ? `2px solid ${Colors.grey}` : "none",
            fontWeight:
              status === Compositions.STATUS_SUBMITTED ? 800 : "initial",
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          avatar={
            <Avatar
              sx={{
                bgcolor:
                  status === Compositions.STATUS_PROCESSING
                    ? Colors.white
                    : Colors.grey,
              }}
            >
              <b>3</b>
            </Avatar>
          }
          label="Processing"
          onClick={() => handleClick(Compositions.STATUS_PROCESSING)}
          style={{
            color:
              status === Compositions.STATUS_PROCESSING
                ? Colors.white
                : Colors.grey,
            backgroundColor: Colors.crimson,
            //  border: status === Compositions.STATUS_PROCESSING ? `2px solid ${Colors.grey}` : "none",
            fontWeight:
              status === Compositions.STATUS_PROCESSING ? 800 : "initial",
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          avatar={
            <Avatar
              sx={{
                bgcolor:
                  status === Compositions.STATUS_PUBLISHED
                    ? Colors.white
                    : Colors.grey,
              }}
            >
              <b>4</b>
            </Avatar>
          }
          label="Published"
          onClick={() => handleClick(Compositions.STATUS_PUBLISHED)}
          style={{
            color:
              status === Compositions.STATUS_PUBLISHED
                ? Colors.white
                : Colors.grey,
            backgroundColor: Colors.flamingo,
            //  border: status === Compositions.STATUS_PUBLISHED ? `2px solid ${Colors.grey}` : "none",
            fontWeight:
              status === Compositions.STATUS_PUBLISHED ? 800 : "initial",
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          avatar={
            <Avatar
              sx={{
                bgcolor:
                  status === Compositions.STATUS_TAKEN_DOWN
                    ? Colors.white
                    : Colors.grey,
              }}
            >
              <b>5</b>
            </Avatar>
          }
          label="Taken Down"
          onClick={() => handleClick(Compositions.STATUS_TAKEN_DOWN)}
          style={{
            color:
              status === Compositions.STATUS_TAKEN_DOWN
                ? Colors.white
                : Colors.grey,
            backgroundColor: Colors.wine,
            //  border: status === Compositions.STATUS_TAKEN_DOWN ? `2px solid ${Colors.grey}` : "none",
            fontWeight:
              status === Compositions.STATUS_TAKEN_DOWN ? 800 : "initial",
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          avatar={
            <Avatar>
              <MusicNoteOutlined />
            </Avatar>
          }
          label="All"
          style={{ fontWeight: !status ? 800 : "initial" }}
          onClick={() => handleClick()}
        />
      </Grid>
    </Grid>
  );
}
