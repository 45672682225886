import { Paper } from "@mui/material";
import React from "react";
import H2 from "../../../../../components/layout/titles/h2";
import { Composition } from "../../../../../models/composition/composition";
import { SongWriter } from "../../../../../models/composition/song-writer";
import ExternalSongwriter from "./external-songwriter";
import InternalSongwriter from "./internal-songwriter";

interface ISongwritersCard {
  composition: Composition;
  setComposition: React.Dispatch<
    React.SetStateAction<Composition | null | undefined>
  >;
}

export default function SongwritersCard({
  composition,
  setComposition,
}: ISongwritersCard) {
  return (
    <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
      <H2>Songwriters</H2>
      {composition?.song_writers?.map((songWriter: SongWriter, index: number) =>
        songWriter.external_song_writer ? (
          <ExternalSongwriter key={songWriter.id} songWriter={songWriter} />
        ) : (
          <InternalSongwriter
            key={songWriter.id}
            songWriter={songWriter}
            index={index}
            composition={composition}
            setComposition={setComposition}
          />
        )
      )}
    </Paper>
  );
}
