import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import RoleTypes from "../../../enums/role-types";
import ProfileTypes from "../../../enums/profile-types";
import ApiUri from "../../../api/api-uri";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { useHistory } from "react-router-dom";
import API from "../../../api/api";
import {
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";

const DefaultRoayaltyValues = () => {
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const history = useHistory();

  const songwriterProfile = { id: 4, name: ProfileTypes.SONGWRITER };
  const publisherProfile = { id: 3, name: ProfileTypes.PUBLISHER };
  const publisherAdministratorProfile = {
    id: 2,
    name: ProfileTypes.PUBLISHER_ADMINISTRATOR,
  };

  let profileOptions: any = [];
  switch (user?.last_accessed_profile.profile_type.name) {
    case ProfileTypes.SERVICE:
      profileOptions = [publisherAdministratorProfile];
      break;
    case ProfileTypes.PUBLISHER_ADMINISTRATOR:
      profileOptions = [publisherProfile, songwriterProfile];
      break;
    case ProfileTypes.PUBLISHER:
      profileOptions = [songwriterProfile];
      break;
  }
  const [showDefaultRoyaltyValuesPage, setShowDefaultRoyaltyValuesPage] =
    useState(false);
  const [templates, setTemplates] = useState<any>([]);
  const [selectedProfileType, setSelectedProfileType] = useState<any>(
    profileOptions[0]
  );

  const seeThisPage =
    (userRole === RoleTypes.OWNER || userRole === RoleTypes.ADMIN) &&
    (user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE ||
      user?.last_accessed_profile.profile_type.name ===
        ProfileTypes.PUBLISHER_ADMINISTRATOR ||
      user?.last_accessed_profile.profile_type.name === ProfileTypes.PUBLISHER);

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowDefaultRoyaltyValuesPage(true);
      startGlobalLoader();
      API.get(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          ApiUri.ROYALTY_SPLITS_TEMPLATES
      ).then(
        (response) => {
          const templatesToBeAdded = response.data.collection;
          const missingProfiles = profileOptions.filter(
            (profile) =>
              !templatesToBeAdded.some(
                (template) => template.profile_type.id === profile.id
              )
          );

          const dummyTemplates = missingProfiles.map((profile) => ({
            profile_type: profile,
            performance_royalty_split_profile: "",
            performance_royalty_split_parent: "",
            mechanical_royalty_split_profile: "",
            mechanical_royalty_split_parent: "",
            sync_royalty_split_profile: "",
            sync_royalty_split_parent: "",
            lyrics_royalty_split_profile: "",
            lyrics_royalty_split_parent: "",
          }));

          const updatedTemplates = [...templatesToBeAdded, ...dummyTemplates];
          setTemplates(updatedTemplates);
          stopGlobalLoader();
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
    }
  }, []);

  const handleOnSubmit = () => {
    const templateToBeSaved = templates.find(
      (template) => template.profile_type.id === selectedProfileType.id
    );
    if (
      templateToBeSaved.performance_royalty_split_profile +
        templateToBeSaved.performance_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric(
        "Performance rights split invalid, must be 100 in total"
      );
      return;
    }

    if (
      templateToBeSaved.mechanical_royalty_split_profile +
        templateToBeSaved.mechanical_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric(
        "Mechanical rights split invalid, must be 100 in total"
      );
      return;
    }

    if (
      templateToBeSaved.sync_royalty_split_profile +
        templateToBeSaved.sync_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric("Sync rights split invalid, must be 100 in total");
      return;
    }

    if (
      templateToBeSaved.lyrics_royalty_split_profile +
        templateToBeSaved.lyrics_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric("Lyrics rights split invalid, must be 100 in total");
      return;
    }
    startGlobalLoader();

    if (templateToBeSaved.id) {
      API.put(
        ApiUri.ROYALTY_SPLITS_TEMPLATES + "/" + templateToBeSaved.id,
        templateToBeSaved
      ).then(
        (response) => {
          stopGlobalLoader();
          initSnackbarSuccess("Default Roalties Successfully saved");
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    } else {
      API.post(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          ApiUri.ROYALTY_SPLITS_TEMPLATES,
        templateToBeSaved
      ).then(
        (response) => {
          stopGlobalLoader();
          initSnackbarSuccess("Default Roalties Successfully saved");
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    }
  };

  const handleFieldChange = (event) => {
    const { value, name } = event.target;
    const newTemplates = templates.map((template) => {
      let updatedTemplate = { ...template };
      if (updatedTemplate.profile_type.id === selectedProfileType.id) {
        if (value === "") {
          updatedTemplate[name] = value;
        } else {
          updatedTemplate[name] = Number(value);
        }
      }
      return updatedTemplate;
    });

    setTemplates(newTemplates);
  };

  const handleChangeProfile = (event) => {
    const { value } = event.target;
    const newSelectedProfile = profileOptions.find(
      (profile) => profile.id === value
    );
    setSelectedProfileType(newSelectedProfile);
  };

  return showDefaultRoyaltyValuesPage ? (
    <Grid spacing={4} container paddingBottom={3}>
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Profile Type</InputLabel>
            <Select
              name="profile-type"
              value={selectedProfileType.id}
              onChange={handleChangeProfile}
              label="Profile Type"
            >
              {profileOptions.map((profile) => (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {templates
        .filter(
          (template) => template.profile_type.id === selectedProfileType.id
        )
        .map((template) => (
          <Grid item container xs={12} spacing={4} key={template.id}>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography variant="overline" color="primary">
                  Performance Royalty
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="performance_royalty_split_profile"
                    value={template.performance_royalty_split_profile ?? ""}
                    onChange={handleFieldChange}
                    label={`Performance royalty share for ${template?.profile_type?.name} profiles`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="performance_royalty_split_parent"
                    value={template.performance_royalty_split_parent ?? ""}
                    onChange={handleFieldChange}
                    label={`Performance royalty share for ${user?.last_accessed_profile?.profile_information?.name}`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <Typography variant="overline" color="primary">
                  Mechanical Royalty
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="mechanical_royalty_split_profile"
                    value={template.mechanical_royalty_split_profile ?? ""}
                    onChange={handleFieldChange}
                    label={`Mechanical royalty share for ${template?.profile_type?.name} profiles`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="mechanical_royalty_split_parent"
                    value={template.mechanical_royalty_split_parent ?? ""}
                    onChange={handleFieldChange}
                    label={`Mechanical royalty share for ${user?.last_accessed_profile?.profile_information?.name}`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <Typography variant="overline" color="primary">
                  Sync Royalty
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="sync_royalty_split_profile"
                    value={template.sync_royalty_split_profile ?? ""}
                    onChange={handleFieldChange}
                    label={`Sync royalty share for ${template?.profile_type?.name} profiles`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="sync_royalty_split_parent"
                    value={template.sync_royalty_split_parent ?? ""}
                    onChange={handleFieldChange}
                    label={`Sync royalty share for ${user?.last_accessed_profile?.profile_information?.name}`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <Typography variant="overline" color="primary">
                  Lyrics Royalty
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="lyrics_royalty_split_profile"
                    value={template.lyrics_royalty_split_profile ?? ""}
                    onChange={handleFieldChange}
                    label={`Lyrics royalty share for ${template?.profile_type?.name} profiles`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    name="lyrics_royalty_split_parent"
                    value={template.lyrics_royalty_split_parent ?? ""}
                    onChange={handleFieldChange}
                    label={`Lyrics royalty share for ${user?.last_accessed_profile?.profile_information?.name}`}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <ActionButtonSmall onClick={handleOnSubmit}>
                  Save
                </ActionButtonSmall>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  ) : (
    <></>
  );
};

export default DefaultRoayaltyValues;
