import {
  Autocomplete,
  Badge,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { ProAffiliation } from "../../../../../models/composition/pro-affiliation";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { InitialSongwriter } from "./NewSongwriterForm";
import { Info } from "@mui/icons-material";

interface ProAfilitiationFieldProps {
  newSongwriter: SongWriter | InitialSongwriter | any;
  setNewSongwriter:
    | React.Dispatch<React.SetStateAction<SongWriter>>
    | React.Dispatch<React.SetStateAction<InitialSongwriter>>;
  proAffiliations: ProAffiliation[];
}

const ProAfilitiationField: React.FC<ProAfilitiationFieldProps> = ({
  newSongwriter,
  setNewSongwriter,
  proAffiliations,
}) => {
  const handleChange = (value) => {
    setNewSongwriter((previousState) => ({
      ...previousState,
      pro_affiliation: value,
    }));
  };
  // const handleChange = (event: any) => {
  //   const { value } = event.target;

  //   const proAffiliation = { id: value };
  //   newSongwriter.pro_affiliation = proAffiliation as any;
  //   setNewSongwriter((previousState) => ({
  //     ...previousState,
  //     ...newSongwriter,
  //   }));
  // };

  console.log("🚀 ~ !newSongwriter.has_cae_number:", newSongwriter);

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Tooltip
          title="Please select from the dropdown menu. If you aren't a member of a PRO, select NS (none)"
          placement="top-end"
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={<Info color="disabled" />}
          />
        </Tooltip>
        <Autocomplete
          value={newSongwriter?.pro_affiliation ?? null}
          onChange={(event, value) => handleChange(value)}
          options={proAffiliations}
          getOptionLabel={(option) =>
            `${option.name_code} (${option.country_name})`
          }
          disabled={
            newSongwriter?.profile
              ? newSongwriter?.profile?.cae_number?.length == 0
              : !newSongwriter.has_cae_number
          }
          renderInput={(params) => (
            <TextField
              required={newSongwriter.has_cae_number}
              {...params}
              label="PRO Affiliation"
            />
          )}
        />
        {/* <InputLabel required>PRO Affiliation</InputLabel>
        <Select
          onChange={handleChange}
          label="PRO Affiliation"
          value={newSongwriter.pro_affiliation?.id || ""}
        >
          {proAffiliations?.map(
            (proAffiliation: ProAffiliation, index: number) => {
              return (
                <MenuItem key={index} value={proAffiliation.id}>
                  {proAffiliation.name_code} ({proAffiliation.country_name})
                </MenuItem>
              );
            }
          )}
        </Select> */}
      </FormControl>
    </Grid>
  );
};

export default ProAfilitiationField;
