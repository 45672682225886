import { useContext, useEffect, useState } from "react";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { SnackbarContext } from "../../context/snackbar";
import { Language } from "../../models/language/language";

export default function useCustomLanguages() {
  const [customLanguages, setCustomLanguages] = useState<Language[]>([]);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    API.get(ApiUri.CUSTOM_LANGUAGE).then(
      (response) => {
        setCustomLanguages(response.data.collection);
      },
      (err) => {
        initSnackbarError(err);
      },
    );
  }, []); // eslint-disable-line

  return customLanguages;
}
