import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import NewPaymentGateway from "./NewPaymentGateway";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext, IAuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ContextMenu from "./context-menu";

const PaymentSettings = () => {
  const [loading, setLoading] = useState(true);
  const [paymentGateways, setPaymentGateways] = useState<any>(null);
  const { user } = useContext<IAuthContext>(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    if (paymentGateways !== null) {
      return;
    } else {
      setLoading(true);
      API.get(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          "/payment-gateway"
      ).then(
        (response) => {
          setPaymentGateways(response.data.collection);
          setLoading(false);
        },
        (error) => {
          initSnackbarError(error);
          setLoading(false);
        }
      );
    }
  }, [paymentGateways]);

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <React.Fragment>
          <Box m={4}>
            <NewPaymentGateway
              setPaymentGateways={setPaymentGateways}
              paymentGateways={paymentGateways}
            />
          </Box>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Provider</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentGateways &&
                paymentGateways
                  .filter((paymentGateway) => paymentGateway.status)
                  .map((row) => {
                    return (
                      <TableRow hover key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.payment_type.name}</TableCell>
                        <TableCell align="right">
                          <ContextMenu
                            paymentGateway={row}
                            setPaymentGateways={setPaymentGateways}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PaymentSettings;
