import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ProfileRbac } from "../../../models/profile/profile-rbac";
import ContextMenu from "./context-menu";
import { Profile } from "../../../models/profile/profile";
import InviteUserToProfile from "./invite-user-to-profile";
import { AuthContext } from "../../../context/auth";
import RoleTypes from "../../../enums/role-types";

interface IManageUsers {
  profile: Profile;
  reload: any;
}

export default function ManageUsers({ profile, reload }: IManageUsers) {
  const { user } = useContext(AuthContext);

  const currentUserRole = profile.profile_rbac.find(
    (profile) => profile.user.id === user?.id
  )?.role.name;

  return profile ? (
    <React.Fragment>
      {(currentUserRole === RoleTypes.OWNER ||
        currentUserRole === RoleTypes.ADMIN ||
        user?.is_mmpz_service_admin) && (
        <Box m={4}>
          <InviteUserToProfile />
        </Box>
      )}
      <Table>
        <TableHead style={{ borderBottom: "2px solid" }}>
          <TableRow>
            <TableCell>
              <b>First Name</b>
            </TableCell>
            <TableCell>
              <b>Last Name</b>
            </TableCell>
            <TableCell>
              <b>Email Address</b>
            </TableCell>
            <TableCell>
              <b>Role</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profile.profile_rbac.map((row: ProfileRbac) => {
            return (
              <TableRow hover key={row.id}>
                <TableCell>{row.user.user_profile.first_name}</TableCell>
                <TableCell>{row.user.user_profile.last_name}</TableCell>
                <TableCell>{row.user.email}</TableCell>
                <TableCell>{row.role.name}</TableCell>
                {(currentUserRole === RoleTypes.OWNER ||
                  currentUserRole === RoleTypes.ADMIN ||
                  user?.is_mmpz_service_admin) && (
                  <TableCell>
                    <ContextMenu
                      profile={profile}
                      profileRbac={row}
                      reload={reload}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  ) : null;
}
