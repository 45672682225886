import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import {
  Badge,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { SnackbarContext } from "../../../../context/snackbar";
import { Product } from "../../../../models/products/product";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import { Info } from "@mui/icons-material";
import Colors from "../../../../enums/colors";
import FQCN from "../../../../enums/FQCN";
import CustomToolbar from "../../../../components/layout/titles/CustomToolbar";
import ProfileTypes from "../../../../enums/profile-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      margin: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateNewPlusSubModal({
  modalOpened,
  handleClose,
  setSubscriptions,
  subscription,
}) {
  const classes = useStyles();
  const [newImage, setNewImage] = useState(null as any);
  const [loading, setLoading] = useState(true);

  const [ps, setPs] = useState(subscription as any);

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  useEffect(() => {
    if (!modalOpened) {
      return;
    }

    startGlobalLoader();

    API.get(ApiUri.PACKAGE + "/" + subscription?.id).then(
      (response) => {
        setPs(response.data);
        setLoading(false);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );

    return () => {
      handleClose();
    };
  }, [modalOpened]); // eslint-disable-line

  const closeProductModal = () => {
    handleClose();
  };

  const handleSave = () => {
    startGlobalLoader();

    const psWithNumberPrice = { ...ps };
    psWithNumberPrice.price = parseFloat(ps.price);
    setPs(psWithNumberPrice);

    if (!newImage) {
      initSnackbarError("Please upload an image");
      stopGlobalLoader();
    } else {
      const payload = {
        name: ps.name,
        description: ps.description,
        image_name: ps.image_name,
        duration: ps.duration,
        is_monthly: ps.is_monthly,
        is_semester: ps.is_semester,
        is_annual: ps.is_annual,
        composition_limit: ps.composition_limit,
        subprofile_limit: ps.subprofile_limit,
        enabled: ps.enabled,
        admin_percentage: ps.admin_percentage,
        price: parseFloat(ps.price),
        visible: ps.visible,
        quantity_sold: ps.quantity_sold,
        impressions_count: ps.impressions_count,
        type: ps.type,
        discount: ps.discount,
        allow_extras: ps.allow_extras,
        visible_to_admin_only: ps.visible_to_admin_only,
        paypal_product_id: ps.paypal_product_id,
        paypal_plan_id: ps.paypal_plan_id,
        stripe_product_id: ps.stripe_product_id,
        stripe_plan_id: ps.stripe_plan_id,
        profile_type: ps.profile_type,
      };
      API.post(ApiUri.PACKAGE + "/" + ps?.id + ApiUri.PLUS, payload).then(
        (response) => {
          if (newImage) {
            const newProduct = response.data;
            const formData = new FormData();
            formData.append("name", newImage.file.name);
            formData.append("file", newImage.file);

            API.post(ApiUri.FILE, formData, {
              params: {
                object_fqcn: FQCN.PACKAGE,
                object_id: response.data.id,
              },
            }).then(
              (response) => {
                const completePs = newProduct;
                completePs.file = response.data;

                API.put(ApiUri.PACKAGE + "/" + completePs.id, completePs).then(
                  (response) => {
                    initSnackbarSuccess(
                      `${
                        psWithNumberPrice.type === "Primary"
                          ? "Package"
                          : "Subscription"
                      } created`
                    );
                    setNewImage(null);
                    setSubscriptions(undefined);
                    stopGlobalLoader();
                    closeProductModal();
                  },
                  (err) => {
                    initSnackbarError(err);
                    stopGlobalLoader();
                  }
                );
              },
              (err) => {
                initSnackbarError(err);
                stopGlobalLoader();
              }
            );
          } else {
            initSnackbarSuccess(
              `${
                psWithNumberPrice.type === "Primary"
                  ? "Package"
                  : "Subscription"
              } created`
            );
            setNewImage(null);
            setSubscriptions(undefined);
            stopGlobalLoader();
            closeProductModal();
          }
        },
        (err) => {
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    }
  };

  const handleChange = (event) => {
    const newPs = ps;
    const name = event.target.name;
    let value = event.target.value;
    if (name === "admin_percentage" || name === "discount") {
      if (0 <= value && value <= 100) {
        newPs[name] = value / 100;
        setPs((oldPs) => ({ ...oldPs, ...newPs }));
      }
    } else if (name === "price") {
      value = value.replace(/,/g, ".").replace(/^\.|,$/g, "");

      const isValidInput = /^(?!-)\d*(\.\d{0,2})?$/.test(value);

      if (isValidInput) {
        newPs[name] = value;
        setPs((oldPs) => ({ ...oldPs, ...newPs }));
      }
    } else if (
      name === "composition_limit" ||
      name === "duration" ||
      name === "subprofile_limit"
    ) {
      if (0 <= value && String(value).substring(0, 2) !== "00") {
        if (String(value).substring(0, 1) == "0") {
          value = Number(String(value).substring(1));
        }
        newPs[name] = Number(value);
        setPs((oldPs) => ({ ...oldPs, ...newPs }));
      }
    } else if (
      name === "allow_extras" ||
      name === "visible" ||
      name === "visible_to_admin_only"
    ) {
      newPs[name] = event.target.checked;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "name") {
      newPs[name] = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "description" && value.length <= 250) {
      newPs[name] = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "type") {
      newPs[name] = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "duration_period") {
      switch (value) {
        case "monthly":
          newPs.is_monthly = true;
          newPs.is_annual = false;
          newPs.duration = 30;
          break;
        case "annual":
          newPs.is_monthly = false;
          newPs.is_annual = true;
          newPs.duration = 365;
          break;
      }
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "profile_type") {
      newPs.profile_type.name = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    }
  };

  const handleImageChange = function loadFile(event) {
    if (event.target.files.length > 0) {
      const url = URL.createObjectURL(event.target.files[0]);
      const newPs = ps;
      newPs.image_name = event.target.files[0].name;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
      setNewImage({
        url: url,
        name: event.target.files[0].name,
        file: event.target.files[0],
      });
    }
  };

  const isFormValid =
    ps.name &&
    newImage &&
    ps.profile_type.name &&
    ps.admin_percentage &&
    ps.composition_limit &&
    ps.description &&
    ps.price !== "" &&
    ps.price >= 0 &&
    ps.duration &&
    ps.duration > 0 &&
    (ps.profile_type.name === ProfileTypes.PUBLISHER
      ? ps.subprofile_limit > 0
      : true);

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeProductModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Create New "Plus" Subscription
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeProductModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>
        {!loading && (
          <Grid
            container
            direction="row"
            padding={2}
            spacing={2}
            marginTop={3}
            height="100%"
          >
            <Grid container item xs={12} md={3} p={2} justifyContent="center">
              <Stack spacing={2}>
                <InputLabel required>Image</InputLabel>
                {newImage ? (
                  <img
                    style={{
                      width: "300px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    src={newImage.url}
                  />
                ) : (
                  <Stack
                    width="300px"
                    height="300px"
                    sx={{ backgroundColor: Colors.grey }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {" "}
                    300x300{" "}
                  </Stack>
                )}
                <ActionButtonSmall component="label">
                  Upload New Image
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                  />
                </ActionButtonSmall>
              </Stack>
            </Grid>

            <Grid container item xs={12} md={9} spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Name"
                  value={ps.name + " Plus" || ""}
                  name="name"
                  disabled
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Profile Type</InputLabel>
                  <Tooltip
                    title="Determines whether this product appears for songwriters or publishers."
                    placement="top-end"
                  >
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={<Info color="disabled" />}
                    />
                  </Tooltip>
                  <Select
                    name="profile_type"
                    label="Profile Type"
                    value={ps.profile_type.name}
                    onChange={handleChange}
                    disabled
                    fullWidth
                  >
                    <MenuItem value="Songwriter">Songwriter</MenuItem>
                    <MenuItem value="Publisher">Publisher</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  label="Administrator Percentage"
                  name="admin_percentage"
                  value={
                    ps.admin_percentage
                      ? (ps.admin_percentage * 100).toFixed(0)
                      : ""
                  }
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  label="Composition Limit"
                  value={
                    ps.composition_limit ? ps.composition_limit.toFixed(0) : ""
                  }
                  onChange={handleChange}
                  disabled
                  name="composition_limit"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  label="Subprofile Limit"
                  value={
                    ps.subprofile_limit ? ps.subprofile_limit.toFixed(0) : ""
                  }
                  disabled
                  onChange={handleChange}
                  name="subprofile_limit"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Discount"
                  value={ps.discount ? (ps.discount * 100).toFixed(0) : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  name="discount"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  label="Price"
                  value={ps.price}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  name="price"
                  onChange={handleChange}
                />
              </Grid>

              {ps.type === "Primary" ? (
                ""
              ) : (
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel required>Duration</InputLabel>
                    <Select
                      name="duration_period"
                      label="Duration"
                      disabled
                      value={ps.is_monthly ? "monthly" : "annual"}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="monthly">Monthly (30 days)</MenuItem>
                      <MenuItem value="annual">Annual (365 days)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item container xs={12} md={12} spacing={3}>
                <Grid item xs={12} md={8}>
                  <TextField
                    multiline
                    fullWidth
                    required
                    rows={4}
                    label="Description"
                    value={ps.description || ""}
                    name="description"
                    onChange={handleChange}
                    helperText={ps.description.length + "/250"}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <Tooltip
                        title="Determines whether this product appears for songwriters or publishers."
                        placement="bottom"
                      >
                        <Badge
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          badgeContent={<Info color="disabled" />}
                        />
                      </Tooltip>
                      <FormControlLabel
                        label="Visible to Customers"
                        control={
                          <Checkbox
                            checked={ps.visible}
                            name="visible"
                            onChange={handleChange}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      label="Allows Extra"
                      control={
                        <Checkbox
                          checked={ps.allow_extras}
                          name="allow_extras"
                          onChange={handleChange}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="space-around">
                <ActionButtonSmall onClick={handleSave} disabled={!isFormValid}>
                  Save
                </ActionButtonSmall>
              </Box>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </React.Fragment>
  );
}
