import { Badge, FormControl, Grid, TextField, Tooltip } from "@mui/material";
import React from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { InitialSongwriter } from "./NewSongwriterForm";
import { Info } from "@mui/icons-material";

interface PublisherInfoFields {
  newSongwriter: SongWriter | InitialSongwriter;
  setNewSongwriter:
    | React.Dispatch<React.SetStateAction<SongWriter>>
    | React.Dispatch<React.SetStateAction<InitialSongwriter>>;
  usingExistingSongwriter?: boolean;
}
const PublisherInfoFields: React.FC<PublisherInfoFields> = ({
  newSongwriter,
  setNewSongwriter,
  usingExistingSongwriter,
}) => {
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setNewSongwriter((previousState) => ({ ...previousState, [name]: value }));
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Tooltip
            title="Enter the name of the songwriter's publisher, if they don't have one leave blank."
            placement="top-end"
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={<Info color="disabled" />}
            />
          </Tooltip>
          <TextField
            name="publisher_name"
            disabled={
              !newSongwriter.external_song_writer || usingExistingSongwriter
            }
            value={newSongwriter.publisher_name || ""}
            onChange={handleChange}
            type="text"
            label="Publisher Name"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Tooltip
            title="The PRO membership number for the publisher. If you don't know this, leave blank."
            placement="top-end"
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={<Info color="disabled" />}
            />
          </Tooltip>
          <TextField
            name="publisher_cae_number"
            disabled={
              !newSongwriter.external_song_writer || usingExistingSongwriter
            }
            value={newSongwriter.publisher_cae_number || ""}
            onChange={handleChange}
            type="text"
            label="Publisher CAE Number"
            // required
          />
        </FormControl>
      </Grid>
    </React.Fragment>
  );
};

export default PublisherInfoFields;
