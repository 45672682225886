import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { GlobalLoaderContext } from "../../../context/global-loader";
import {
  Badge,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { SnackbarContext } from "../../../context/snackbar";
import { Product } from "../../../models/products/product";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { Info } from "@mui/icons-material";
import FQCN from "../../../enums/FQCN";
import Colors from "../../../enums/colors";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import ProfileTypes from "../../../enums/profile-types";
import RoleTypes from "../../../enums/role-types";
import { AuthContext } from "../../../context/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      margin: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AboutProductModal({
  modalOpened,
  handleClose,
  setProducts,
  product,
  type,
  currentUserRole,
}) {
  //TODO: only show product when everything has loaded
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const mode = "Edit";

  const [newImage, setNewImage] = useState(null as any);
  const [image, setImage] = useState(null as any);
  const [loading, setLoading] = useState(true);

  const defaultPs = {
    name: "",
    duration: 30,
    image_name: null,
    is_monthly: true,
    is_semester: false,
    is_annual: false,
    composition_limit: 0,
    subprofile_limit: 0,
    enabled: true,
    admin_percentage: 0,
    price: 0,
    visible: true,
    quantity_sold: 0,
    impressions_count: 0,
    type: type,
    discount: 0,
    allow_extras: true,
    visible_to_admin_only: false,
    profile_type: { name: "Songwriter" },
    description: "",
    file: null,
  };

  const [ps, setPs] = useState(defaultPs as any);

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  useEffect(() => {
    if (!modalOpened) {
      return;
    }

    startGlobalLoader();

    API.get(ApiUri.PACKAGE + "/" + product.id).then(
      (response) => {
        setPs(response.data);
        if (response.data.file) {
          const imageId = response.data.file.id;
          API.get(ApiUri.FILE + "/" + imageId, { responseType: "blob" }).then(
            (response) => {
              setImage(URL.createObjectURL(response.data));
              setLoading(false);
              stopGlobalLoader();
            },
            (error) => {
              initSnackbarError(error);
              stopGlobalLoader();
            }
          );
        } else {
          setLoading(false);
          stopGlobalLoader();
        }
        // stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );

    return () => {
      handleClose();
    };
  }, [modalOpened]); // eslint-disable-line

  const closeProductModal = () => {
    handleClose();
    setLoading(true);
  };

  const handleSave = () => {
    startGlobalLoader();
    const productToBeUsed = { ...ps, price: parseFloat(ps.price) };

    if (!newImage) {
      API.put(ApiUri.PACKAGE + "/" + product.id, productToBeUsed).then(
        (response) => {
          initSnackbarSuccess(
            `${
              productToBeUsed.type === "Primary" ? "Package" : "Subscription"
            } Edited Succesfully`
          );
          setPs(defaultPs);
          setNewImage(null);
          setProducts(undefined);
          setLoading(true);
          stopGlobalLoader();
          closeProductModal();
        },
        (err) => {
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    } else {
      const formData = new FormData();
      formData.append("name", newImage.file.name);
      formData.append("file", newImage.file);

      API.post(ApiUri.FILE, formData, {
        params: {
          object_fqcn: FQCN.PACKAGE,
          object_id: productToBeUsed.id,
        },
      }).then(
        (response) => {
          const completePs = productToBeUsed;
          completePs.file = response.data;

          API.put(ApiUri.PACKAGE + "/" + completePs.id, completePs).then(
            (response) => {
              initSnackbarSuccess(
                `${
                  productToBeUsed.type === "Primary"
                    ? "Package"
                    : "Subscription"
                } Edited Succesfully`
              );
              setPs(defaultPs);
              setNewImage(null);
              setProducts(undefined);
              setLoading(true);
              stopGlobalLoader();
              closeProductModal();
            },
            (err) => {
              initSnackbarError(err);
              stopGlobalLoader();
            }
          );
        },
        (err) => {
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    }
  };

  const handleChange = (event) => {
    const newPs = ps;
    const name = event.target.name;
    let value = event.target.value;
    if (name === "admin_percentage" || name === "discount") {
      if (0 <= value && value <= 100) {
        newPs[name] = value / 100;
        setPs((oldPs) => ({ ...oldPs, ...newPs }));
      }
    } else if (name === "price") {
      value = value.replace(/,/g, ".").replace(/^\.|,$/g, "");

      // Validate the input using a regular expression
      const isValidInput = /^(?!-)\d*(\.\d{0,2})?$/.test(value);

      if (isValidInput) {
        newPs[name] = value;
        setPs((oldPs) => ({ ...oldPs, ...newPs }));
      }
    } else if (
      name === "composition_limit" ||
      name === "duration" ||
      name === "subprofile_limit"
    ) {
      if (0 <= value && String(value).substring(0, 2) !== "00") {
        if (String(value).substring(0, 1) == "0") {
          value = Number(String(value).substring(1));
        }
        newPs[name] = Number(value);
        setPs((oldPs) => ({ ...oldPs, ...newPs }));
      }
    } else if (
      name === "allow_extras" ||
      name === "visible" ||
      name === "visible_to_admin_only"
    ) {
      newPs[name] = event.target.checked;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "name") {
      newPs[name] = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "description" && value.length <= 250) {
      newPs[name] = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "type") {
      newPs[name] = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "duration_period") {
      switch (value) {
        case "monthly":
          newPs.is_monthly = true;
          newPs.is_annual = false;
          newPs.duration = 30;
          break;
        case "annual":
          newPs.is_monthly = false;
          newPs.is_annual = true;
          newPs.duration = 365;
          break;
      }
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    } else if (name === "profile_type") {
      newPs.profile_type.name = value;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
    }
  };

  const handleImageChange = function loadFile(event) {
    if (event.target.files.length > 0) {
      const url = URL.createObjectURL(event.target.files[0]);
      const newPs = ps;
      newPs.image_name = event.target.files[0].name;
      setPs((oldPs) => ({ ...oldPs, ...newPs }));
      setNewImage({
        url: url,
        name: event.target.files[0].name,
        file: event.target.files[0],
      });
    }
  };
  const isFormValid =
    ps.name &&
    (image || newImage) &&
    ps.profile_type.name &&
    ps.admin_percentage &&
    ps.composition_limit &&
    ps.description &&
    ps.price !== "" &&
    ps.price >= 0 &&
    ps.duration &&
    ps.duration > 0 &&
    (ps.profile_type.name === ProfileTypes.PUBLISHER
      ? ps.subprofile_limit > 0
      : true);

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeProductModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              {mode} {ps.type === "Primary" ? "Package" : "Subscription"}
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeProductModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>
        {loading ? (
          ""
        ) : (
          <Grid
            container
            direction="row"
            padding={2}
            spacing={2}
            marginTop={3}
            height="100%"
          >
            <Grid container item xs={12} md={3} p={2} justifyContent="center">
              <Stack spacing={2}>
                <InputLabel required>Image</InputLabel>
                {newImage ? (
                  <img
                    style={{
                      width: "300px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    src={newImage.url as any}
                  />
                ) : (
                  <img
                    style={{
                      width: "300px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    src={image as any}
                  />
                )}
                <ActionButtonSmall
                  component="label"
                  disabled={
                    currentUserRole !== RoleTypes.OWNER &&
                    currentUserRole !== RoleTypes.ADMIN &&
                    currentUserRole !== RoleTypes.EDITOR &&
                    !user?.is_mmpz_service_admin
                  }
                >
                  Upload Image
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                  />
                </ActionButtonSmall>
              </Stack>
            </Grid>

            <Grid container item xs={12} md={9} spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Name"
                  value={ps.name || ""}
                  name="name"
                  onChange={handleChange}
                  required
                  disabled={
                    product.has_standard ||
                    (currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      currentUserRole !== RoleTypes.EDITOR &&
                      !user?.is_mmpz_service_admin)
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Profile Type</InputLabel>
                  <Tooltip
                    title="Determines whether this product appears for songwriters or publishers."
                    placement="top-end"
                  >
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={<Info color="disabled" />}
                    />
                  </Tooltip>
                  <Select
                    // disabled
                    name="profile_type"
                    label="Profile Type"
                    value={ps.profile_type.name}
                    onChange={handleChange}
                    fullWidth
                    disabled={
                      product.has_standard ||
                      (currentUserRole !== RoleTypes.OWNER &&
                        currentUserRole !== RoleTypes.ADMIN &&
                        currentUserRole !== RoleTypes.EDITOR &&
                        !user?.is_mmpz_service_admin)
                    }
                  >
                    <MenuItem value="Songwriter">Songwriter</MenuItem>
                    <MenuItem value="Publisher">Publisher</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  label="Administrator Percentage"
                  name="admin_percentage"
                  value={
                    ps.admin_percentage
                      ? (ps.admin_percentage * 100).toFixed(0)
                      : ""
                  }
                  // disabled={view}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  disabled={
                    product.has_standard ||
                    (currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      currentUserRole !== RoleTypes.EDITOR &&
                      !user?.is_mmpz_service_admin)
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Composition Limit"
                  value={
                    ps.composition_limit ? ps.composition_limit.toFixed(0) : ""
                  }
                  onChange={handleChange}
                  name="composition_limit"
                  disabled={
                    product.has_standard ||
                    (currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      currentUserRole !== RoleTypes.EDITOR &&
                      !user?.is_mmpz_service_admin)
                  }
                />
              </Grid>

              {ps.profile_type.name === ProfileTypes.PUBLISHER && (
                <Grid item xs={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Subprofile Limit"
                    value={
                      ps.subprofile_limit ? ps.subprofile_limit.toFixed(0) : ""
                    }
                    onChange={handleChange}
                    name="subprofile_limit"
                    disabled={
                      product.has_standard ||
                      (currentUserRole !== RoleTypes.OWNER &&
                        currentUserRole !== RoleTypes.ADMIN &&
                        currentUserRole !== RoleTypes.EDITOR &&
                        !user?.is_mmpz_service_admin)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Quantity Sold"
                  value={ps.quantity_sold ? ps.quantity_sold : 0}
                  onChange={handleChange}
                  disabled
                  name="quantity_sold"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Discount"
                  value={ps.discount ? (ps.discount * 100).toFixed(0) : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  name="discount"
                  onChange={handleChange}
                  disabled={
                    currentUserRole !== RoleTypes.OWNER &&
                    currentUserRole !== RoleTypes.ADMIN &&
                    currentUserRole !== RoleTypes.EDITOR &&
                    !user?.is_mmpz_service_admin
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  label="Price"
                  value={ps.price ?? ""}
                  // disabled={view}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  name="price"
                  onChange={handleChange}
                  disabled={
                    currentUserRole !== RoleTypes.OWNER &&
                    currentUserRole !== RoleTypes.ADMIN &&
                    currentUserRole !== RoleTypes.EDITOR &&
                    !user?.is_mmpz_service_admin
                  }
                />
              </Grid>

              {ps.type === "Primary" ? (
                ""
              ) : (
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel required>Duration</InputLabel>
                    <Select
                      name="duration_period"
                      label="Duration"
                      // disabled={view}
                      value={ps.is_monthly ? "monthly" : "annual"}
                      onChange={handleChange}
                      fullWidth
                      disabled={
                        product.has_standard ||
                        (currentUserRole !== RoleTypes.OWNER &&
                          currentUserRole !== RoleTypes.ADMIN &&
                          currentUserRole !== RoleTypes.EDITOR &&
                          !user?.is_mmpz_service_admin)
                      }
                    >
                      <MenuItem value="monthly">Monthly (30 days)</MenuItem>
                      <MenuItem value="annual">Annual (365 days)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item container xs={12} md={12} spacing={3}>
                <Grid item xs={12} md={8}>
                  <TextField
                    multiline
                    fullWidth
                    required
                    rows={4}
                    label="Description"
                    value={ps.description || ""}
                    // disabled={view}
                    name="description"
                    onChange={handleChange}
                    helperText={ps.description.length + "/250"}
                    disabled={
                      currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      currentUserRole !== RoleTypes.EDITOR &&
                      !user?.is_mmpz_service_admin
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {/* <Grid item xs={12} md={12}>
                    <FormControl>
                      <Tooltip
                        title="Determines whether this product appears only for administrators."
                        placement="bottom"
                      >
                        <Badge
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          badgeContent={<Info color="disabled" />}
                        />
                      </Tooltip>
                    </FormControl>

                    <FormControlLabel
                      label="Visible to Administrator Only"
                      control={
                        <Checkbox
                          checked={ps.visible_to_admin_only}
                          // disabled={view}
                          name="visible_to_admin_only"
                          onChange={handleChange}
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            currentUserRole !== RoleTypes.EDITOR &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                      }
                    />
                  </Grid> */}

                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <Tooltip
                        title="Determines whether this product appears for songwriters or publishers."
                        placement="bottom"
                      >
                        <Badge
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          badgeContent={<Info color="disabled" />}
                        />
                      </Tooltip>
                      <FormControlLabel
                        label="Visible to Customers"
                        control={
                          <Checkbox
                            // disabled={view}
                            checked={ps.visible}
                            name="visible"
                            onChange={handleChange}
                            disabled={
                              currentUserRole !== RoleTypes.OWNER &&
                              currentUserRole !== RoleTypes.ADMIN &&
                              currentUserRole !== RoleTypes.EDITOR &&
                              !user?.is_mmpz_service_admin
                            }
                          />
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      label="Allows Extra"
                      control={
                        <Checkbox
                          // disabled={view}
                          checked={ps.allow_extras}
                          name="allow_extras"
                          onChange={handleChange}
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            currentUserRole !== RoleTypes.EDITOR &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="space-around">
                <ActionButtonSmall
                  onClick={handleSave}
                  disabled={
                    !isFormValid ||
                    (currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      currentUserRole !== RoleTypes.EDITOR &&
                      !user?.is_mmpz_service_admin)
                  }
                >
                  Save
                </ActionButtonSmall>
              </Box>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </React.Fragment>
  );
}
