import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import theme from "../../theme";
import { Grid, Paper, styled } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AddCircle } from "@mui/icons-material";
import { GlobalLoaderContext } from "../../context/global-loader";
import { SnackbarContext } from "../../context/snackbar";

const useStyles = makeStyles(() => {
  return {
    uploadInputContainer: {
      width: 200,
      height: 200,
      backgroundSize: "contain!important",
      backgroundRepeat: "no-repeat!important",

      "&:hover": {
        cursor: "pointer",
      },

      [theme.breakpoints.up("md")]: {
        width: 500,
        height: 500,
        backgroundSize: "contain!important",
        backgroundRepeat: "no-repeat!important",
      },
    },
  };
});

const Input = styled("input")({
  display: "none",
});

interface IFileUpload {
  objectFQCN: string;
  objectId: number;
  callback: Function;
  uri?: string;
  showBackground?: boolean;
  customBackground?: string;
  accept?: string;
}

export default function FileUpload({
  objectFQCN,
  objectId,
  callback,
  uri,
  showBackground = false,
  customBackground,
  accept = "file/*",
}: IFileUpload) {
  const classes = useStyles();
  const [background, setBackground] = useState(customBackground);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  useEffect(() => {
    setBackground(customBackground);
  }, [customBackground]);

  const handleFileUpload = (event) => {
    if (!event.target.files[0]) {
      return;
    }

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("file", file);

    startGlobalLoader("Dots", "Please wait, file uploading");

    const URI = uri ? uri : ApiUri.FILE;

    API.post(URI, formData, {
      params: {
        object_fqcn: objectFQCN,
        object_id: objectId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        setBackground(result.data.file.url);
        initSnackbarSuccess("File successfully uploaded");
        stopGlobalLoader();
        callback(result.data);
      })
      .catch((err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      });
  };

  const elementId = "uploadInput-" + Math.random();

  return showBackground ? (
    <Paper elevation={8}>
      <label htmlFor={elementId}>
        <Input
          onChange={handleFileUpload}
          accept={accept}
          id={elementId}
          type="file"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.uploadInputContainer}
          style={{
            background: background ? `url(${background})` : "lightgrey",
          }}
        >
          {!background && (
            <Grid item>
              <AddCircle color="action" fontSize="large" />
            </Grid>
          )}
        </Grid>
      </label>
    </Paper>
  ) : (
    <label htmlFor={elementId}>
      <Input
        onChange={handleFileUpload}
        accept={accept}
        id={elementId}
        type="file"
      />
      <AddCircle fontSize="large" style={{ cursor: "pointer" }} />
    </label>
  );
}
