import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Colors from "../enums/colors";
import { CustomisationContext } from "../context/customisation";
import { AuthContext } from "../context/auth";
import API from "../api/api";
import ApiUri from "../api/api-uri";
import { SnackbarContext } from "../context/snackbar";
import { GlobalLoaderContext } from "../context/global-loader";
import { useHistory } from "react-router-dom";
import NewProfileForm from "./NewProfileForm";
import { useParams } from "react-router";
import parse from "html-react-parser";

const TermsAndConditionsPage = () => {
  const { url_name, profile_type } = useParams();
  // const { customisation } = useContext(CustomisationContext);
  // const [logoURL, setLogoURL] = useState<string | undefined>(undefined);
  const [content, setContent] = useState("");
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  useEffect(() => {
    startGlobalLoader();
    // const imageId = customisation?.logo?.id;
    // if (imageId) {
    //   API.get(ApiUri.FILE + "/" + imageId, { responseType: "blob" }).then(
    //     (response) => {
    //       setLogoURL(URL.createObjectURL(response.data));
    //     },
    //     (error) => {
    //       initSnackbarError(error);
    //     }
    //   );
    // }

    const payload = {
      product: "subscription",
      profile_type: {
        name: profile_type,
      },
    };
    API.post(
      "/public" + ApiUri.PROFILE + "/" + url_name + ApiUri.GET_CONTRACT,
      payload
    ).then(
      (response) => {
        const publishingAgreementContent =
          response.data.publishing_agreement_content;
        setContent(publishingAgreementContent);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  }, []);

  return (
    <Stack
      // sx={{ backgroundColor: customisation?.mainColor }}
      alignItems={"center"}
      minHeight={"100vh"}
      paddingBottom={3}
      paddingTop={2}
    >
      {/* <img
        src={logoURL || "/mmpz-logo.svg"}
        alt=""
        width="100%"
        loading="eager"
        style={{
          width: "100%",
          maxHeight: "150px",
          objectFit: "scale-down",
        }}
      /> */}
      <Stack m={2} p={4} sx={{ backgroundColor: "#E7EAEB" }}>
        {parse(content)}
      </Stack>
    </Stack>
  );
};

export default TermsAndConditionsPage;
