import React, { useContext } from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CustomisationContext } from "../../../context/customisation";
import Colors from "../../../enums/colors";

const useStyles = makeStyles(() => {
  return {
    button: {
      width: 167,
      minHeight: 36,
      textTransform: "none",
      borderRadius: 0,
    },
  };
});

export default function ActionButtonSmall(props: any) {
  const classes = useStyles();
  const { customisation } = useContext(CustomisationContext);

  const backgroundColor = props.disabled
    ? Colors.grey
    : props.customColor
    ? props.customColor
    : customisation?.buttonColor;

  return (
    <Button
      {...props}
      className={classes.button}
      variant={props.variant ? props.variant : "contained"}
      style={{ backgroundColor }}
    >
      {props.children}
    </Button>
  );
}
