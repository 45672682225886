import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { GlobalLoaderContext } from "../../../context/global-loader";
import Platforms from "../../../enums/platforms";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { SnackbarContext } from "../../../context/snackbar";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import DateTime from "../../../components/utilities/date-time";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { SdCardSharp } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenerateLyricFindModal({ modalOpened, handleClose }) {
  const classes = useStyles();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null);

  const closeGenerateLyricFindModal = () => {
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  const handleStartDateChange = (event) => {
    const newDate = event;
    setStartDate(newDate);
  };
  const handleEndDateChange = (event) => {
    const newDate = event.$d;
    setEndDate(newDate);
  };

  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    } else {
      // change here if the value should be different when exporting all songs
      return "";
    }
  };

  const handleSave = () => {
    // from web is hardcoded
    const formData = {
      date_begin: formatDate(startDate?.$d),
      date_end: formatDate(endDate),
      from: "web",
    };
    startGlobalLoader();
    API.post(ApiUri.FEED + ApiUri.LYRICFIND, formData).then(
      (response) => {
        if (response.data.message) {
          initSnackbarGeneric(response.data.message);
          stopGlobalLoader();
        } else {
          initSnackbarSuccess("LyricFind requested");
          setStartDate(null);
          setEndDate(null);
          stopGlobalLoader();
          closeGenerateLyricFindModal();
        }
      },
      (error) => {
        initSnackbarError(error);
        setStartDate(null);
        setEndDate(null);
        stopGlobalLoader();
        closeGenerateLyricFindModal();
      }
    );
  };

  const formIsValid = startDate && endDate;

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeGenerateLyricFindModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Generate LyricFind
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeGenerateLyricFindModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction={"row"} spacing={2}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                format="DD/MM/YYYY"
                disableFuture
                sx={{ width: "100%" }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY"
                disableFuture
                sx={{ width: "100%" }}
                minDate={startDate}
              />
            </Stack>
          </LocalizationProvider>
          <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
            Generate
          </ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
