import React, { useContext, useEffect, useState } from "react";
import { Composition } from "../../../../../models/composition/composition";
import { Divider, Grid, Stack } from "@mui/material";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import SongWritersPercentageShareContextProvider, {
  SongWritersPercentageShareContext,
} from "../../../../../context/songwriters-percentage-share";
import TotalSharesBox from "./TotalSharesBox";
import SongwritersStepper from "./SongwritersStepper";
import { SongWriter } from "../../../../../models/composition/song-writer";
import NewSongwriterStepper from "./NewSongwriterStepper";
import { SnackbarContext } from "../../../../../context/snackbar";

export const calculateShares = (songWriters: SongWriter[]) => {
  const shares = songWriters.reduce((sum, songWriter) => {
    if (songWriter.share !== null) {
      return sum + songWriter.share;
    }
    return sum;
  }, 0);
  const convertedShares = parseFloat(shares.toFixed(2));
  return convertedShares;
};

interface FormStepTwoProps {
  composition: Composition;
  handleSave: (step: number, currentComposition: Composition) => void;
  mode: "create" | "edit";
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const FormStepTwo: React.FC<FormStepTwoProps> = ({
  composition,
  handleSave,
  mode,
  setActiveStep,
}) => {
  const { initSnackbarError } = useContext(SnackbarContext);
  const [currentComposition, setCurrentComposition] = useState(composition);
  const [activeSongwriterIndex, setActiveSongwriterIndex] = useState(
    mode === "create" ? 0 : -1
  );
  const [showNewSongwriterForm, setShowNewSongwriterForm] = useState(false);

  useEffect(() => {
    if (showNewSongwriterForm) {
      setActiveSongwriterIndex(-1);
    }
  }, [showNewSongwriterForm]);

  useEffect(() => {
    if (activeSongwriterIndex !== -1) {
      setShowNewSongwriterForm(false);
    }
  }, [activeSongwriterIndex]);

  const [totalShares, setTotalShares] = useState(
    calculateShares(composition.song_writers)
  );

  const allSongwritersAreComplete = () => {
    for (const songwriter of currentComposition.song_writers) {
      if (
        !songwriter.id ||
        !songwriter.share ||
        (!songwriter.songwriter_cae_number && songwriter.has_cae_number) ||
        // !songwriter.publisher_name ||
        // !songwriter.publisher_cae_number ||
        (!songwriter.music_contribution && !songwriter.lyrics_contribution) ||
        (!songwriter.pro_affiliation && songwriter.has_cae_number) ||
        (!songwriter.external_song_writer && !songwriter.profile)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    if (!allSongwritersAreComplete()) {
      initSnackbarError("There is at least one incomplete songwriter form.");
    } else if (totalShares !== 100) {
      initSnackbarError(
        "The total shares percentage should amount to 100% before moving forward."
      );
    } else {
      handleSave(1, currentComposition);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TotalSharesBox totalShares={totalShares} />
      </Grid>

      <Grid item xs={12}>
        <SongwritersStepper
          activeSongwriterIndex={activeSongwriterIndex}
          setActiveSongwriterIndex={setActiveSongwriterIndex}
          currentComposition={currentComposition}
          setCurrentComposition={setCurrentComposition}
          setTotalShares={setTotalShares}
        />
      </Grid>

      {totalShares !== 100 && (
        <React.Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <NewSongwriterStepper
              showNewSongwriterForm={showNewSongwriterForm}
              setShowNewSongwriterForm={setShowNewSongwriterForm}
              currentComposition={currentComposition}
              setTotalShares={setTotalShares}
              setCurrentComposition={setCurrentComposition}
            />
          </Grid>
        </React.Fragment>
      )}

      <Grid item xs={12}>
        <Stack direction={"row"} spacing={2}>
          <ActionButtonSmall onClick={() => setActiveStep(0)}>
            Back
          </ActionButtonSmall>
          <ActionButtonSmall onClick={handleNext}>Next</ActionButtonSmall>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FormStepTwo;
