import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Badge,
  Switch,
} from "@mui/material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import useCountries from "../../../hooks/country/countries.hook";
import { Country } from "../../../models/country/country";
import { Profile } from "../../../models/profile/profile";
import ProfileTypes from "../../../enums/profile-types";
import { AuthContext } from "../../../context/auth";
import { Info } from "@mui/icons-material";
import RoleTypes from "../../../enums/role-types";
import { putProfile } from "../../../api/utils/put/putProfile";

export default function GeneralSettings({
  profile,
  missingFields,
  currentUserRole,
}) {
  const countries = useCountries();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [formData, setFormData] = useState<Profile>(profile);
  const { user } = useContext(AuthContext);

  const handleFieldChange = (event: any) => {
    const { name, value, checked } = event.target;

    if (name === "profile_information.name") {
      formData.profile_information.name = value;
    }

    if (name === "profile_information.email") {
      formData.profile_information.email = value;
    }

    if (name === "profile_information.country.id") {
      formData.profile_information.country.id = value;
    }

    if (name === "profile_information.address") {
      formData.profile_information.address = value;
    }

    if (name === "profile_information.phone_number") {
      formData.profile_information.phone_number = value;
    }

    if (name === "profile_information.city") {
      formData.profile_information.city = value;
    }

    if (name === "profile_information.company_registration_number") {
      formData.profile_information.company_registration_number = value;
    }

    if (name === "cae_number") {
      formData.cae_number = value;
    }

    if (name === "has_cae_number") {
      formData.has_cae_number = checked;
      if (!checked) {
        formData.cae_number = "";
      }
    }

    if (name === "signup_private") {
      formData.signup_private = event.target.checked;
    }

    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    startGlobalLoader();
    try {
      await putProfile({ profileId: profile.id, formData });
      initSnackbarSuccess("Profile successfully edited");
    } catch (error) {
      initSnackbarError(error);
    } finally {
      stopGlobalLoader();
    }
  };

  const disableEverything =
    currentUserRole !== RoleTypes.OWNER &&
    currentUserRole !== RoleTypes.ADMIN &&
    !user?.is_mmpz_service_admin;

  const disableCaeNumberField =
    disableEverything ||
    (!formData.has_cae_number &&
      (formData.profile_type.name === ProfileTypes.SONGWRITER ||
        formData.profile_type.name === ProfileTypes.PUBLISHER));

  return formData ? (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <Tooltip
              title="Please contact your Publisher Administrator if you wish to change your profile name."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              name="profile_information.name"
              value={formData.profile_information.name}
              required={true}
              type="text"
              label="Profile Name"
              onChange={handleFieldChange}
              disabled={!user?.is_mmpz_service_admin}
              error={
                missingFields.includes("name") &&
                !formData.profile_information.name
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <Tooltip
              title="Please contact your Publisher Administrator if you wish to change the email address associated with your profile."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              name="profile_information.email"
              value={formData.profile_information.email}
              required={true}
              type="email"
              label="Email Address"
              onChange={handleFieldChange}
              disabled={!user?.is_mmpz_service_admin}
              error={
                missingFields.includes("email") &&
                !formData.profile_information.email
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_type.name"
              value={formData.profile_type.name}
              type="text"
              label="Profile Type"
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_information.address"
              value={formData.profile_information.address || ""}
              type="text"
              label="Address"
              onChange={handleFieldChange}
              error={
                missingFields.includes("address") &&
                !formData.profile_information.address
              }
              required
              disabled={disableEverything}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_information.city"
              value={formData.profile_information.city || ""}
              type="text"
              label="City"
              onChange={handleFieldChange}
              error={
                missingFields.includes("city") &&
                !formData.profile_information.city
              }
              required
              disabled={disableEverything}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <InputLabel required>Country</InputLabel>
            <Select
              name="profile_information.country.id"
              value={formData.profile_information?.country?.id}
              required={true}
              onChange={handleFieldChange}
              error={
                missingFields.includes("country") &&
                !formData.profile_information?.country?.id
              }
              disabled={disableEverything}
              label="Country"
            >
              {countries.map((country: Country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <TextField
              name="profile_information.phone_number"
              value={formData.profile_information.phone_number || ""}
              type="number"
              label="Phone Number"
              onChange={handleFieldChange}
              error={
                missingFields.includes("phone_number") &&
                !formData.profile_information.phone_number
              }
              required
              disabled={disableEverything}
            />
          </FormControl>
        </Grid>

        {(formData.profile_type.name === ProfileTypes.SONGWRITER ||
          formData.profile_type.name === ProfileTypes.PUBLISHER) && (
          <Grid item xs={6} sm={4}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.has_cae_number}
                    onChange={handleFieldChange}
                    name="has_cae_number"
                    color="primary"
                    disabled={disableEverything}
                  />
                }
                label={formData.profile_type.name + " has a CAE/IPI Number"}
              />
            </FormGroup>
          </Grid>
        )}

        <Grid item xs={6} sm={4}>
          {formData.has_cae_number && (
            <FormControl fullWidth style={{ padding: 5 }}>
              <TextField
                name="cae_number"
                value={formData.cae_number || ""}
                type="number"
                label="CAE/IPI Number"
                onChange={handleFieldChange}
                required={!disableCaeNumberField}
                error={
                  missingFields.includes("cae_number") && !formData.cae_number
                }
                disabled={disableCaeNumberField}
              />
            </FormControl>
          )}
        </Grid>

        {formData.profile_type.name ===
          ProfileTypes.PUBLISHER_ADMINISTRATOR && (
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth style={{ padding: 5 }}>
              <TextField
                name="profile_information.company_registration_number"
                value={
                  formData.profile_information.company_registration_number || ""
                }
                label="Company Registration Number"
                onChange={handleFieldChange}
                required
                disabled={disableEverything}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={8} />

        {formData.profile_type.name !== ProfileTypes.SONGWRITER ? (
          <Grid item xs={12} sm={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.signup_private}
                    onChange={handleFieldChange}
                    name="signup_private"
                    color="primary"
                    disabled={disableEverything}
                  />
                }
                label="Sign up under this profile should be private?"
              />
            </FormGroup>
          </Grid>
        ) : null}
      </Grid>

      <Grid style={{ marginTop: 40 }} container direction="row">
        <ActionButtonSmall
          type="submit"
          disabled={disableEverything}
          onClick={handleOnSubmit}
        >
          Save
        </ActionButtonSmall>
      </Grid>
    </React.Fragment>
  ) : null;
}
