import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Profile } from "../../../../models/profile/profile";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { Grid } from "@mui/material";
import ProfileShowcaseCard from "./profile-showcase-card";
import ProfileDetailsCard from "./profile-details-card";
import { SnackbarContext } from "../../../../context/snackbar";
import CustomToolbar from "../../../../components/layout/titles/CustomToolbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "20px",
      marginRight: "20px",
      marginTop: "20px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made children required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AboutSubProfileModal({
  modalOpened,
  handleClose,
  profileId,
  currentUserRole,
}) {
  const classes = useStyles();
  const [profile, setProfile] = useState({} as Profile);
  const [songs, setSongs] = useState<number | undefined>(undefined);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    let isMounted = true;
    if (!modalOpened) {
      return;
    }

    startGlobalLoader();

    API.get(ApiUri.PROFILE + "/" + profileId).then(
      (response) => {
        if (isMounted) {
          setProfile(response.data);
          const profileId = response.data.id;
          API.get(ApiUri.PROFILE + "/" + profileId + ApiUri.SONG, {
            params: {
              limit: 100000000,
              page: 1,
              search_term: "",
            },
          }).then(
            (response) => {
              setSongs(response.data.collection.length);
              stopGlobalLoader();
            },
            (error) => {
              initSnackbarError(error);
              stopGlobalLoader();
            }
          );
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
    return () => {
      handleClose();
    };
  }, [modalOpened]); // eslint-disable-line

  const closeAboutProfileModal = () => {
    handleClose();
    setProfile({} as Profile);
    setSongs(undefined);
  };

  return (
    <React.Fragment>
      {profile.id && songs !== undefined ? (
        <Dialog
          className={classes.dialog}
          fullScreen
          open={modalOpened}
          onClose={closeAboutProfileModal}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <CustomToolbar>
              <Typography variant="h6" className={classes.title}>
                About Profile
              </Typography>
              <IconButton
                color="inherit"
                onClick={closeAboutProfileModal}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </CustomToolbar>
          </AppBar>

          <Grid container direction="row">
            <ProfileShowcaseCard profile={profile} songs={songs} />
            <ProfileDetailsCard
              profile={profile}
              currentUserRole={currentUserRole}
            />
          </Grid>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
}
