import {
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { ProfileContext } from "../../../../../context/profile-context";
import SmallLoader from "../../../../../components/small-loader";
import { RoyaltySplits } from "../../../../../models/profile/royalty-splits";

const initialFormData: RoyaltySplits = {
  id: 0,
  //   id: 1,
  performance_royalty_split_profile: 0,
  performance_royalty_split_parent: 0,
  mechanical_royalty_split_profile: 0,
  mechanical_royalty_split_parent: 0,
  sync_royalty_split_profile: 0,
  sync_royalty_split_parent: 0,
  lyrics_royalty_split_profile: 0,
  lyrics_royalty_split_parent: 0,
};

export default function ProfileRoyaltySplits() {
  const { profile } = useContext(ProfileContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const [formData, setFormData] = useState<RoyaltySplits>(initialFormData);

  // uncomment this when the get royalty splits endpoint is ready
  useEffect(() => {
    API.get(ApiUri.PROFILE + "/" + profile.id + ApiUri.ROYALTY_SPLITS).then(
      (response) => {
        setFormData(response?.data);
      },
      (error) => {
        initSnackbarError(error);
      }
    );
  }, []);

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((previousState) => ({
      ...previousState,
      [name]: parseInt(value),
    }));
  };

  const handleOnSubmit = (event: any) => {
    event.preventDefault();

    if (
      formData.performance_royalty_split_profile +
        formData.performance_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric(
        "Performance rights split invalid, must be 100 in total"
      );
      return;
    }

    if (
      formData.mechanical_royalty_split_profile +
        formData.mechanical_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric(
        "Mechanical rights split invalid, must be 100 in total"
      );
      return;
    }

    if (
      formData.sync_royalty_split_profile +
        formData.sync_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric("Sync rights split invalid, must be 100 in total");
      return;
    }

    if (
      formData.lyrics_royalty_split_profile +
        formData.lyrics_royalty_split_parent !==
      100
    ) {
      initSnackbarGeneric("Lyrics rights split invalid, must be 100 in total");
      return;
    }

    startGlobalLoader();

    API.put(
      ApiUri.PROFILE +
        "/" +
        profile.id +
        ApiUri.ROYALTY_SPLITS +
        "/" +
        formData.id,
      formData
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Royalty splits successfully edited");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return formData.id ? (
    <form
      autoComplete="off"
      onSubmit={handleOnSubmit}
      style={{ width: "100%" }}
    >
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="overline" color="primary">
            Performance Rights
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="performance_royalty_split_profile"
              value={formData.performance_royalty_split_profile ?? ""}
              onChange={handleFieldChange}
              label={`Performance rights share for ${profile.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="performance_royalty_split_parent"
              value={formData.performance_royalty_split_parent ?? ""}
              onChange={handleFieldChange}
              label={`Performance rights share for ${profile.parent.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        spacing={3}
        style={{ marginTop: 40 }}
      >
        <Grid item xs={12}>
          <Typography variant="overline" color="primary">
            Mechanical Rights
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="mechanical_royalty_split_profile"
              value={formData.mechanical_royalty_split_profile ?? ""}
              onChange={handleFieldChange}
              label={`Mechanical rights share for ${profile.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="mechanical_royalty_split_parent"
              value={formData.mechanical_royalty_split_parent ?? ""}
              onChange={handleFieldChange}
              label={`Mechanical rights share for ${profile.parent.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        spacing={4}
        style={{ marginTop: 40 }}
      >
        <Grid item xs={12}>
          <Typography variant="overline" color="primary">
            Sync Rights
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="sync_royalty_split_profile"
              value={formData.sync_royalty_split_profile ?? ""}
              onChange={handleFieldChange}
              label={`Sync rights share for ${profile.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="sync_royalty_split_parent"
              value={formData.sync_royalty_split_parent ?? ""}
              onChange={handleFieldChange}
              label={`Sync rights share for ${profile.parent.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        spacing={4}
        style={{ marginTop: 40 }}
      >
        <Grid item xs={12}>
          <Typography variant="overline" color="primary">
            Lyrics Rights
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="lyrics_royalty_split_profile"
              value={formData.lyrics_royalty_split_profile ?? ""}
              onChange={handleFieldChange}
              label={`Lyrics rights share for ${profile.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextField
              name="lyrics_royalty_split_parent"
              value={formData.lyrics_royalty_split_parent ?? ""}
              onChange={handleFieldChange}
              label={`Lyrics rights share for ${profile.parent.profile_information.name}`}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid item xs={12}>
          <ActionButtonSmall type="submit">Save</ActionButtonSmall>
        </Grid>
      </Grid>
    </form>
  ) : (
    <SmallLoader />
  );
}
