import { Info } from "@mui/icons-material";
import { Badge, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { BlockPicker } from "react-color";

const ColorPicker = ({ updateColor, color }) => {
  const handleColorChange = (newColor) => {
    if (newColor) {
      updateColor(color.name, newColor.hex);
    }
  };

  return (
    <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
      <Stack spacing={1}>
        <Tooltip title={color.title} placement="top-end">
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={<Info color="disabled" />}
          />
        </Tooltip>
        <Typography variant="h5">{color.name}</Typography>
      </Stack>
      <BlockPicker
        color={color.hex}
        triangle="hide"
        onChange={handleColorChange}
      />
    </Stack>
  );
};

export default ColorPicker;
