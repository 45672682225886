import API from "../../api";
import ApiUri from "../../api-uri";

export const getPaymentGateways = async ({ profileId }) => {
  try {
    const response = API.get(
      ApiUri.PROFILE + "/" + profileId + ApiUri.PAYMENT_GATEWAY
    );
    return response;
  } catch (err) {
    throw err;
  }
};
