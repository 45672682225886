import {
  Alert,
  AppBar,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { roundDownToSecondDecimal } from "./ProductCard";
import ManualInvoiceModal from "./ManualInvoiceModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "sticky",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmProductModal = ({
  modalOpened,
  handleClose,
  product,
  paymentGateways,
  imageURL,
  currentSubscription = undefined,
}) => {
  const classes = useStyles();
  const [availablePaymentGateways, setAvailablePaymentGateways] =
    useState(paymentGateways);
  const [paymentGateway, setPaymentGateway] = useState<any>(
    availablePaymentGateways[0]
  );
  const [openManualInvoiceModal, setOpenManualInvoiceModal] = useState(false);
  const [newPrice, setNewPrice] = useState<any>(null);
  useEffect(() => {
    setAvailablePaymentGateways(paymentGateways);
    setPaymentGateway(paymentGateways[0]);
  }, [paymentGateways]);
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const [blockEverything, setBlockEverything] = useState(true);

  useEffect(() => {
    if (currentSubscription && modalOpened) {
      startGlobalLoader();
      const formData = {
        payment_gateway: {
          id: (currentSubscription as any)?.package_transaction?.payment_gateway
            .id,
        },
      };
      API.post(
        ApiUri.PACKAGE + "/" + product.id + ApiUri.CHECK_PRICE,
        formData
      ).then(
        (response) => {
          setBlockEverything(false);
          setNewPrice(response.data.price);
          stopGlobalLoader();
        },
        (error) => {
          setBlockEverything(true);
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
    } else {
      setBlockEverything(false);
    }
  }, [modalOpened, currentSubscription]);

  const closeConfirmProductModal = () => {
    setPaymentGateway(availablePaymentGateways[0]);
    handleClose();
  };

  const handleFieldChange = (event) => {
    const { value } = event.target;
    const newPaymentGateway = paymentGateways.find((p) => p.id === value);
    setPaymentGateway(newPaymentGateway);
  };

  const handleConfirm = () => {
    startGlobalLoader("Dots", "Processing purchase...");
    const formData = {
      payment_gateway: paymentGateway,
      return_url:
        window.location.protocol +
        "//" +
        window.location.host +
        "/songs/success",
      cancel_url:
        window.location.protocol +
        "//" +
        window.location.host +
        ApiUri.SONGS +
        ApiUri.ERROR,
    };
    API.post(
      ApiUri.PACKAGE + "/" + product.id + ApiUri.TRANSACTION,
      formData
    ).then(
      (response) => {
        // skipping purchase process for free products and completing transaction
        if (product.price === 0) {
          const token = response.data.payment_gateway_external_id;
          const productId = product.id;
          API.get("package-transaction", {
            params: { externalId: token },
          }).then(
            (response) => {
              const transaction = response.data;

              const currentDate = new Date();
              const year = currentDate.getFullYear();
              const month = String(currentDate.getMonth() + 1).padStart(2, "0");
              const day = String(currentDate.getDate()).padStart(2, "0");
              const hours = String(currentDate.getHours()).padStart(2, "0");
              const minutes = String(currentDate.getMinutes()).padStart(2, "0");
              const seconds = String(currentDate.getSeconds()).padStart(2, "0");
              const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

              const formData = {
                status: "approved",
                payment_gateway: paymentGateway,
                payment_gateway_external_id: token,
                created_at: formattedDate,
              };
              API.put(
                ApiUri.PACKAGE +
                  "/" +
                  productId +
                  "/transaction" +
                  "/" +
                  transaction.id,
                formData
              ).then(
                (response) => {
                  window.location = window.location;
                  stopGlobalLoader();
                },
                (error) => {
                  stopGlobalLoader();
                  initSnackbarError(error);
                }
              );
            },
            (err) => {
              initSnackbarError(err);
              stopGlobalLoader();
            }
          );
        } else {
          const startsWithHttpsWww = (item) =>
            item.href.startsWith("https://www.");
          localStorage.setItem("productId", product.id);
          localStorage.setItem("productType", product.type);
          localStorage.setItem(
            "paymentGateway",
            JSON.stringify(paymentGateway)
          );

          if (paymentGateway.payment_type.name === "Stripe") {
            localStorage.setItem(
              "token",
              response.data.payment_gateway_external_id
            );
            const url = response?.data?.payment_gateway_create?.url;
            if (url) {
              const paymentAnchor = document.createElement("a");
              paymentAnchor.href = url;
              paymentAnchor.target = "_blank";
              paymentAnchor.style.display = "none";

              // Append the anchor to the body
              document.body.appendChild(paymentAnchor);

              // Simulate a click event to open a new tab
              paymentAnchor.click();

              stopGlobalLoader();
              startGlobalLoader("Dots", "Processing purchase...", true);

              // Remove the anchor from the body
              document.body.removeChild(paymentAnchor);

              window.addEventListener("storage", function (event) {
                if (event.key === "tabClosed" && event.newValue === "true") {
                  // Do something when the other tab is closed
                  window.location = window.location;
                }
              });
            } else {
              initSnackbarError("Something went wrong");
              stopGlobalLoader();
            }
          } else if (paymentGateway.payment_type.name === "Paypal") {
            const link =
              response.data.payment_gateway_create.links.find(
                startsWithHttpsWww
              );
            const url = link.href;
            if (url) {
              const paymentAnchor = document.createElement("a");
              paymentAnchor.href = url;
              paymentAnchor.target = "_blank";
              paymentAnchor.style.display = "none";

              // Append the anchor to the body
              document.body.appendChild(paymentAnchor);

              // Simulate a click event to open a new tab
              paymentAnchor.click();

              stopGlobalLoader();
              startGlobalLoader("Dots", "Processing purchase...", true);

              // Remove the anchor from the body
              document.body.removeChild(paymentAnchor);

              window.addEventListener("storage", function (event) {
                if (event.key === "tabClosed" && event.newValue === "true") {
                  // Do something when the other tab is closed
                  window.location = window.location;
                }
              });
            } else {
              initSnackbarError("Something went wrong");
              stopGlobalLoader();
            }
          }
        }
      },
      (err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  };

  const handleManual = () => {
    setOpenManualInvoiceModal(true);
  };

  const originalPriceAfterDiscount =
    (product.discount ? 1 - product.discount : 1) * product.price;

  const currentTimestamp = Date.now();

  const currentDate = new Date(currentTimestamp);

  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();
  const formattedCurrentDate = `${currentDay}/${currentMonth}/${currentYear}`;
  let msToAdd = 0;
  if (product.is_monthly) {
    msToAdd = 30 * 24 * 60 * 60 * 1000;
  } else if (product.is_annual) {
    msToAdd = 365 * 24 * 60 * 60 * 1000;
  }
  const futureTimestamp = currentTimestamp + msToAdd;

  const futureDate = new Date(futureTimestamp);

  const futureDay = String(futureDate.getDate()).padStart(2, "0");
  const futureMonth = String(futureDate.getMonth() + 1).padStart(2, "0");
  const futureYear = futureDate.getFullYear();
  const formattedFutureDate = `${futureDay}/${futureMonth}/${futureYear}`;

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        open={modalOpened}
        onClose={closeConfirmProductModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md"
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Confirm {product.type === "Primary" ? "Package" : "Subscription"}
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeConfirmProductModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack padding={3} spacing={5} paddingX={20}>
          <Stack spacing={2}>
            <Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                paddingBottom={2}
              >
                <h1>{product.name}</h1>
                <img
                  src={imageURL || ""}
                  alt=""
                  style={{
                    maxHeight: "200px",
                    objectFit: "scale-down",
                  }}
                />
              </Stack>

              {newPrice !== null && newPrice < originalPriceAfterDiscount ? (
                <Paper elevation={5}>
                  <Stack p={2}>
                    <Stack>
                      <Typography
                        variant="h4"
                        sx={{ textDecoration: "line-through" }}
                        color={"red"}
                      >
                        £ {roundDownToSecondDecimal(originalPriceAfterDiscount)}
                      </Typography>
                      <Typography variant="h3">
                        £ {roundDownToSecondDecimal(newPrice)} *
                      </Typography>
                    </Stack>
                    <Typography variant="subtitle1" paddingTop={2}>
                      * In case of subscription upgrade the initial payment
                      price is the difference between the price of the
                      subscription you are upgrading to and the price of the
                      subscription you are currently subscribed to.
                    </Typography>
                  </Stack>
                </Paper>
              ) : newPrice !== null &&
                newPrice === originalPriceAfterDiscount ? (
                <Stack spacing={2}>
                  <Alert severity="info">
                    You currently have an <b>active subscription</b>.
                    Downgrading will cancel the active subscription and begin a
                    new renewal period ending on <b>{formattedFutureDate}</b>.
                  </Alert>
                  <Paper elevation={5}>
                    <Stack p={2}>
                      <Stack>
                        <Typography variant="h3" paddingBottom={1}>
                          £{" "}
                          {roundDownToSecondDecimal(originalPriceAfterDiscount)}{" "}
                          *
                        </Typography>
                        <Typography>
                          <b>New Subscription: </b>
                          {product.name}
                        </Typography>
                        <Typography>
                          <b>Downgrade from: </b>
                          {(currentSubscription as any)?.package.name}
                        </Typography>
                        <Typography>
                          <b>Activation period: </b>
                          {formattedCurrentDate} - {formattedFutureDate} (
                          {product.is_monthly ? "1 month" : "1 year"})
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle1" paddingTop={2}>
                        * In case of subscription downgrade the initial payment
                        price is the full price of the new subscription.
                      </Typography>
                    </Stack>
                  </Paper>
                </Stack>
              ) : (
                <Typography variant="h3">
                  £ {roundDownToSecondDecimal(originalPriceAfterDiscount)}{" "}
                </Typography>
              )}
            </Stack>
            <Stack>
              <Typography>{product.description}</Typography>
            </Stack>
            <Stack>
              <Typography>
                <b>Type: </b>
                {product.type === "Primary" ? "Package" : "Subscription"}
              </Typography>
              <Typography>
                <b>Admin Percentage: </b>
                {Math.floor(product.admin_percentage * 100)}%
              </Typography>
              {product.type === "Primary" && (
                <Typography>
                  <b>Composition Limit: </b>
                  {product.composition_limit}
                </Typography>
              )}
              {product.type !== "Primary" && (
                <Typography>
                  <b>Duration: </b>
                  {product.duration === 30
                    ? "1 month"
                    : `${product.duration} days`}
                </Typography>
              )}
            </Stack>
          </Stack>
          {paymentGateways && paymentGateway && product.price > 0 && (
            <FormControl fullWidth style={{ padding: 5 }}>
              <InputLabel>Products Payment Provider</InputLabel>
              <Select
                name="profile_information.country.id"
                value={paymentGateway.id}
                required={true}
                label="Products Payment Provider"
                onChange={handleFieldChange}
                disabled={blockEverything}
              >
                {paymentGateways.map((paymentGateway) => (
                  <MenuItem key={paymentGateway.id} value={paymentGateway.id}>
                    {paymentGateway.name} ({paymentGateway.payment_type.name})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Stack direction={"row"} spacing={2}>
            <ActionButtonSmall
              onClick={handleConfirm}
              disabled={blockEverything}
            >
              {product.price > 0 ? "Confirm" : "Activate"}
            </ActionButtonSmall>
            {user?.is_mmpz_service_admin && (
              <ActionButtonSmall
                onClick={handleManual}
                disabled={blockEverything}
              >
                Manual
              </ActionButtonSmall>
            )}
          </Stack>
          {/* <a href={url} target="_blank">
            aaa
          </a> */}
        </Stack>
      </Dialog>
      <ManualInvoiceModal
        modalOpened={openManualInvoiceModal}
        handleClose={() => setOpenManualInvoiceModal(false)}
        subscription={product}
        paymentGateway={paymentGateway}
      />
    </React.Fragment>
  );
};

export default ConfirmProductModal;
