import React, { createContext, useContext, useEffect, useState } from "react";
import { Customisation } from "../models/customisation/customisation";
import { AuthContext } from "./auth";
import Colors from "../enums/colors";

export interface ICustomisationContext {
  customisation?: Customisation;
  setCustomisation?: any;
}

export const CustomisationContext = createContext({} as ICustomisationContext);

const CustomisationContextProvider = ({ children }: any) => {
  const defaultCustomisation = {
    mainColor: Colors.cherry,
    textColor: Colors.white,
    backgroundColor: Colors.darkCherry,
    buttonColor: Colors.bordeaux,
    logo: undefined,
  };
  const [customisation, setCustomisation] =
    useState<Customisation>(defaultCustomisation);
  const { user, userRole } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      console.log("🧑 User", user);
      console.log("📝 User Role:", userRole);
      const newCustomisation: Customisation = {
        mainColor:
          user?.last_accessed_profile?.profile_information?.main_color ||
          defaultCustomisation.mainColor,
        textColor:
          user?.last_accessed_profile?.profile_information?.text_color ||
          defaultCustomisation.textColor,
        buttonColor:
          user?.last_accessed_profile?.profile_information?.button_color ||
          defaultCustomisation.buttonColor,
        backgroundColor:
          user?.last_accessed_profile?.profile_information?.background_color ||
          defaultCustomisation.backgroundColor,
        logo:
          user?.last_accessed_profile?.profile_information?.file ||
          defaultCustomisation.logo,
      };
      setCustomisation(newCustomisation);
    }
  }, [user]);

  return (
    <CustomisationContext.Provider
      value={{
        customisation,
        setCustomisation,
      }}
    >
      {children}
    </CustomisationContext.Provider>
  );
};

export default CustomisationContextProvider;
