import React, { useContext, useState } from "react";
import { Box, Divider, Link, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ConfirmationDialogActivator from "../../../components/confirmation-dialog/confirmation-dialog-activator";
import { Edit, LinkOff, Person } from "@mui/icons-material";
import RoleTypes from "../../../enums/role-types";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { Profile } from "../../../models/profile/profile";
import { ProfileRbac } from "../../../models/profile/profile-rbac";
import { AuthContext } from "../../../context/auth";
import UpdateInvoiceModal from "./UpdateInvoiceModal";
import ProfileTypes from "../../../enums/profile-types";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

export default function ContextMenu({
  subscriptionId,
  setSubscriptions,
  transactionId,
  status,
}) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const [openUpdateInvoiceModal, setOpenUpdateInvoiceModal] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    startGlobalLoader();
    API.delete(
      ApiUri.PACKAGE +
        "/" +
        subscriptionId +
        "/transaction" +
        "/" +
        transactionId +
        "/cancel",
      {
        params: { comment: "cancel" },
      }
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Subscription successfully canceled");
        setSubscriptions([]);
        handleClose();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        handleClose();
      }
    );
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ConfirmationDialogActivator
            onClick={handleCancel}
            type="link"
            actionName="Cancel"
          />
        </MenuItem>
        {user?.is_mmpz_service_admin &&
          status === "Inactive" &&
          user.last_accessed_profile.profile_type.name ===
            ProfileTypes.SERVICE && (
            <MenuItem onClick={() => setOpenUpdateInvoiceModal(true)}>
              Update
            </MenuItem>
          )}
      </Menu>
      <UpdateInvoiceModal
        modalOpened={openUpdateInvoiceModal}
        handleClose={() => setOpenUpdateInvoiceModal(false)}
        setSubscriptions={setSubscriptions}
        subscriptionId={subscriptionId}
        transactionId={transactionId}
      />
    </React.Fragment>
  );
}
