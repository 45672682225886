import { FormControl, Grid, InputAdornment, TextField } from "@mui/material";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import { Profile } from "../../../../../models/profile/profile";
import { Icon } from "@iconify/react";
import soundcloudIcon from "@iconify-icons/mdi/soundcloud";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { ProfileSocialMedia } from "../../../../../models/profile/profile-social-media";

interface ISocialMedia {
  profile: Profile;
}

export default function SocialMedia({ profile }: ISocialMedia) {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [formData, setFormData] = useState<ProfileSocialMedia>(
    profile.profile_social_media
  );

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((previousState) => ({ ...previousState, [name]: value }));
  };

  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    startGlobalLoader();

    API.put(
      ApiUri.PROFILE +
        "/" +
        profile.id +
        ApiUri.PROFILE_SOCIAL_MEDIA +
        "/" +
        profile.profile_social_media.id,
      formData
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Social media successfully edited");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleOnSubmit}
      style={{ width: "100%" }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item>
          <FormControl fullWidth>
            <TextField
              name="facebook_url"
              value={formData?.facebook_url ?? ""}
              onChange={handleFieldChange}
              label="Facebook"
              type="url"
              placeholder="https://facebook.com/my-profile"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Facebook />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              name="twitter_url"
              value={formData?.twitter_url ?? ""}
              onChange={handleFieldChange}
              label="Twitter"
              type="url"
              placeholder="https://twitter.com/my-profile"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Twitter />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              name="instagram_url"
              value={formData?.instagram_url ?? ""}
              onChange={handleFieldChange}
              label="Instagram"
              type="url"
              placeholder="https://instagram.com/my-profile"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Instagram />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              name="youtube_url"
              value={formData?.youtube_url ?? ""}
              onChange={handleFieldChange}
              label="YouTube"
              type="url"
              placeholder="https://youtube.com/my-profile"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTube />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              name="soundcloud_url"
              value={formData?.soundcloud_url ?? ""}
              onChange={handleFieldChange}
              label="SoundCloud"
              type="url"
              placeholder="https://soundcloud.com/my-profile"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon width="30px" height="30px" icon={soundcloudIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <ActionButtonSmall type="submit">Save</ActionButtonSmall>
        </Grid>
      </Grid>
    </form>
  );
}
