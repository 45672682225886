import React from "react";
import { Link } from "@mui/material";
import { File } from "../../models/app/file";
import { CloudDownloadRounded } from "@mui/icons-material";
import ApiUri from "../../api/api-uri";

interface IFileDownload {
  file: File;
}

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

export default function FileDownload({ file }: IFileDownload) {
  return file?.id ? (
    <Link
      href={
        `https://api.mmpz.${testSite ? "test." : ""}myclientzone.com/api/v1` +
        ApiUri.FILE +
        "/" +
        file.id
      }
      target="_blank"
    >
      <CloudDownloadRounded fontSize="large" />
    </Link>
  ) : null;
}
