import { Box, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Song } from "../../../../models/song/song";
import { Instrument } from "../../../../models/song/instrument";
import { LyricalTheme } from "../../../../models/song/lyrical-theme";
import { Mood } from "../../../../models/song/tags/mood";
import { Genre } from "../../../../models/song/tags/genre";
import { Tempo } from "../../../../models/song/tags/tempo";
import { Vocals } from "../../../../models/song/tags/vocals";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  }),
);

interface ITags {
  song: Song;
}

export default function Tags({ song }: ITags) {
  const classes = useStyles();

  return song ? (
    <Grid item xs={12} sm={8} md={10} className={classes.gridItem}>
      <Paper elevation={3}>
        <Box p={2}>
          <Box marginBottom={3} marginTop={2}>
            <Typography variant="h6" color="primary">
              Tags
            </Typography>
            <Divider />
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="overline">Instruments</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.instruments.map((instrument: Instrument) => (
                <Chip
                  key={instrument.id}
                  label={instrument.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Lyrical Themes</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.lyrical_themes.map((lyricalTheme: LyricalTheme) => (
                <Chip
                  key={lyricalTheme.id}
                  label={lyricalTheme.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Mood</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.moods.map((mood: Mood) => (
                <Chip
                  key={mood.id}
                  label={mood.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Genre</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.genres.map((genre: Genre) => (
                <Chip
                  key={genre.id}
                  label={genre.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Tempo</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.tempos.map((tempo: Tempo) => (
                <Chip
                  key={tempo.id}
                  label={tempo.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Vocals</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.vocals.map((vocals: Vocals) => (
                <Chip
                  key={vocals.id}
                  label={vocals.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
