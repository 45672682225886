import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { useContext, useState } from "react";
import ProfileContextProvider from "../../../context/profile-context";
import { Profile } from "../../../models/profile/profile";
import ProfileDeliverySplits from "./profile-delivery-splits";
import ProfileRoyaltySplits from "./ProfileRoyaltySplits";
import { AuthContext } from "../../../context/auth";
import ProfileTypes from "../../../enums/profile-types";

interface IRoyaltySplits {
  profile: Profile;
  setProfile: Function;
}

export default function RoyaltySplits({ profile, setProfile }: IRoyaltySplits) {
  const { user } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleStepClick = (step: number) => {
    if (activeStep === step) {
      setActiveStep(-1);
    } else {
      setActiveStep(step);
    }
  };

  return (
    <ProfileContextProvider profile={profile} setProfile={setProfile}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ width: "100%" }}
      >
        <Step key={0} completed={false}>
          <StepLabel
            style={{ cursor: "pointer" }}
            onClick={() => handleStepClick(0)}
          >
            Delivery Splits
          </StepLabel>
          <StepContent>
            <ProfileDeliverySplits />
          </StepContent>
        </Step>
        <Step key={1} completed={false}>
          <StepLabel
            style={{ cursor: "pointer" }}
            onClick={() => handleStepClick(1)}
          >
            Royalty Splits
          </StepLabel>
          <StepContent>
            <ProfileRoyaltySplits />
          </StepContent>
        </Step>
      </Stepper>
    </ProfileContextProvider>
  );
}
