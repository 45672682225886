import API from "../../api";
import ApiUri from "../../api-uri";

export const getCompleteProfile = async ({ profileId }) => {
  try {
    const response = API.get(ApiUri.PROFILE + "/" + profileId);
    return response;
  } catch (err) {
    throw err;
  }
};
