import { useContext, useEffect, useState } from "react";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { SnackbarContext } from "../../context/snackbar";
import { Language } from "../../models/language/language";

export default function useLanguages() {
  const [languages, setLanguages] = useState<Language[]>([]);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    API.get(ApiUri.LANGUAGE).then(
      (response) => {
        setLanguages(response.data.collection);
      },
      (err) => {
        initSnackbarError(err);
      },
    );
  }, []); // eslint-disable-line

  return languages;
}
