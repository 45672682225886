import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ListItemButton, Typography } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Collapse, List } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Colors from "../enums/colors";
import { NavigationContext } from "../context/navigation";
import { Stack } from "@mui/system";
import { CustomisationContext } from "../context/customisation";
import { Person } from "@mui/icons-material";
import { AuthContext } from "../context/auth";
import { GlobalLoaderContext } from "../context/global-loader";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { SnackbarContext } from "../context/snackbar";

export default function ParentProfileButton({ parentProfile }) {
  const { customisation } = useContext(CustomisationContext);
  const useStyles = makeStyles(() => ({
    navLink: {
      textDecoration: "none",
    },
    listItem: {
      minHeight: 30,
      "&:hover": {
        backgroundColor: customisation?.backgroundColor,
      },
      paddingTop: 0,
      paddingBottom: 0,
    },
    nestedListItem: {
      paddingLeft: 50,
    },
    icon: {
      alignContent: "center",
      paddingRight: 8,
      transform: "scale(0.75)",
      color: customisation?.textColor,
    },
    text: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "17px",
      color: customisation?.textColor,
    },
  }));
  const classes = useStyles();
  const { navigation, setNavigation } = useContext(NavigationContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  const handleClick = () => {
    startGlobalLoader();
    API.get(ApiUri.SELECT_PROFILE + "/" + parentProfile.id).then(
      (response) => {
        window.location.href = "/";
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <ListItemButton onClick={handleClick} className={classes.listItem}>
      <Typography className={classes.text}>
        {parentProfile.profile_information.name}
      </Typography>
    </ListItemButton>
  );
}
