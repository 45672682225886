import {
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import NewSongwriterForm, { InitialSongwriter } from "./NewSongwriterForm";
import { Composition } from "../../../../../models/composition/composition";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { SnackbarContext } from "../../../../../context/snackbar";

interface NewSongwriterStepperProps {
  showNewSongwriterForm: boolean;
  setShowNewSongwriterForm: React.Dispatch<React.SetStateAction<boolean>>;
  currentComposition: Composition;
  setTotalShares: React.Dispatch<React.SetStateAction<number>>;
  setCurrentComposition: React.Dispatch<React.SetStateAction<Composition>>;
}

const NewSongwriterStepper: React.FC<NewSongwriterStepperProps> = ({
  showNewSongwriterForm,
  setShowNewSongwriterForm,
  currentComposition,
  setTotalShares,
  setCurrentComposition,
}) => {
  const { initSnackbarSuccess } = useContext(SnackbarContext);
  const toggleForm = () => {
    setShowNewSongwriterForm((previousValue) => !previousValue);
  };

  const saveNewSongwriterCallback = (newSongwriter: SongWriter) => {
    setTotalShares((pastShares) =>
      parseFloat((pastShares + newSongwriter.share).toFixed(2))
    );

    const songWriters = [...currentComposition.song_writers, newSongwriter];

    setCurrentComposition((previousState) => ({
      ...previousState,
      song_writers: songWriters,
    }));

    initSnackbarSuccess("New songwriter successfully added");
    setShowNewSongwriterForm(false);
  };
  return (
    <Stepper activeStep={showNewSongwriterForm ? 0 : -1} orientation="vertical">
      <Step>
        <StepLabel icon={<AddIcon />} style={{ cursor: "pointer" }}>
          <Typography variant="subtitle1" onClick={toggleForm}>
            New Songwriter
          </Typography>
        </StepLabel>
        <StepContent>
          <Grid container>
            <Grid item xs={12}>
              <NewSongwriterForm
                showLyricsContribution={!currentComposition.is_instrumental}
                currentComposition={currentComposition}
                saveNewSongwriterCallback={saveNewSongwriterCallback}
              />
            </Grid>
          </Grid>
        </StepContent>
      </Step>
    </Stepper>
  );
};

export default NewSongwriterStepper;
