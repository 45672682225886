import { Box, Grid, Paper, Typography, Fab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import BigAvatar from "../../../../components/avatars/big-avatar";
import Colors from "../../../../enums/colors";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import ProfileUsersCard from "./profile-users-card";
import { Profile } from "../../../../models/profile/profile";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import soundcloudIcon from "@iconify-icons/mdi/soundcloud";
import { CustomisationContext } from "../../../../context/customisation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
  })
);

interface IProfileShowcaseCard {
  profile: Profile;
  songs: number | undefined;
}

export default function ProfileShowcaseCard({
  profile,
  songs,
}: IProfileShowcaseCard) {
  const classes = useStyles();
  const { customisation } = useContext(CustomisationContext);

  return profile ? (
    <Grid item xs={12} md={6}>
      <Grid container direction="column">
        <Grid item className={classes.gridItem}>
          <Paper elevation={3}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={4}
            >
              <BigAvatar
                alt={profile?.profile_information?.name}
                src="#"
              ></BigAvatar>
              <br></br>
              <Typography variant="h5">
                {profile?.profile_information?.name}
              </Typography>
              <Typography>{profile?.profile_type.name}</Typography>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: "10px" }}
            >
              {profile?.profile_social_media?.facebook_url ? (
                <Grid item>
                  <a
                    href={profile?.profile_social_media?.facebook_url ?? ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Fab size="small" color="primary">
                      <Facebook />
                    </Fab>
                  </a>
                </Grid>
              ) : null}
              {profile?.profile_social_media?.twitter_url ? (
                <Grid item>
                  <a
                    href={profile?.profile_social_media?.twitter_url ?? ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Fab size="small" color="primary">
                      <Twitter />
                    </Fab>
                  </a>
                </Grid>
              ) : null}
              {profile?.profile_social_media?.instagram_url ? (
                <Grid item>
                  <a
                    href={profile?.profile_social_media?.instagram_url ?? ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Fab size="small" color="primary">
                      <Instagram />
                    </Fab>
                  </a>
                </Grid>
              ) : null}
              {profile?.profile_social_media?.youtube_url ? (
                <Grid item>
                  <a
                    href={profile?.profile_social_media?.youtube_url ?? ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Fab size="small" color="primary">
                      <YouTube />
                    </Fab>
                  </a>
                </Grid>
              ) : null}
              {profile?.profile_social_media?.soundcloud_url ? (
                <Grid item>
                  <a
                    href={profile?.profile_social_media?.soundcloud_url ?? ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Fab size="small" color="primary">
                      <Icon width="30px" height="30px" icon={soundcloudIcon} />
                    </Fab>
                  </a>
                </Grid>
              ) : null}
            </Grid>
            <Grid container direction="row">
              <Box
                color={Colors.white}
                bgcolor="primary.main"
                height={80}
                width={1 / 2}
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                <Typography>Songs:</Typography>
                <Typography variant="h4">{songs}</Typography>
              </Box>
              <Box
                color={Colors.white}
                bgcolor={customisation?.mainColor}
                height={80}
                width={1 / 2}
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                <Typography color={customisation?.textColor}>Users:</Typography>
                <Typography variant="h4" color={customisation?.textColor}>
                  {profile?.profile_rbac.length}
                </Typography>
              </Box>
            </Grid>
          </Paper>
        </Grid>

        <ProfileUsersCard profile={profile} />
      </Grid>
    </Grid>
  ) : null;
}
