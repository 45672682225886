import React from "react";
import { Avatar } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      fontSize: "6em",
    },
  };
});

export default function BigAvatar(props: any) {
  const classes = useStyles();
  return <Avatar className={classes.avatar} {...props} />;
}
