export default class Colors {
  static cherry = "#bf0d3e";
  static darkCherry = "#9c062c";
  static bordeaux = "#600c35";
  static crimson = "#913d5f";
  static white = "#fff";
  static black = "#000";
  static flamingo = "#f85168";
  static wine = "#870019";
  static grey = "#bdbdbd";
}
