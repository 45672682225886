import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import ConfirmationDialogActivator from "../../../../../components/confirmation-dialog/confirmation-dialog-activator";
import { Delete } from "@mui/icons-material";
import { SnackbarContext } from "../../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { Composition } from "../../../../../models/composition/composition";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";

interface IInternalSongwriter {
  songWriter: SongWriter;
  index: number;
  composition: Composition;
  setComposition: any;
}

export default function InternalSongwriter({
  songWriter,
  index,
  composition,
  setComposition,
}: IInternalSongwriter) {
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const handleUnlink = (index: number) => {
    const data = {
      profile_id: songWriter?.profile?.id,
      action: "unlink",
    };

    startGlobalLoader();

    API.post(
      ApiUri.SONG_WRITER + "/" + songWriter.id + ApiUri.CONNECT_PROFILE,
      data
    ).then(
      (response) => {
        stopGlobalLoader();
        const songWriters = composition.song_writers.map((sw, index) => {
          if (sw.id === songWriter.id) {
            return response.data;
          }
          return sw;
        });

        setComposition((previousState) => ({
          ...previousState,
          song_writers: songWriters,
        }));
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
    // const newSongWriters = composition.song_writers;

    // newSongWriters.splice(index, 1);

    // setComposition((previousState) => ({
    //   ...previousState,
    //   song_writers: newSongWriters,
    // }));

    // API.delete(
    //   ApiUri.COMPOSITION +
    //     "/" +
    //     composition.id +
    //     ApiUri.SONG_WRITER +
    //     "/" +
    //     songWriter.id
    // ).then(
    //   (response) => {
    //     initSnackbarSuccess("Songwriter deleted");
    //     stopGlobalLoader();
    //   },
    //   (err) => {
    //     stopGlobalLoader();
    //     initSnackbarError(err);
    //   }
    // );
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 40, marginBottom: 20 }}>
      <Grid xs={12} item>
        <Stack direction={"row"} spacing={1}>
          <Typography variant="h6">
            {songWriter.profile.profile_information.name}
          </Typography>
          {index === 0 ? (
            <Chip
              size="small"
              label="Internal Songwriter"
              style={{ marginBottom: 20, marginLeft: 10 }}
            />
          ) : (
            <Chip
              size="small"
              label="Internal Songwriter"
              style={{ marginBottom: 20, marginLeft: 10 }}
              onDelete={() => {
                handleUnlink(index);
              }}
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Stack direction={"row"}>
          <Stack>
            <InputLabel style={{ fontSize: 12 }}>Contributions</InputLabel>
            <FormGroup row>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={songWriter.music_contribution}
                    color="primary"
                  />
                }
                label="Music"
              />
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={songWriter.lyrics_contribution}
                    color="primary"
                  />
                }
                label="Lyrics"
              />
            </FormGroup>
          </Stack>

          {/* {index !== 0 && (
            <Stack
              justifySelf={"end"}
              alignSelf={"start"}
              direction={"row"}
              justifyContent={"end"}
              alignItems={"end"}
              width={"100%"}
            >
              <ConfirmationDialogActivator
                onClick={handleDelete}
                type="fab"
                icon={<Delete />}
                actionName="Delete Songwriter"
                content="Are you sure you want to delete this songwriter? This action can't be reversed."
              />
            </Stack>
          )} */}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled
          value={songWriter.songwriter_cae_number || ""}
          type="text"
          label="Songwriter CAE Number"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.publisher_name || ""}
          type="text"
          label="Publisher Name"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.publisher_cae_number || ""}
          type="text"
          label="Publisher CAE Number"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.pro_affiliation?.name_code || ""}
          type="text"
          label="PRO Affiliation"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          disabled
          value={songWriter.share || ""}
          type="number"
          label="Share"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
}
