import React, { useContext, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ActionButtonSmall from "../layout/buttons/action-button-small";
import useCountries from "../../hooks/country/countries.hook";
import { Country } from "../../models/country/country";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import { SnackbarContext } from "../../context/snackbar";
import { GlobalLoaderContext } from "../../context/global-loader";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

const useStyles = makeStyles(() => {
  return {
    container: {
      maxWidth: 500,
      minHeight: 200,
      padding: 20,
      marginTop: 40,
      margin: "0 auto",
    },
  };
});

interface FormData {
  profile_information: {
    name: string;
    country: {
      id: number | null;
    };
  };
  mmdz_profile?: object;
  jwt_token: string;
}

const formDataInitial: FormData = {
  profile_information: {
    name: "",
    country: {
      id: 0,
    },
  },
  jwt_token: "",
};

export default function AccountsAppRegistration(props) {
  const query = new URLSearchParams(props.location.search);
  const jwtToken = query.get("token") as string;

  if (!jwtToken) {
    window.location.href =
      `https://mmaz.${testSite ? "test." : ""}myclientzone.com` + ApiUri.LOGIN;
  }

  const countries = useCountries();
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  const classes = useStyles();
  const [formData, setFormData] = useState(formDataInitial);

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "profile_information.name") {
      formData.profile_information.name = value;
    }

    if (name === "profile_information.country.id") {
      formData.profile_information.country.id = value;
    }

    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    formData.jwt_token = jwtToken;

    startGlobalLoader();

    API.post(ApiUri.ACCOUNTS_APP_REGISTRATION, formData).then(
      (response) => {
        setRegistrationSuccess(true);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <React.Fragment>
      {jwtToken ? (
        <React.Fragment>
          {!registrationSuccess ? (
            <>
              <Grid container justifyContent="center">
                <Grid item>
                  <h1>Create Your Profile</h1>
                </Grid>
              </Grid>

              <Paper elevation={3} className={classes.container}>
                <form autoComplete="off" onSubmit={handleOnSubmit}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item xs={12}>
                      <FormControl fullWidth style={{ padding: 5 }}>
                        <TextField
                          name="profile_information.name"
                          value={formData.profile_information.name}
                          required={true}
                          type="text"
                          label="Profile Name"
                          placeholder="Enter your profile name"
                          onChange={handleFieldChange}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth style={{ padding: 5 }}>
                        <InputLabel>Country *</InputLabel>
                        <Select
                          name="profile_information.country.id"
                          value={formData.profile_information.country.id}
                          required={true}
                          onChange={handleFieldChange}
                        >
                          {countries.map((country: Country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    style={{ marginTop: 40 }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ActionButtonSmall type="submit">Finish</ActionButtonSmall>
                  </Grid>
                </form>
              </Paper>
            </>
          ) : (
            <Grid container justifyContent="center">
              <Grid item>
                <h1>Account successfully created!</h1>
                <p>
                  Please click the confirmation link in your email to activate
                  it.
                </p>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
