import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import { IAuthContext, AuthContext } from "../../../../context/auth";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import RoleTypes from "../../../../enums/role-types";
import { TwoFactorAuthenticationModal } from "../../../../components/TwoFactorAuthenticationModal";

interface IInvitationEmailDialog {
  isOpened: boolean;
  close: Function;
}

export default function InvitationEmailDialog({
  isOpened,
  close,
}: IInvitationEmailDialog) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [
    twoFactorAuthenticationModalOpen,
    setTwoFactorAuthenticationModalOpen,
  ] = useState(false);

  const { user, userRole } = useContext<IAuthContext>(AuthContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const handleFieldChange = (event) => {
    let { name, value } = event.target;
    const valueWithouthSpace = value.trim();

    const correctedValue =
      valueWithouthSpace === "" ? valueWithouthSpace : value;

    switch (name) {
      case "first_name":
        setFirstName(correctedValue);
        break;
      case "last_name":
        setLastName(correctedValue);
        break;
      case "email":
        setEmail(correctedValue);
        break;
      case "role":
        setRole(correctedValue);
        break;
    }
  };

  const handleOk = (event) => {
    event.preventDefault();
    user?.twoFactorAuthenticationEnabled
      ? setTwoFactorAuthenticationModalOpen(true)
      : handleOnSubmit();
  };

  const handleOnSubmit = () => {
    startGlobalLoader();

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      role: role,
    };

    const emailQuery = { email: email };

    API.get(ApiUri.USER, { params: emailQuery }).then(
      (response) => {
        API.post(
          ApiUri.PROFILE +
            "/" +
            user?.last_accessed_profile.id +
            ApiUri.INVITE_USER,
          data
        ).then(
          (response) => {
            setFirstName("");
            setLastName("");
            setEmail("");
            setRole("");
            initSnackbarSuccess("User successfully invited to profile");
            stopGlobalLoader();
            close();
          },
          (err) => {
            initSnackbarError(err);
            stopGlobalLoader();
          }
        );
      },
      (err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  };

  return (
    <React.Fragment>
      <Dialog disableEscapeKeyDown open={isOpened} fullWidth>
        <DialogTitle>
          <Typography>
            <b>Add user to profile</b>
          </Typography>
        </DialogTitle>
        <Divider />
        <form autoComplete="off" onSubmit={handleOk}>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="first_name"
                    type="first_name"
                    required
                    value={firstName || ""}
                    label="First Name"
                    onChange={handleFieldChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="last_name"
                    type="last_name"
                    required
                    value={lastName || ""}
                    label="Last Name"
                    onChange={handleFieldChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="email"
                    type="email"
                    required
                    value={email || ""}
                    label="Email"
                    onChange={handleFieldChange}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel required>Role</InputLabel>
                  <Select
                    name="role"
                    label="role"
                    required
                    value={role}
                    onChange={handleFieldChange}
                  >
                    {userRole === RoleTypes.OWNER && (
                      <MenuItem value={RoleTypes.OWNER}>
                        {RoleTypes.OWNER}
                      </MenuItem>
                    )}
                    <MenuItem value={RoleTypes.ADMIN}>
                      {RoleTypes.ADMIN}
                    </MenuItem>
                    <MenuItem value={RoleTypes.EDITOR}>
                      {RoleTypes.EDITOR}
                    </MenuItem>
                    <MenuItem value={RoleTypes.READ_ONLY}>
                      {RoleTypes.READ_ONLY}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginBottom: "10px" }}>
            <Grid container justifyContent="space-evenly">
              <ActionButtonSmall onClick={close}>Cancel</ActionButtonSmall>
              <ActionButtonSmall type="submit" color="primary">
                Ok
              </ActionButtonSmall>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <TwoFactorAuthenticationModal
        modalOpened={twoFactorAuthenticationModalOpen}
        handleClose={() => setTwoFactorAuthenticationModalOpen(false)}
        onSuccess={handleOnSubmit}
        stopLoadingAfterSuccess={false}
      />
    </React.Fragment>
  );
}
