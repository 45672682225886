import React from "react";
import styled from "styled-components";
import Colors from "../../../enums/colors";

const StyledH2 = styled.h2`
  font-weight: 700;
  font-size: 25px;
`;

export default function H2(props: any) {
  return <StyledH2>{props.children}</StyledH2>;
}
