import { FormControl, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import { Song } from "../../../../../models/song/song";
import { Autocomplete } from "@mui/material";
import { Instrument } from "../../../../../models/song/instrument";
import { LyricalTheme } from "../../../../../models/song/lyrical-theme";
import { Genre } from "../../../../../models/song/tags/genre";
import { AuthContext } from "../../../../../context/auth";
import { Mood } from "../../../../../models/song/tags/mood";
import { Tempo } from "../../../../../models/song/tags/tempo";
import { Vocals } from "../../../../../models/song/tags/vocals";
import { Type } from "../../../../../models/song/tags/type";

interface IFormStepFour {
  song: Song;
  setSong: Function;
  isInstrumental: boolean;
}

export default function FormStepFour({
  song,
  setSong,
  isInstrumental,
}: IFormStepFour) {
  const { user } = useContext(AuthContext);
  const [instruments, setInstruments] = useState<Array<Instrument>>([]);
  const [lyricalThemes, setLyricalThemes] = useState<Array<LyricalTheme>>([]);
  const [genres, setGenres] = useState<Array<Genre>>([]);
  const [moods, setMoods] = useState<Array<Mood>>([]);
  const [tempos, setTempos] = useState<Array<Tempo>>([]);
  const [vocals, setVocals] = useState<Array<Vocals>>([]);
  const [types, setTypes] = useState<Array<Type>>([]);

  const handleGenresFieldChange = (value) => {
    setSong((previousState) => ({ ...previousState, genres: value }));
  };

  const handleInstrumentsFieldChange = (value) => {
    setSong((previousState) => ({ ...previousState, instruments: value }));
  };

  const handleLyricalThemesFieldChange = (value) => {
    setSong((previousState) => ({ ...previousState, lyrical_themes: value }));
  };

  const handleMoodsFieldChange = (value) => {
    setSong((previousState) => ({ ...previousState, moods: value }));
  };

  const handleTemposFieldChange = (value) => {
    setSong((previousState) => ({ ...previousState, tempos: value }));
  };

  const handleVocalsFieldChange = (value) => {
    setSong((previousState) => ({ ...previousState, vocals: value }));
  };

  const handleTypesFieldChange = (value) => {
    setSong((previousState) => ({ ...previousState, types: value }));
  };

  useEffect(() => {
    API.get(ApiUri.SONG + ApiUri.INSTRUMENT).then((response) => {
      setInstruments(response.data.collection);
    });

    API.get(ApiUri.SONG + ApiUri.LYRICAL_THEME).then((response) => {
      setLyricalThemes(response.data.collection);
    });

    API.get(ApiUri.SONG + ApiUri.GENRE).then((response) => {
      setGenres(response.data.collection);
    });

    API.get(ApiUri.SONG + ApiUri.TEMPO).then((response) => {
      setTempos(response.data.collection);
    });

    API.get(ApiUri.SONG + ApiUri.MOOD).then((response) => {
      setMoods(response.data.collection);
    });

    API.get(ApiUri.SONG + ApiUri.VOCALS).then((response) => {
      setVocals(response.data.collection);
    });

    API.get(ApiUri.SONG + ApiUri.TYPE).then((response) => {
      setTypes(response.data.collection);
    });
  }, []); // eslint-disable-line

  return (
    <form>
      <Grid container spacing={4} alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={song.genres ?? []}
              onChange={(event, value) => handleGenresFieldChange(value)}
              options={genres}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField required {...params} label="Genre" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={song.instruments ?? []}
              onChange={(event, value) => handleInstrumentsFieldChange(value)}
              options={instruments}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField required {...params} label="Instruments" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={song.lyrical_themes ?? []}
              onChange={(event, value) => handleLyricalThemesFieldChange(value)}
              options={lyricalThemes}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  required={!isInstrumental}
                  {...params}
                  label="Lyrical Themes"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={song.moods ?? []}
              onChange={(event, value) => handleMoodsFieldChange(value)}
              options={moods}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField required {...params} label="Mood/Feel" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={song.tempos ?? []}
              onChange={(event, value) => handleTemposFieldChange(value)}
              options={tempos}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField required {...params} label="Tempo" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={song.vocals ?? []}
              onChange={(event, value) => handleVocalsFieldChange(value)}
              options={vocals}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  required={!isInstrumental}
                  {...params}
                  label="Vocals"
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* {user?.is_mmpz_service_admin ? ( */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              value={song.types ?? []}
              onChange={(event, value) => handleTypesFieldChange(value)}
              options={types}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField required {...params} label="Type" />
              )}
            />
          </FormControl>
        </Grid>
        {/* ) : null} */}
      </Grid>
    </form>
  );
}
