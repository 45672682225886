import React, { useContext } from "react";
import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AuthContext } from "../context/auth";
import Colors from "../enums/colors";
import SmallAvatar from "../components/avatars/small-avatar";
import { AccountCircleRounded } from "@mui/icons-material";
import API from "../api/api";
import ApiUri from "../api/api-uri";
import { SnackbarContext } from "../context/snackbar";
import { GlobalLoaderContext } from "../context/global-loader";
import { CustomisationContext } from "../context/customisation";

const useStyles = makeStyles(() => {
  return {
    profileDropdownBox: {
      marginRight: 30,
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

export default function ProfileSelector() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { initSnackbarError } = useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { customisation } = useContext(CustomisationContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectProfile = (profile: any) => {
    handleClose();
    startGlobalLoader();
    API.get(ApiUri.SELECT_PROFILE + "/" + profile.id).then(
      (response) => {
        window.location.href = "/";
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.profileDropdownBox}
        onClick={handleClick}
      >
        <SmallAvatar
          profileType={user?.last_accessed_profile.profile_type.name}
        />
        {/* <h5 color={Colors.white} style={{ marginLeft: 5 }}> */}
        <Typography sx={{ marginLeft: 1, color: customisation?.textColor }}>
          {user?.last_accessed_profile.profile_information.name}
        </Typography>
        {/* </h5> */}
        <ArrowDropDownIcon sx={{ color: customisation?.textColor }} />
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ top: 5 }}
      >
        <Box ml={"15px"} mt={2}>
          <b>Main Profiles</b>
        </Box>
        {user?.main_profiles.map((profile: any, index) => (
          <MenuItem
            key={`main-${profile.id}-${index}`}
            onClick={() => handleSelectProfile(profile)}
          >
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <SmallAvatar profileType={profile.profile_type.name} />
              <b style={{ fontSize: 12 }}>{profile.profile_information.name}</b>
            </Stack>
          </MenuItem>
        ))}

        <Box ml={"15px"} mt={2}>
          <b>Recently Accessed</b>
        </Box>
        {user?.last_accessed_profiles.map((profile: any) => (
          <MenuItem
            key={`recent-${profile.id}`}
            onClick={() => handleSelectProfile(profile)}
          >
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <SmallAvatar profileType={profile.profile_type.name} />
              <b style={{ fontSize: 12 }}>{profile.profile_information.name}</b>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
