import { useContext, useEffect, useState } from "react";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { AuthContext } from "../../context/auth";
import { GlobalLoaderContext } from "../../context/global-loader";
import { SnackbarContext } from "../../context/snackbar";
import { Profile } from "../../models/profile/profile";

export default function useLastAccessedProfile() {
  const [profile, setProfile] = useState<Profile>();
  const { user } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  useEffect(() => {
    startGlobalLoader();
    API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
      (response) => {
        setProfile(response.data);
        stopGlobalLoader();
      },
      (err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      },
    );
  }, []); // eslint-disable-line

  return profile;
}
