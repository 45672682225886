import {
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Song } from "../../../../../models/song/song";
import FileUpload from "../../../../../components/utilities/file-upload";
import FQCN from "../../../../../enums/FQCN";
import ApiUri from "../../../../../api/api-uri";
import Songs from "../../../../../enums/songs";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { FullSongVersion } from "../../../../../models/song/full-song-version";
import { InstrumentalSongVersion } from "../../../../../models/song/instrumental-song-version";
import { CleanSongVersion } from "../../../../../models/song/clean-song-version";
import FileDownload from "../../../../../components/utilities/file-download";
import { ExtraInstrumentalSongVersion } from "../../../../../models/song/extra-instrumental-song-version";
import { PlayCircleFilled } from "@mui/icons-material";
import FileRemove from "../../../../../components/utilities/file-remove";
import { SnackbarContext } from "../../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import API from "../../../../../api/api";

interface IFormStepTwo {
  song: Song;
  setSong: Function;
}

export default function FormStepwo({ song, setSong }: IFormStepTwo) {
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const [songHasCleanVersion, setSongHasCleanVersion] = useState(
    song.clean_song_version !== null
  );
  const [songHasExtraInstrumentalVersion, setSongHasExtraInstrumentalVersion] =
    useState(song.extra_instrumental_song_version !== null);

  const handleSongHasCleanVersionClick = () => {
    setSongHasCleanVersion(!songHasCleanVersion);
  };

  const handleSongHasExtraInstrumentalVersionClick = () => {
    setSongHasExtraInstrumentalVersion(!songHasExtraInstrumentalVersion);
  };

  const buildSongVersionUri = (version: string) => {
    return ApiUri.SONG + "/" + song.id + ApiUri.VERSION + "?version=" + version;
  };

  const handleFullVersionUploadSuccess = (data: FullSongVersion) => {
    setSong((previousState) => ({ ...previousState, full_song_version: data }));
  };

  const handleInstrumentalVersionUploadSuccess = (
    data: InstrumentalSongVersion
  ) => {
    setSong((previousState) => ({
      ...previousState,
      instrumental_song_version: data,
    }));
  };

  const handleExtraInstrumentalVersionUploadSuccess = (
    data: ExtraInstrumentalSongVersion
  ) => {
    setSong((previousState) => ({
      ...previousState,
      extra_instrumental_song_version: data,
    }));
  };

  const handleCleanVersionUploadSuccess = (data: CleanSongVersion) => {
    setSong((previousState) => ({
      ...previousState,
      clean_song_version: data,
    }));
  };

  const handleRemoveSong = (version: string) => {
    let versionName = "";
    switch (version) {
      case "full_song_version":
        versionName = "full";
        break;
      case "instrumental_song_version":
        versionName = "instrumental";
        break;
      case "extra_instrumental_song_version":
        versionName = "extra_instrumental";
        break;
      case "clean_song_version":
        versionName = "clean";
        break;
    }

    startGlobalLoader();
    API.delete(buildSongVersionUri(versionName)).then(
      (response) => {
        setSong((previousState) => ({
          ...previousState,
          [version]: null,
        }));
        initSnackbarSuccess("The song has been removed");
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <form>
      <Grid container spacing={4}>
        {/* should be required */}
        <Grid item xs={12}>
          <Typography variant="overline">Full Version *</Typography>
        </Grid>
        <Grid item xs={12}>
          {song.full_song_version ? (
            <React.Fragment>
              <AudioPlayer
                style={{ marginBottom: 10 }}
                autoPlayAfterSrcChange={false}
                showSkipControls={false}
                showJumpControls={false}
                customVolumeControls={[]}
                customAdditionalControls={[]}
                layout="horizontal-reverse"
                src={song.full_song_version.file.url}
                customIcons={{
                  play: (
                    <PlayCircleFilled
                      color="primary"
                      style={{ fontSize: "1em" }}
                    />
                  ),
                }}
              />
              <FileDownload file={song.full_song_version?.file} />
              <FileRemove
                callback={() => handleRemoveSong("full_song_version")}
              />
            </React.Fragment>
          ) : (
            <FileUpload
              objectFQCN={FQCN.SONG}
              objectId={song.id}
              callback={handleFullVersionUploadSuccess}
              uri={buildSongVersionUri(Songs.SONG_VERSION_FULL)}
              accept=".wav"
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">
            Requirements: .wav file, 16 Bit minimum, 44.1 kHz sample rate
          </Typography>
        </Grid>

        {!song.is_instrumental ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="overline">Instrumental Version</Typography>
            </Grid>

            <Grid item xs={12}>
              {song.instrumental_song_version ? (
                <React.Fragment>
                  <AudioPlayer
                    style={{ marginBottom: 10 }}
                    autoPlayAfterSrcChange={false}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    layout="horizontal-reverse"
                    src={song.instrumental_song_version.file.url}
                    customIcons={{
                      play: (
                        <PlayCircleFilled
                          color="primary"
                          style={{ fontSize: "1em" }}
                        />
                      ),
                    }}
                  />
                  <FileDownload file={song.instrumental_song_version?.file} />
                  <FileRemove
                    callback={() =>
                      handleRemoveSong("instrumental_song_version")
                    }
                  />
                </React.Fragment>
              ) : (
                <FileUpload
                  objectFQCN={FQCN.SONG}
                  objectId={song.id}
                  callback={handleInstrumentalVersionUploadSuccess}
                  uri={buildSongVersionUri(Songs.SONG_VERSION_INSTRUMENTAL)}
                  accept=".wav"
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="caption">
                Requirements: .wav file, 16 Bit minimum, 44.1 kHz sample rate
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch color="primary" />}
                label="Song has extra instrumental version?"
                checked={songHasExtraInstrumentalVersion}
                onClick={handleSongHasExtraInstrumentalVersionClick}
              />
            </Grid>

            {songHasExtraInstrumentalVersion ? (
              <React.Fragment>
                <Grid item xs={12}>
                  {song.extra_instrumental_song_version ? (
                    <React.Fragment>
                      <AudioPlayer
                        style={{ marginBottom: 10 }}
                        autoPlayAfterSrcChange={false}
                        showSkipControls={false}
                        showJumpControls={false}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        layout="horizontal-reverse"
                        src={song.extra_instrumental_song_version?.file.url}
                        customIcons={{
                          play: (
                            <PlayCircleFilled
                              color="primary"
                              style={{ fontSize: "1em" }}
                            />
                          ),
                        }}
                      />
                      <FileDownload
                        file={song.extra_instrumental_song_version?.file}
                      />
                      <FileRemove
                        callback={() =>
                          handleRemoveSong("extra_instrumental_song_version")
                        }
                      />
                    </React.Fragment>
                  ) : (
                    <FileUpload
                      objectFQCN={FQCN.SONG}
                      objectId={song.id}
                      callback={handleExtraInstrumentalVersionUploadSuccess}
                      uri={buildSongVersionUri(
                        Songs.SONG_VERSION_EXTRA_INSTRUMENTAL
                      )}
                      accept=".wav"
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption">
                    Requirements: .wav file, 16 Bit minimum, 44.1 kHz sample
                    rate
                  </Typography>
                </Grid>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}

        {!song.is_instrumental ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="overline">Clean Version</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch color="primary" />}
                label="Song has clean version?"
                checked={songHasCleanVersion}
                onClick={handleSongHasCleanVersionClick}
              />
            </Grid>

            {songHasCleanVersion ? (
              <React.Fragment>
                <Grid item xs={12}>
                  {song.clean_song_version ? (
                    <React.Fragment>
                      <AudioPlayer
                        style={{ marginBottom: 10 }}
                        autoPlayAfterSrcChange={false}
                        showSkipControls={false}
                        showJumpControls={false}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        layout="horizontal-reverse"
                        src={song.clean_song_version.file.url}
                        customIcons={{
                          play: (
                            <PlayCircleFilled
                              color="primary"
                              style={{ fontSize: "1em" }}
                            />
                          ),
                        }}
                      />
                      <FileDownload file={song.clean_song_version?.file} />
                      <FileRemove
                        callback={() => handleRemoveSong("clean_song_version")}
                      />
                    </React.Fragment>
                  ) : (
                    <FileUpload
                      objectFQCN={FQCN.SONG}
                      objectId={song.id}
                      callback={handleCleanVersionUploadSuccess}
                      uri={buildSongVersionUri(Songs.SONG_VERSION_CLEAN)}
                      accept=".wav"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    Requirements: .wav file, 16 Bit minimum, 44.1 kHz sample
                    rate
                  </Typography>
                </Grid>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
      </Grid>
    </form>
  );
}
