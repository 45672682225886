import {
  AppBar,
  Dialog,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import SubscriptionPaymentsTable from "./SubscriptionPaymentsTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentHistoryModal = ({
  modalOpened,
  handleClose,
  activeSubscriptions,
  inactiveSubscriptions,
}) => {
  const classes = useStyles();
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closePaymentHistoryModal = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closePaymentHistoryModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Payment History
            </Typography>
            <IconButton
              color="inherit"
              onClick={closePaymentHistoryModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={3} marginX={3}>
          <p>Current Subscription Payment</p>
          {activeSubscriptions.length > 0 ? (
            <SubscriptionPaymentsTable
              subscriptions={activeSubscriptions}
              showActive={true}
            />
          ) : (
            "-"
          )}
          <Divider />
          <p>Your Past Subscription Payments</p>
          {[...activeSubscriptions, ...inactiveSubscriptions].length > 0 ? (
            <SubscriptionPaymentsTable
              subscriptions={[...activeSubscriptions, ...inactiveSubscriptions]}
              showActive={false}
            />
          ) : (
            "-"
          )}
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentHistoryModal;
