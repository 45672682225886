import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { GlobalLoaderContext } from "../../../context/global-loader";
import {
  Badge,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { SnackbarContext } from "../../../context/snackbar";
import { Product } from "../../../models/products/product";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { Info } from "@mui/icons-material";
import Colors from "../../../enums/colors";
import FQCN from "../../../enums/FQCN";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import ProfileTypes from "../../../enums/profile-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      margin: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const formatDate = (dateObj) => {
  let date = dateObj.$d;

  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
  let day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);

  // Construct the ISO 8601 formatted string
  let isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  return isoDateString;
};

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManualInvoiceModal({
  modalOpened,
  handleClose,
  subscription,
  paymentGateway,
  reactivate = false,
}) {
  const classes = useStyles();

  const [identifier, setIdentifier] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  useEffect(() => {
    if (!modalOpened) {
      return;
    }

    return () => {
      handleClose();
    };
  }, [modalOpened]); // eslint-disable-line

  const closeManualInvoiceModal = () => {
    handleClose();
  };

  const handleSave = () => {
    startGlobalLoader();
    const urlToUse = reactivate
      ? ApiUri.PACKAGE +
        "/" +
        subscription?.package?.id +
        ApiUri.TRANSACTION +
        "/" +
        subscription?.package_transaction?.id +
        ApiUri.REACTIVE
      : ApiUri.PACKAGE + "/" + subscription?.id + ApiUri.MANUAL_PROCESS;
    API.post(urlToUse, {
      identifier: identifier,
      date_begin: formatDate(startDate),
      date_end: formatDate(endDate),
      payment_gateway: paymentGateway,
    }).then(
      (response) => {
        stopGlobalLoader();
        window.location = window.location;
        handleClose();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        handleClose();
      }
    );
  };

  const handleChangeIdentifier = (event) => {
    const { value } = event.target;
    setIdentifier(value);
  };

  const handleChangeStartDate = (event) => {
    if (event) {
      const newDate = event;
      setStartDate(newDate);
    }
  };

  const handleChangeEndDate = (event) => {
    if (event) {
      const newDate = event;
      setEndDate(newDate);
    }
  };

  const isFormValid =
    identifier &&
    startDate &&
    endDate &&
    dayjs(startDate).isBefore(dayjs(endDate));

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        open={modalOpened}
        onClose={closeManualInvoiceModal}
        TransitionComponent={Transition}
        // fullWidth
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Manual Invoice
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeManualInvoiceModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>
        <Stack spacing={2} margin={4} alignItems={"center"}>
          <TextField
            fullWidth
            label="Invoice Number"
            value={identifier || ""}
            name="identifier"
            onChange={handleChangeIdentifier}
          />

          <Stack spacing={2} direction={"row"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction={"row"} spacing={2}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleChangeStartDate}
                  format="DD/MM/YYYY"
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleChangeEndDate}
                  format="DD/MM/YYYY"
                  minDate={startDate}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>

          <ActionButtonSmall onClick={handleSave} disabled={!isFormValid}>
            Save
          </ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
