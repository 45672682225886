export default class Compositions {
  static STATUS_DRAFT = "draft";
  static STATUS_SUBMITTED = "submitted";
  static STATUS_PROCESSING = "processing";
  static STATUS_PUBLISHED = "published";
  static STATUS_TAKEN_DOWN = "taken_down";

  static ALL_STATUSES = [
    Compositions.STATUS_DRAFT,
    Compositions.STATUS_SUBMITTED,
    Compositions.STATUS_PROCESSING,
    Compositions.STATUS_PUBLISHED,
    Compositions.STATUS_TAKEN_DOWN,
  ];

  static getStatusDisplayName = (status: string) => {
    if (status === Compositions.STATUS_DRAFT) {
      return "Draft";
    }

    if (status === Compositions.STATUS_SUBMITTED) {
      return "Submitted";
    }

    if (status === Compositions.STATUS_PROCESSING) {
      return "Processing";
    }

    if (status === Compositions.STATUS_PUBLISHED) {
      return "Published";
    }

    if (status === Compositions.STATUS_TAKEN_DOWN) {
      return "Taken Down";
    }
  };
}
