import API from "../../api";
import ApiUri from "../../api-uri";

export const getAllProducts = async () => {
  try {
    const response = API.get(ApiUri.PACKAGE);
    return response;
  } catch (err) {
    throw err;
  }
};
