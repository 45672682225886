import React, { useState } from "react";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import InvitationEmailDialog from "./invitation-email-dialog";

export default function InviteUserToProfile() {
  const [dialogOpened, setDialogOpened] = useState(false);

  const openDialog = () => {
    setDialogOpened(true);
  };

  const closeDialog = () => {
    setDialogOpened(false);
  };

  return (
    <React.Fragment>
      <ActionButtonSmall type="submit" onClick={openDialog}>
        Invite User
      </ActionButtonSmall>
      <InvitationEmailDialog isOpened={dialogOpened} close={closeDialog} />
    </React.Fragment>
  );
}
