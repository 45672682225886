import {
  AppBar,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { TwoFactorAuthenticationModal } from "../../../components/TwoFactorAuthenticationModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditPaymentGatewayModal = ({
  modalOpened,
  handleClose,
  setPaymentGateways,
  paymentGateway,
  paymentTypes,
}) => {
  const classes = useStyles();
  const [editedPaymentGateway, setEditedPaymentGateway] =
    useState<any>(paymentGateway);
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closeEditPaymentGatewayModal = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "name":
        setEditedPaymentGateway({ ...editedPaymentGateway, name: value });
        break;
      case "clientID":
        setEditedPaymentGateway({
          ...editedPaymentGateway,
          provider_params: {
            ...editedPaymentGateway.provider_params,
            client_id: value,
          },
        });
        break;
      case "secret":
        setEditedPaymentGateway({
          ...editedPaymentGateway,
          provider_params: {
            ...editedPaymentGateway.provider_params,
            secret: value,
          },
        });
        break;
      case "publishableKey":
        setEditedPaymentGateway({
          ...editedPaymentGateway,
          provider_params: {
            ...editedPaymentGateway.provider_params,
            publishable_key: value,
          },
        });
        break;
      case "secretKey":
        setEditedPaymentGateway({
          ...editedPaymentGateway,
          provider_params: {
            ...editedPaymentGateway.provider_params,
            secret_key: value,
          },
        });
        break;
      case "paymentType":
        const newPaymentType = paymentTypes.find(
          (paymentType) => paymentType.id === value
        );

        const newProviderParams = editedPaymentGateway.provider_params;
        newProviderParams.mode =
          newPaymentType.id === 1
            ? "sandbox"
            : newPaymentType.id === 2
            ? "test"
            : "";
        setEditedPaymentGateway({
          ...editedPaymentGateway,
          website:
            newPaymentType.id === 1
              ? "paypal.com"
              : newPaymentType.id === 2
              ? "stripe.com"
              : "",
          payment_type: newPaymentType,
          provider_params: newProviderParams,
        });
        break;
    }
  };

  const handleSave = () => {
    // Saving logic
    startGlobalLoader();
    API.put(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        "/payment-gateway/" +
        editedPaymentGateway.id,
      editedPaymentGateway
    ).then(
      (response) => {
        initSnackbarSuccess("Products Payment Provider edited");
        stopGlobalLoader();
        setPaymentGateways(null);
        closeEditPaymentGatewayModal();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        closeEditPaymentGatewayModal();
      }
    );
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeEditPaymentGatewayModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Products Payment Provider
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeEditPaymentGatewayModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={10} marginX={20}>
          <Stack spacing={2}>
            <TextField
              value={editedPaymentGateway.name}
              onChange={handleChange}
              name="name"
              label="Name"
            />
            <FormControl fullWidth>
              <InputLabel>Payment Type</InputLabel>
              <Select
                name="paymentType"
                value={editedPaymentGateway?.payment_type?.id}
                required={true}
                onChange={handleChange}
                label="Payment Type"
              >
                {paymentTypes.map((paymentType) => (
                  <MenuItem key={paymentType.id} value={paymentType.id}>
                    {paymentType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {editedPaymentGateway.payment_type.id === 1 && (
              <React.Fragment>
                <TextField
                  value={editedPaymentGateway.provider_params.client_id}
                  onChange={handleChange}
                  name="clientID"
                  label="Client ID"
                />
                <TextField
                  value={editedPaymentGateway.provider_params.secret}
                  onChange={handleChange}
                  name="secret"
                  label="Secret"
                />
              </React.Fragment>
            )}
            {editedPaymentGateway.payment_type.id === 2 && (
              <React.Fragment>
                <TextField
                  value={editedPaymentGateway.provider_params.publishable_key}
                  name="publishableKey"
                  label="Publishable Key"
                  onChange={handleChange}
                />
                <TextField
                  value={editedPaymentGateway.provider_params.secret_key}
                  name="secretKey"
                  label="Secret Key"
                  onChange={handleChange}
                />
              </React.Fragment>
            )}
          </Stack>
          <ActionButtonSmall onClick={handleSave}>Save</ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default EditPaymentGatewayModal;
