import React, { useContext } from "react";
import { Avatar } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { CustomisationContext } from "../../context/customisation";
import ProfileTypes from "../../enums/profile-types";

export default function SmallAvatar({
  profileType,
  hideBorder,
  size,
  ...props
}: any) {
  const { customisation } = useContext(CustomisationContext);
  let borderColor = customisation?.textColor;
  const border = hideBorder ? "" : "solid";

  switch (profileType) {
    case ProfileTypes.SONGWRITER:
      borderColor = "#22BEEF";
      break;
    case ProfileTypes.PUBLISHER:
      borderColor = "#FFC100";
      break;
    case ProfileTypes.PUBLISHER_ADMINISTRATOR:
      borderColor = "#FE4A42";
      break;
    case ProfileTypes.SERVICE:
      borderColor = "#3D4F61";
      break;
  }
  const useStyles = makeStyles((theme) => {
    return {
      avatar: {
        width: size ?? theme.spacing(3),
        height: size ?? theme.spacing(3),
        borderWidth: "2px",
        borderStyle: border,
        borderColor: `${borderColor}`,
      },
    };
  });

  const classes = useStyles();
  return <Avatar className={classes.avatar} {...props} />;
}
