export default class Platforms {
  static AMRA = { id: 19, name: "AMRA" };
  static CAPASSO = { id: 15, name: "CAPASSO" };
  static BLOKUR = { id: 17, name: "Blokur" };
  static ESMAA = { id: 18, name: "ESMAA" };
  static MLC = { id: 10, name: "MLC" };
  static MUSIXMATCH = { id: 13, name: "MusixMatch" };
  static PRETZEL = { id: 9, name: "Pretzel" };
  static PRS = { id: 1, name: "PRS" };
}

