import Pusher from "pusher-js";
import React, { createContext, useEffect, useState } from "react";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { Notification } from "../models/notification/notification";
import { AxiosResponse } from "axios";

export interface INotificationsContext {
  notifications: Array<Notification>;
  unreadNotificationsCount: number;
  getNotifications: Function;
  setNotifications: Function;
  setUnreadNotificationsCount: Function;
}

export const NotificationsContext = createContext({} as INotificationsContext);

const NotificationsContextProvider = ({ children }: any) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useEffect(() => {
    API.get(ApiUri.USER_ME).then((response: AxiosResponse) => {
      const user = response.data;
      handleNotificationsData(response.data.notifications);

      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
        cluster: "eu",
      });

      const channel = pusher.subscribe("notifications");
      const event = "user-" + user?.id;

      channel.bind(event, (data) => {
        getNotifications();
      });
    });
  }, []); // eslint-disable-line

  const getNotifications = () => {
    API.get(ApiUri.USER_ME + ApiUri.NOTIFICATIONS).then((response) => {
      handleNotificationsData(response.data.collection);
    });
  };

  const handleNotificationsData = (data) => {
    setNotifications(data);

    let unreadCount = 0;
    data.map((notification: Notification) => {
      return !notification.is_read ? unreadCount++ : null;
    });

    setUnreadNotificationsCount(unreadCount);
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        unreadNotificationsCount,
        getNotifications,
        setNotifications,
        setUnreadNotificationsCount,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;
