import { Box, TextField } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import JoditEditor from "jodit-react";

const TextEditor = ({ value, setValue, label, disabled = false }) => {
  const editor = useRef(null);
  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "|",
    "link",
  ];
  const editorConfig = {
    readonly: disabled,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true,
    },
  };
  return (
    <Box>
      <JoditEditor
        ref={editor}
        value={value}
        onBlur={(newValue) => setValue(newValue)}
        config={editorConfig}
      />
    </Box>
  );
};

export default TextEditor;
