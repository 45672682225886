import React, { useContext, useState } from "react";
import { Divider, Link, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SnackbarContext } from "../../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import ConfirmationDialogActivator from "../../../../../components/confirmation-dialog/confirmation-dialog-activator";
import { Edit, LinkOff, Person } from "@mui/icons-material";
import RoleTypes from "../../../../../enums/role-types";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import { Profile } from "../../../../../models/profile/profile";
import { ProfileRbac } from "../../../../../models/profile/profile-rbac";
import { AuthContext } from "../../../../../context/auth";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

interface IContextMenu {
  profile: Profile;
  profileRbac: ProfileRbac;
  reload: any;
}

export default function ContextMenu({
  profile,
  profileRbac,
  reload,
}: IContextMenu) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { user, userRole } = useContext(AuthContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    startGlobalLoader();
    window.location.replace(
      `https://mmaz.${testSite ? "test." : ""}myclientzone.com`! +
        "/users/" +
        profileRbac.user.id
    );
  };

  const handleUnlinkUser = () => {
    handleClose();
    startGlobalLoader();

    API.delete(
      ApiUri.PROFILE +
        "/" +
        profile.id +
        "/user/" +
        profileRbac.user.id +
        "/unlink"
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("User successfully unlinked");
        reload();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleChangeRole = (role) => {
    handleClose();
    startGlobalLoader();

    const data = {
      name: role,
    };

    API.post(
      ApiUri.PROFILE +
        "/" +
        profile.id +
        "/user/" +
        profileRbac.user.id +
        "/change-role",
      data
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Role successfully changed");
        reload();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <LinkOff fontSize="small" className={classes.menuItemIcon} />
          <ConfirmationDialogActivator
            onClick={handleUnlinkUser}
            type="link"
            actionName="Unlink"
          />
        </MenuItem>
        {user?.is_mmpz_service_admin ? (
          <MenuItem>
            <Edit fontSize="small" className={classes.menuItemIcon} />
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleEdit}
            >
              Edit/Disable
            </Link>
          </MenuItem>
        ) : null}
        <Divider
          style={{ marginTop: "10px", marginBottom: "10px" }}
          variant="middle"
        />
        {userRole === RoleTypes.OWNER && (
          <MenuItem>
            <Person fontSize="small" className={classes.menuItemIcon} />
            <ConfirmationDialogActivator
              onClick={() => handleChangeRole(RoleTypes.OWNER)}
              type="link"
              actionName="Set as Owner"
            />
          </MenuItem>
        )}
        <MenuItem>
          <Person fontSize="small" className={classes.menuItemIcon} />
          <ConfirmationDialogActivator
            onClick={() => handleChangeRole(RoleTypes.ADMIN)}
            type="link"
            actionName="Set as Admin"
          />
        </MenuItem>
        <MenuItem>
          <Person fontSize="small" className={classes.menuItemIcon} />
          <ConfirmationDialogActivator
            onClick={() => handleChangeRole(RoleTypes.EDITOR)}
            type="link"
            actionName="Set as Editor"
          />
        </MenuItem>
        <MenuItem>
          <Person fontSize="small" className={classes.menuItemIcon} />
          <ConfirmationDialogActivator
            onClick={() => handleChangeRole(RoleTypes.READ_ONLY)}
            type="link"
            actionName="Set as Read Only"
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
