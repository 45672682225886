import React, { useContext } from "react";
import List from "@mui/material/List";
import NavigationItem from "./navigation-item";
import { NavigationContext } from "../context/navigation";

export default function Navigation() {
  const { navigation } = useContext(NavigationContext);

  return (
    <React.Fragment>
      <List>
        {navigation.map((page: any, index: number) => (
          <NavigationItem key={index} page={page} />
        ))}
      </List>
    </React.Fragment>
  );
}
