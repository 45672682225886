import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import API from "../api/api";
import { GlobalLoaderContext } from "../context/global-loader";
import { Stack, TextField } from "@mui/material";
import { SnackbarContext } from "../context/snackbar";
import CustomToolbar from "./layout/titles/CustomToolbar";
import ActionButtonSmall from "./layout/buttons/action-button-small";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TwoFactorAuthenticationModal = ({
  modalOpened,
  handleClose,
  onSuccess,
  stopLoadingAfterSuccess = true,
}) => {
  const classes = useStyles();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const [code, setCode] = useState("");

  const closeTwoFactorAuthenticationModal = () => {
    handleClose();
  };

  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const handleVerify = () => {
    const formData = {
      code: code,
    };
    startGlobalLoader();
    API.post("confirm-2fa-code", formData).then(
      (response) => {
        if (response.data.valid_code) {
          onSuccess();
          if (stopLoadingAfterSuccess) {
            stopGlobalLoader();
          }
          closeTwoFactorAuthenticationModal();
          handleClose();
        } else {
          initSnackbarError(response.data.message);
          stopGlobalLoader();
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        open={modalOpened}
        onClose={closeTwoFactorAuthenticationModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Two Factor Authentication Code Required
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeTwoFactorAuthenticationModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={2}>
          <TextField
            value={code}
            onChange={handleChangeCode}
            label="Authentication Code"
          />
          <ActionButtonSmall onClick={handleVerify}>Verify</ActionButtonSmall>
          <p>
            Open the two-factor authentication app on your device to view your
            authentication code and verify your identity.
          </p>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};
