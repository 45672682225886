import { FormControl, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import { Profile } from "../../../../../models/profile/profile";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";

interface INotes {
  profile: Profile;
}

export default function Notes({ profile }: INotes) {
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const [notes, setNotes] = useState(profile.profile_information.notes);
  const [formData, setFormData] = useState<Profile>(profile);

  const handleChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
    formData.profile_information.notes = notes;
    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    startGlobalLoader();

    API.put(ApiUri.PROFILE + "/" + profile.id, formData).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Profile notes successfully updated");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      },
    );
  };

  return (
    <form autoComplete="off" style={{ width: "100%" }} onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormControl required fullWidth>
            <TextField
              label="Profile notes"
              multiline
              focused
              rows={20}
              variant="outlined"
              value={notes ?? ""}
              onChange={handleChangeNotes}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <ActionButtonSmall type="submit">Save</ActionButtonSmall>
        </Grid>
      </Grid>
    </form>
  );
}
