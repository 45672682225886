import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Song } from "../../../../models/song/song";
import FileDownload from "../../../../components/utilities/file-download";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  }),
);

interface IArtworkCard {
  song: Song;
}

export default function ArtworkCard({ song }: IArtworkCard) {
  const classes = useStyles();

  return song ? (
    <Grid item xs={12} sm={4} md={2} className={classes.gridItem}>
      <Paper elevation={3}>
        <Box p={2}>
          <Box marginBottom={3} marginTop={2}>
            <Typography variant="h6" color="primary">
              Artwork
            </Typography>
            <Divider />
          </Box>
          <Grid container>
            <Grid item xs={12}>
              {song.artwork ? (
                <img src={song.artwork?.file.url} alt="" width="100%" />
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <FileDownload file={song.artwork?.file} />
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
