import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext, IAuthContext } from "../../../context/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditSectionModal = ({
  modalOpened,
  handleClose,
  setSections,
  sectionBeingEdited,
}) => {
  const { user } = useContext<IAuthContext>(AuthContext);
  const classes = useStyles();
  const [sectionTitle, setSectionTitle] = useState(sectionBeingEdited.title);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closeNewSectionModal = () => {
    setSectionTitle("");
    handleClose();
  };

  const handleSave = () => {
    if (sectionTitle.length === 0) {
      initSnackbarError("The section name can't be empty");
    } else {
      startGlobalLoader();
      const formData = sectionBeingEdited;
      formData.title = sectionTitle;
      API.put(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          "/helper" +
          "/" +
          sectionBeingEdited.id,
        formData
      ).then(
        (response) => {
          initSnackbarSuccess("Section edited");
          stopGlobalLoader();
          setSections(null);
          setSectionTitle("");
          handleClose();
        },
        (error) => {
          initSnackbarError(error);
          stopGlobalLoader();
          setSectionTitle("");
          handleClose();
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        open={modalOpened}
        onClose={closeNewSectionModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Section
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeNewSectionModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack padding={3} spacing={2}>
          <TextField
            value={sectionTitle}
            onChange={(event) => setSectionTitle(event.target.value)}
            label="Title"
          />
          <ActionButtonSmall onClick={handleSave}>Save</ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default EditSectionModal;
