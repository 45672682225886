import React, { useContext, useEffect, useState } from "react";
import SubProfilesTable from "./sub-profiles-table";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import RoleTypes from "../../../enums/role-types";
import ProfileTypes from "../../../enums/profile-types";
import { useHistory } from "react-router-dom";

export default function ManageSubProfiles() {
  const [showManageSubProfilesPage, setShowManageSubProfilesPage] =
    useState(false);
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name !== ProfileTypes.SONGWRITER;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowManageSubProfilesPage(true);
    }
  }, []);

  return (
    showManageSubProfilesPage && (
      <React.Fragment>
        <SubProfilesTable />
      </React.Fragment>
    )
  );
}
