import { Badge, FormControl, Grid, TextField, Tooltip } from "@mui/material";
import React from "react";
import { Song } from "../../../../../models/song/song";
import { Info } from "@mui/icons-material";

interface IFormStepFive {
  song: Song;
  setSong: Function;
}

export default function FormStepFive({ song, setSong }: IFormStepFive) {
  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setSong((previousState) => ({ ...previousState, [name]: value }));
  };

  return (
    <Grid container spacing={4} alignContent="center" alignItems="center">
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Tooltip
            title="URL (link) to the song, which could be YouTube link, SoundCloud link, Spotify link etc."
            placement="top-end"
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={<Info color="disabled" />}
            />
          </Tooltip>
          <TextField
            label="Song URL"
            name="url"
            value={song.url || ""}
            onChange={handleFieldChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Tooltip title="YouTube Asset ID" placement="top-end">
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={<Info color="disabled" />}
            />
          </Tooltip>
          <TextField
            label="YouTube Asset ID"
            name="youtube_asset_id"
            value={song.youtube_asset_id || ""}
            onChange={handleFieldChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Tooltip title="Spotify ID" placement="top-end">
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={<Info color="disabled" />}
            />
          </Tooltip>
          <TextField
            label="Spotify ID"
            name="spotify_id"
            value={song.spotify_id || ""}
            onChange={handleFieldChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
