import React, { useState, useEffect, useContext } from "react";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";

const SuccessPage = () => {
  const [transactionComplete, setTransactionComplete] = useState(false);

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  window.addEventListener("beforeunload", function (event) {
    localStorage.setItem("tabClosed", "true");
  });

  useEffect(() => {
    startGlobalLoader("Dots", "Processing purchase, don't close this page...");

    // getting the values needed
    const productId = localStorage.getItem("productId");
    const rawPaymentgateway = localStorage.getItem("paymentGateway");
    const paymentGateway = JSON.parse(rawPaymentgateway as string);
    let token = localStorage.getItem("token");
    if (!token) {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      const productType = localStorage.getItem("productType");
      if (productType === "Primary") {
        token = url.searchParams.get("token");
      } else {
        token = url.searchParams.get("subscription_id");
      }
    }
    localStorage.clear();

    API.get("package-transaction", { params: { externalId: token } }).then(
      (response) => {
        const transaction = response.data;

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        const formData = {
          status: "approved",
          payment_gateway: paymentGateway,
          payment_gateway_external_id: token,
          created_at: formattedDate,
        };
        API.put(
          ApiUri.PACKAGE +
            "/" +
            productId +
            "/transaction" +
            "/" +
            transaction.id,
          formData
        ).then(
          (response) => {
            const isComplete =
              response.data.payment_gateway_process.status === "complete" ||
              response.data.payment_gateway_process.status === "active" ||
              response.data.payment_gateway_process.status === "ACTIVE";
            if (isComplete) {
              setTransactionComplete(true);
            }

            stopGlobalLoader();
          },
          (error) => {
            stopGlobalLoader();
            initSnackbarError(error);
          }
        );
      },
      (err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  }, []);

  return (
    <React.Fragment>
      {transactionComplete && (
        <React.Fragment>
          <h1>Thank you!</h1>
          <h2>A confirmation has been sent to your email.</h2>
          <h2>You can now close this page</h2>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SuccessPage;
