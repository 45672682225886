import { useContext, useEffect, useState } from "react";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { AuthContext } from "../../context/auth";
import { SnackbarContext } from "../../context/snackbar";

export default function useProfiles() {
  const [profiles, setProfiles] = useState([]);
  const { user } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    if (!user?.is_mmpz_service_admin) {
      return;
    }

    API.get(ApiUri.PROFILE).then(
      (response) => {
        setProfiles(response.data.collection);
      },
      (err) => {
        initSnackbarError(err);
      },
    );
  }, []); // eslint-disable-line

  return profiles;
}
