import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import ProfileTypes from "../../../enums/profile-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import TextEditor from "./TextEditor";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import {
  buildNewContract,
  contentTypes,
  contentTypesLabels,
  productTypes,
  profileTypes,
} from "./types";
import RoleTypes from "../../../enums/role-types";
import { useHistory } from "react-router-dom";

const Contracts = () => {
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [contracts, setContracts] = useState<any[]>([]);
  const [selectedContractIndex, setSelectedContractIndex] = useState<number>(0);
  const [selectedContractContentIndex, setSelectedContractContentIndex] =
    useState<number>(0);
  const [showContractsPage, setShowContractsPage] = useState(false);
  const history = useHistory();

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR;

  const selectedContract = contracts[selectedContractIndex];

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowContractsPage(true);
      startGlobalLoader();
      API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
        (response) => {
          const profile = response.data;
          const userRole = profile?.profile_rbac?.find(
            (profile) => profile.user.id === user?.id
          )?.role.name;
          setCurrentUserRole(userRole);
        },
        (error) => {
          initSnackbarError(error);
        }
      );
      if (
        user?.last_accessed_profile.profile_type.name ===
        ProfileTypes.PUBLISHER_ADMINISTRATOR
      ) {
        API.get(
          ApiUri.PROFILE + "/" + user.last_accessed_profile.id + ApiUri.CONTRACT
        ).then(
          (response) => {
            const contracts = response.data.collection;
            setContracts(contracts);
            stopGlobalLoader();
          },
          (err) => {
            stopGlobalLoader();
            initSnackbarError(err);
          }
        );
      }
    }
  }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    let updatedSelectedContractIndex = -1;
    switch (name) {
      case "product":
        updatedSelectedContractIndex = contracts.findIndex(
          (contract) =>
            contract.product === value &&
            contract.profile_type.id === selectedContract.profile_type.id
        );
        if (updatedSelectedContractIndex !== -1) {
          setSelectedContractIndex(updatedSelectedContractIndex);
        } else {
          const newContract = buildNewContract(
            value,
            selectedContract?.profile_type
          );
          const updatedContracts = [...contracts, newContract];
          const newIndex = updatedContracts.length - 1;
          setContracts(updatedContracts);
          setSelectedContractIndex(newIndex);
        }
        break;
      case "profile":
        updatedSelectedContractIndex = contracts.findIndex(
          (contract) =>
            contract.product === selectedContract.product &&
            contract.profile_type.id === value
        );
        if (updatedSelectedContractIndex !== -1) {
          setSelectedContractIndex(updatedSelectedContractIndex);
        } else {
          const selectedProfileType = profileTypes.find(
            (profileType) => profileType.id === value
          );
          const newContract = buildNewContract(
            selectedContract?.product,
            selectedProfileType
          );
          const updatedContracts = [...contracts, newContract];
          const newIndex = updatedContracts.length - 1;
          setContracts(updatedContracts);
          setSelectedContractIndex(newIndex);
        }
        break;
      case "checkbox":
        const { checked } = event.target;

        const updatedContracts = contracts.map((contract) => {
          if (
            contract.product === selectedContract.product &&
            contract.profile_type.id === selectedContract.profile_type.id
          ) {
            return { ...contract, display_publishing_agreement: checked };
          }
          return contract;
        });

        setContracts(updatedContracts);

        break;
    }
  };

  const handleChangeTab = (
    event: any,
    newSelectedContractContentIndex: number
  ) => {
    setSelectedContractContentIndex(newSelectedContractContentIndex);
  };

  const handleChangeContent = (value) => {
    const updatedContracts = contracts.map((contract) => {
      if (
        contract.product === selectedContract.product &&
        contract.profile_type.id === selectedContract.profile_type.id
      ) {
        return {
          ...contract,
          [contentTypes[selectedContractContentIndex]]: value,
        };
      }
      return contract;
    });

    setContracts(updatedContracts);
  };

  const handleSave = () => {
    startGlobalLoader();
    if (selectedContract.id) {
      API.put(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          "/" +
          "contract" +
          "/" +
          selectedContract.id,
        selectedContract
      ).then(
        (response) => {
          const updatedContract = response.data;
          const updatedContracts = contracts.map((contract) => {
            if (
              contract.product === updatedContract.product &&
              contract.profile_type.id === updatedContract.profile_type.id
            ) {
              return { ...contract, ...updatedContract };
            }
            return contract;
          });
          setContracts(updatedContracts);
          stopGlobalLoader();
          initSnackbarSuccess("Contract saved");
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
    } else {
      API.post(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile.id +
          "/" +
          "contract",
        selectedContract
      ).then(
        (response) => {
          const updatedContract = response.data;
          const updatedContracts = contracts.map((contract) => {
            if (
              contract.product === updatedContract.product &&
              contract.profile_type.id === updatedContract.profile_type.id
            ) {
              return { ...contract, ...updatedContract };
            }
            return contract;
          });
          setContracts(updatedContracts);
          stopGlobalLoader();
          initSnackbarSuccess("Contract saved");
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
    }
  };

  return (
    showContractsPage && (
      <Stack spacing={2} paddingBottom={3}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Business Model</InputLabel>
              <Select
                name="product"
                value={selectedContract?.product ?? ""}
                required={true}
                onChange={handleChange}
                label="Business Model"
              >
                {productTypes.map((productType) => (
                  <MenuItem value={productType} key={productType}>
                    {productType === "Primary" ? "Package" : "Subscription"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Profile</InputLabel>
              <Select
                name="profile"
                value={selectedContract?.profile_type.id ?? ""}
                required={true}
                onChange={handleChange}
                label="Profile"
              >
                {profileTypes.map((profileType) => (
                  <MenuItem key={profileType.id} value={profileType.id}>
                    {profileType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {selectedContract?.profile_type.id !== 3 && (
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedContract?.display_publishing_agreement ?? false
                    }
                    onChange={handleChange}
                    name="checkbox"
                    color="primary"
                    disabled={
                      currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      !user?.is_mmpz_service_admin
                    }
                  />
                }
                label="Display Publishing Agreement for signing when submitting a Song"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Tabs
              value={selectedContractContentIndex}
              onChange={handleChangeTab}
            >
              {contentTypes.map((contentType, index) => (
                <Tab label={contentTypesLabels[index]} key={index} />
              ))}
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <TextEditor
              value={
                selectedContract
                  ? selectedContract[contentTypes[selectedContractContentIndex]]
                  : ""
              }
              setValue={handleChangeContent}
              label={"Content"}
              disabled={
                currentUserRole !== RoleTypes.OWNER &&
                currentUserRole !== RoleTypes.ADMIN &&
                !user?.is_mmpz_service_admin
              }
            />
          </Grid>

          <Grid item xs={12}>
            <ActionButtonSmall
              onClick={handleSave}
              disabled={
                currentUserRole !== RoleTypes.OWNER &&
                currentUserRole !== RoleTypes.ADMIN &&
                !user?.is_mmpz_service_admin
              }
            >
              Save
            </ActionButtonSmall>
          </Grid>
        </Grid>
      </Stack>
    )
  );
};

export default Contracts;
