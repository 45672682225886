import { FormControl, Grid, TextField } from "@mui/material";
import React from "react";

const ExistingSongwriterInfo = ({ newSongwriter }) => {
  const names = newSongwriter.profile
    ? newSongwriter.profile.profile_information.name.split(" ")
    : [];

  const firstName = names.shift();

  const lastName = names.join(" ");

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            value={firstName || ""}
            required
            disabled
            type="text"
            label="First Name"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            value={lastName || ""}
            required
            disabled
            type="text"
            label="Last Name"
          />
        </FormControl>
      </Grid>
    </React.Fragment>
  );
};

export default ExistingSongwriterInfo;
