import { Box } from "@mui/material";
import React from "react";
import ConfirmationDialogActivator from "../../../../../components/confirmation-dialog/confirmation-dialog-activator";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { InitialSongwriter, initialSongwriter } from "./NewSongwriterForm";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import { Composition } from "../../../../../models/composition/composition";

interface ExistingSongwriterConfirmationDialogActivatorProps {
  setUsingExistingSongwriter: React.Dispatch<React.SetStateAction<boolean>>;
  existingSongwriter: any;
  setNewSongwriter: React.Dispatch<React.SetStateAction<InitialSongwriter>>;
  setExistingSongwriter: React.Dispatch<any>;
}

const ExistingSongwriterConfirmationDialogActivator: React.FC<
  ExistingSongwriterConfirmationDialogActivatorProps
> = ({
  setUsingExistingSongwriter,
  existingSongwriter,
  setNewSongwriter,
  setExistingSongwriter,
}) => {
  const applyExistingSongwriter = () => {
    setUsingExistingSongwriter(true);

    setNewSongwriter(
      (previousState) =>
        ({
          id: null,
          share: previousState.share,
          songwriter_cae_number: existingSongwriter.cae_number,
          publisher_name:
            existingSongwriter.publisherAdministrator.profile_information.name,
          publisher_cae_number:
            existingSongwriter.publisherAdministrator.cae_number,
          has_cae_number: true,
          music_contribution: previousState.music_contribution,
          lyrics_contribution: previousState.lyrics_contribution,
          pro_affiliation: previousState.pro_affiliation,
          profile: {
            id: existingSongwriter.id,
            status: existingSongwriter.status,
            profile_information: {
              id: existingSongwriter.id,
              name: existingSongwriter.profile_information.name,
            },
            cae_number: existingSongwriter.cae_number,
          },
        } as any)
    );
  };

  const handleCancel = () => {
    setExistingSongwriter(null);
    setUsingExistingSongwriter(false);
    setNewSongwriter(initialSongwriter);
  };

  return (
    <Box sx={{ visibility: "hidden" }}>
      <ConfirmationDialogActivator
        actionName="Use"
        content={`This CAE Number belongs to ${
          existingSongwriter?.profile_information.name.split(" ")[0]
        } ${existingSongwriter?.profile_information.name
          .split(" ")
          .slice(1)
          .join(" ")}, would you like to use this songwriter's data?`}
        onClick={applyExistingSongwriter}
        type="button"
        id="confirmationSwitch"
        onCancel={handleCancel}
      />
    </Box>
  );
};

export default ExistingSongwriterConfirmationDialogActivator;
