import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import ProfileTypes from "../../enums/profile-types";

export default function usePublisherRbac() {
  const { user } = useContext(AuthContext);
  const [canCreatePublisher, setCanCreatePublisher] = useState(false);

  useEffect(() => {
    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE
    ) {
      setCanCreatePublisher(false);
    }

    if (
      user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR
    ) {
      setCanCreatePublisher(true);
    }

    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.PUBLISHER
    ) {
      setCanCreatePublisher(false);
    }

    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SONGWRITER
    ) {
      setCanCreatePublisher(false);
    }

    return () => {
      setCanCreatePublisher(false);
    };
  }, []); // eslint-disable-line
  return canCreatePublisher;
}
