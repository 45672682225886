import React, { useEffect } from "react";

const ErrorPage = () => {
  useEffect(() => {
    window.close();
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default ErrorPage;
