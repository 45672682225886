import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
} from "react";

interface TwoFactorAuthenticationTimerContextProps {
  time: number;
  isRunning: boolean;
  startTimer: () => void;
  resetTimer: () => void;
}

const TwoFactorAuthenticationTimerContext = createContext<
  TwoFactorAuthenticationTimerContextProps | undefined
>(undefined);

export const TwoFactorAuthenticationTimerProvider = ({ children }: any) => {
  const [time, setTime] = useState(600); // 10 minutes in seconds
  const [isRunning, setIsRunning] = useState(false);

  const startTimer = () => {
    setIsRunning(true);
  };

  const resetTimer = () => {
    setTime(600);
    setIsRunning(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => Math.max(prevTime - 1, 0));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const value: TwoFactorAuthenticationTimerContextProps = {
    time,
    isRunning,
    startTimer,
    resetTimer,
  };

  return (
    <TwoFactorAuthenticationTimerContext.Provider value={value}>
      {children}
    </TwoFactorAuthenticationTimerContext.Provider>
  );
};

export const useTwoFactorAuthenticationTimer =
  (): TwoFactorAuthenticationTimerContextProps => {
    const context = useContext(TwoFactorAuthenticationTimerContext);
    if (!context) {
      throw new Error("useTimer must be used within a TimerProvider");
    }
    return context;
  };
