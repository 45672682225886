import { Autocomplete, Box, TextField, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { IAuthContext, AuthContext } from "../../../context/auth";
import { Composition } from "../../../models/composition/composition";
import Compositions from "../../../enums/compositions";

const CompositionsAutocomplete = ({
  autocompleteOnChange,
  autoCompleteValue,
  setAutoCompleteValue,
  autoCompleteTextFieldInputValue,
  setAutoCompleteTextFieldInputValue,
}) => {
  const { user } = useContext<IAuthContext>(AuthContext);
  const [compositions, setCompositions] = useState<Composition[]>([]);
  //   const [value, setValue] = useState<Composition[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      autoCompleteTextFieldInputValue &&
      autoCompleteTextFieldInputValue.length > 0
    ) {
      setLoading(true);
      API.get(
        ApiUri.PROFILE + "/" + user?.last_accessed_profile.id + ApiUri.SONG,
        {
          params: {
            limit: 10,
            page: 1,
            status: Compositions.STATUS_PUBLISHED,
            search_term: autoCompleteTextFieldInputValue.trim(),
          },
        }
      ).then(
        (response) => {
          setCompositions(response.data.collection);
          setLoading(false);
        },
        (error) => setLoading(false)
      );
    }
  }, [autoCompleteTextFieldInputValue]); // eslint-disable-line

  const onTextFieldInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAutoCompleteTextFieldInputValue(event.target.value);
    autocompleteOnChange(event.target.value);
    setCompositions([]);
  };

  return (
    <Autocomplete
      value={autoCompleteValue}
      inputValue={autoCompleteTextFieldInputValue}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setAutoCompleteTextFieldInputValue(newValue);
          setAutoCompleteValue({
            id: 0,
            composition: {
              title: newValue,
            },
          });
        } else if (newValue && newValue?.composition?.title) {
          setAutoCompleteTextFieldInputValue(newValue.composition.title);
          setAutoCompleteValue({
            id: newValue.id,
            composition: { title: newValue.composition.title },
          });
          autocompleteOnChange(newValue);
        }
      }}
      // filterOptions={(options, params) => {
      //   const filtered = filter(options, params);
      //   return filtered;
      // }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      clearIcon={null}
      options={compositions}
      getOptionLabel={(option) => {
        return option?.composition?.title ?? option;
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.composition.title}
        </Box>
      )}
      // freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add Song"
          onChange={onTextFieldInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default CompositionsAutocomplete;
