import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import NewPaymentGatewayModal from "./NewPaymentGatewayModal";
import API from "../../../api/api";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";

const NewPaymentGateway = ({
  setPaymentGateways,
  paymentGateways: existingPaymentGateways,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const [allPaymentGatewaysExist, setAllPaymentGatewaysExist] = useState(false);

  const handleClick = () => {
    startGlobalLoader();
    API.get("/payment-type").then(
      (response) => {
        setPaymentTypes(response.data.collection);
        setShowModal(true);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  useEffect(() => {
    if (existingPaymentGateways) {
      const activePaymentGateways = existingPaymentGateways.filter(
        (paymentGateway) => paymentGateway.status
      );
      const hasPaymentType1 = activePaymentGateways.some(
        (paymentGateway) => paymentGateway.payment_type.id === 1
      );

      const hasPaymentType2 = activePaymentGateways.some(
        (paymentGateway) => paymentGateway.payment_type.id === 2
      );

      if (hasPaymentType1 && hasPaymentType2) {
        setAllPaymentGatewaysExist(true);
      }
    }
  }, [existingPaymentGateways]);

  return (
    <React.Fragment>
      <ActionButtonSmall
        onClick={handleClick}
        disabled={allPaymentGatewaysExist}
      >
        New Products Payment Provider
      </ActionButtonSmall>

      {showModal && (
        <NewPaymentGatewayModal
          modalOpened={showModal}
          handleClose={() => setShowModal(false)}
          paymentTypes={paymentTypes}
          setPaymentGateways={setPaymentGateways}
          existingPaymentGateways={existingPaymentGateways}
        />
      )}
    </React.Fragment>
  );
};

export default NewPaymentGateway;
