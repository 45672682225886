import {
  Box,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import H2 from "../../../../components/layout/titles/h2";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import { Profile } from "../../../../models/profile/profile";
import ProfileTypes from "../../../../enums/profile-types";
import RoleTypes from "../../../../enums/role-types";
import { AuthContext, IAuthContext } from "../../../../context/auth";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

interface IProfileDetailsCard {
  profile: Profile;
  currentUserRole: any;
}

export default function ProfileDetailsCard({
  profile,
  currentUserRole,
}: IProfileDetailsCard) {
  const { user } = useContext<IAuthContext>(AuthContext);
  const classes = useStyles();
  const history = useHistory();

  const handleEdit = () => {
    history.push("/sub-profiles/edit/" + profile.id);
  };

  return profile ? (
    <Grid item xs={12} md={6} className={classes.gridItem}>
      <Paper elevation={3}>
        <Box p={2}>
          <H2>Details</H2>
          <Box marginBottom={3} marginTop={2}>
            <Typography variant="h6" color="primary">
              Profile Info
            </Typography>
            <Divider />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Profile Name"
                value={profile.profile_information?.name}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Profile Type"
                value={profile.profile_type.name}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Email"
                value={profile.profile_information?.email}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Address"
                value={profile.profile_information?.address || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="City"
                value={profile.profile_information?.city || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Country"
                value={profile.profile_information?.country.country}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item>
              <ActionButtonSmall
                onClick={handleEdit}
                disabled={
                  currentUserRole !== RoleTypes.OWNER &&
                  currentUserRole !== RoleTypes.ADMIN &&
                  !user?.is_mmpz_service_admin
                }
              >
                Edit
              </ActionButtonSmall>
            </Grid>
          </Grid>

          <Box marginBottom={3} marginTop={2}>
            <Typography variant="h6" color="primary">
              Login
            </Typography>
            <Divider />
          </Box>
          <Grid item>
            <TextField
              fullWidth
              className={classes.textField}
              label="Login URL"
              value={`${
                `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
                "/login"
              }`}
            />
          </Grid>

          {profile.profile_type.name !== ProfileTypes.SONGWRITER && (
            <React.Fragment>
              <Box marginBottom={3} marginTop={2}>
                <Typography variant="h6" color="primary">
                  Registration
                </Typography>
                <Divider />
              </Box>
              <Grid item>
                <TextField
                  fullWidth
                  className={classes.textField}
                  label="Songwriter Registration URL"
                  value={`${
                    `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
                    "/" +
                    profile.url_name +
                    "/mmpz-signup" +
                    "/" +
                    "songwriter"
                  }`}
                />
              </Grid>
              {profile.profile_type.name !== ProfileTypes.PUBLISHER && (
                <Grid item>
                  <TextField
                    fullWidth
                    className={classes.textField}
                    label="Publisher Registration URL"
                    value={`${
                      `https://mmaz.${
                        testSite ? "test." : ""
                      }myclientzone.com` +
                      "/" +
                      profile.url_name +
                      "/mmpz-signup" +
                      "/" +
                      "publisher"
                    }`}
                  />
                </Grid>
              )}
            </React.Fragment>
          )}
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
