import { useContext, useEffect, useState } from "react";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { SnackbarContext } from "../../context/snackbar";

export default function useTerritories() {
  const [territories, setTerritories] = useState([]);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    API.get(ApiUri.TERRITORY).then(
      (response) => {
        setTerritories(response.data.collection);
      },
      (err) => {
        initSnackbarError(err);
      },
    );
  }, []); // eslint-disable-line

  return territories;
}
