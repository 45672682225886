import { Info } from "@mui/icons-material";
import {
  Badge,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext } from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { InitialSongwriter, initialSongwriter } from "./NewSongwriterForm";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import { SnackbarContext } from "../../../../../context/snackbar";
import ProfileTypes from "../../../../../enums/profile-types";

interface SmartCaeNumberFieldProps {
  newSongwriter: SongWriter | InitialSongwriter;
  setNewSongwriter: React.Dispatch<React.SetStateAction<InitialSongwriter>>;
  setCaeNumberIsBeingEdited: React.Dispatch<React.SetStateAction<boolean>>;
  setExistingSongwriter: React.Dispatch<any>;
  setUsingExistingSongwriter: React.Dispatch<React.SetStateAction<boolean>>;
  currentSongwriters: SongWriter[];
  usingExistingSongwriter: boolean;
}

const SmartCaeNumberField: React.FC<SmartCaeNumberFieldProps> = ({
  newSongwriter,
  setNewSongwriter,
  setCaeNumberIsBeingEdited,
  setExistingSongwriter,
  setUsingExistingSongwriter,
  currentSongwriters,
  usingExistingSongwriter,
}) => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const { initSnackbarError } = useContext(SnackbarContext);

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;
    if (name === "songwriter_cae_number") {
      setCaeNumberIsBeingEdited(true);
      setNewSongwriter((previousState) => ({
        ...previousState,
        songwriter_cae_number: value,
      }));
    } else if (name === "has_cae_number") {
      if (checked) {
        setNewSongwriter((previousState) => ({
          ...previousState,
          has_cae_number: checked,
          songwriter_cae_number: "",
        }));
      } else {
        setNewSongwriter((previousState) => ({
          ...previousState,
          has_cae_number: checked,
          pro_affiliation: null,
        }));
      }
    }
  };

  function hasMatchingCaeNumber(songwriters, cae_number) {
    for (let i = 0; i < songwriters.length; i++) {
      if (
        songwriters[i].songwriter_cae_number === cae_number &&
        cae_number !== ""
      ) {
        return true;
      }
    }
    return false;
  }

  const fetchSongwriterData = () => {
    startGlobalLoader();
    setCaeNumberIsBeingEdited(false);
    if (
      hasMatchingCaeNumber(
        currentSongwriters,
        newSongwriter.songwriter_cae_number
      )
    ) {
      setExistingSongwriter(null);
      setUsingExistingSongwriter(false);
      setNewSongwriter(initialSongwriter);
      stopGlobalLoader();
      initSnackbarError(
        "This CAE Number belongs to a songwriter that is already in use in this composition."
      );
    } else {
      API.get(ApiUri.PROFILE_CAE_NUMBER, {
        params: { cae_number: newSongwriter.songwriter_cae_number },
      }).then(
        (response) => {
          const existingSongwriter = response.data;

          if (
            existingSongwriter.profile_type.name !== ProfileTypes.SONGWRITER
          ) {
            setNewSongwriter((previousState) => ({
              ...previousState,
              songwriter_cae_number: "",
            }));
            initSnackbarError("You can only add songwriter profiles");
            stopGlobalLoader();
          } else {
            setExistingSongwriter(existingSongwriter);
            const confirmationSwitch =
              document.getElementById("confirmationSwitch");
            confirmationSwitch?.click();
            stopGlobalLoader();
          }
        },
        (error) => {
          setExistingSongwriter(null);
          setUsingExistingSongwriter(false);
          stopGlobalLoader();
        }
      );
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={newSongwriter.has_cae_number}
                onChange={handleChange}
                name="has_cae_number"
                color="primary"
                disabled={usingExistingSongwriter}
              />
            }
            label="Songwriter has a CAE/IPI Number"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6}>
        {newSongwriter.has_cae_number && (
          <FormControl fullWidth>
            <Tooltip
              title="The PRO membership number for the songwriter, also known as IPI number"
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              name="songwriter_cae_number"
              value={newSongwriter.songwriter_cae_number || ""}
              onChange={handleChange}
              required={true}
              type="number"
              label="Songwriter CAE/IPI Number"
              onBlur={fetchSongwriterData}
              disabled={
                !newSongwriter.has_cae_number || usingExistingSongwriter
              }
            />
          </FormControl>
        )}
      </Grid>
    </>
  );
};

export default SmartCaeNumberField;
