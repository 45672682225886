import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import SmallAvatar from "../../../../components/avatars/small-avatar";
import { Composition } from "../../../../models/composition/composition";
import ProfileTypes from "../../../../enums/profile-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

interface ISongwritersCard {
  composition: Composition;
}

export default function SongwritersCard({ composition }: ISongwritersCard) {
  const classes = useStyles();

  return composition ? (
    <Grid item xs={12} className={classes.gridItem}>
      <Paper elevation={3}>
        <Box p={2}>
          <Box marginBottom={3} marginTop={2}>
            <Typography variant="h6" color="primary">
              Songwriters
            </Typography>
            <Divider />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={4}
                alignContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={2}>
                    {composition?.song_writers?.map(
                      (songwriter, index: number) => (
                        <Paper
                          elevation={3}
                          sx={{ padding: 2, minWidth: "200px" }}
                        >
                          <Stack spacing={3}>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            >
                              <SmallAvatar
                                style={{ marginRight: 10 }}
                                profileType={ProfileTypes.SONGWRITER}
                              />
                              <Typography variant="overline">
                                {songwriter.external_song_writer
                                  ? `${songwriter.external_song_writer.first_name} ${songwriter.external_song_writer.last_name}`
                                  : songwriter.profile.profile_information.name}
                              </Typography>
                            </Stack>

                            <Stack spacing={2}>
                              <TextField
                                fullWidth
                                disabled
                                size="small"
                                label="CAE Number"
                                value={songwriter?.songwriter_cae_number || " "}
                                InputProps={{ readOnly: true }}
                              />

                              <TextField
                                fullWidth
                                disabled
                                size="small"
                                label="Percentage Split"
                                value={songwriter?.share + "%" || " "}
                                InputProps={{ readOnly: true }}
                              />

                              <TextField
                                fullWidth
                                disabled
                                size="small"
                                label="Publisher Name"
                                value={songwriter?.publisher_name || " "}
                                InputProps={{ readOnly: true }}
                              />

                              <TextField
                                fullWidth
                                disabled
                                size="small"
                                label="Publisher CAE Number"
                                value={songwriter?.publisher_cae_number || " "}
                                InputProps={{ readOnly: true }}
                              />
                            </Stack>
                          </Stack>
                        </Paper>
                      )
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
