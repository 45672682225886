import React, { createContext, SyntheticEvent, useState } from "react";
import { Alert, AlertColor, Slide, Snackbar } from "@mui/material";

export interface ISnackbarContext {
  initSnackbarSuccess: Function;
  initSnackbarError: Function;
  initSnackbarGeneric: Function;
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export const SnackbarContext = createContext({} as ISnackbarContext);

const SnackbarContextProvider = ({ children }: any) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const initSnackbarSuccess = (message: string) => {
    setSnackbarSeverity("success");
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const initSnackbarGeneric = (message: string) => {
    setSnackbarSeverity("info");
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const initSnackbarError = (error: any) => {
    let message: null | string = null;

    if (typeof error === "string") {
      message = error;
    } else {
      if (error.response.status === 400) {
        message = error.response.data.error;
      }
      if (error.response.status === 403) {
        message = error.response.data.error;
      }
      if (error.response.status === 409) {
        message = error.response.data.error;
      }
    }

    setSnackbarSeverity("error");
    setSnackbarMessage(message ? message : "Unknown error occurred");
    setSnackbarOpen(true);
  };

  return (
    <SnackbarContext.Provider
      value={{
        initSnackbarSuccess,
        initSnackbarError,
        initSnackbarGeneric,
      }}
    >
      <React.Fragment>
        {children}
        <Snackbar
          open={snackbarOpen}
          onClose={handleClose}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionComponent={SlideTransition}
          transitionDuration={{
            enter: 800,
            exit: 500,
          }}
          ContentProps={{
            style: {
              justifyContent: "center",
              fontWeight: 800,
            },
          }}
        >
          <Alert severity={snackbarSeverity} onClose={handleClose}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
