import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import ActionButtonSmall from "./layout/buttons/action-button-small";
import H1 from "./layout/titles/h1";
import { GlobalLoaderContext } from "../context/global-loader";
import { AuthContext } from "../context/auth";
import { SnackbarContext } from "../context/snackbar";
import { useHistory } from "react-router-dom";
import API from "../api/api";
import ApiUri from "../api/api-uri";

const ChooseInvitationPage = ({ invitations }) => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const history = useHistory();
  const { user, setUser } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const formatDate = (date) => {
    const parsedDate = new Date(date);

    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Note: Month is 0-based, so we add 1.
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;
    return formattedDateTime;
  };

  const handleAccept = (row) => {
    startGlobalLoader();
    const payloadObject = {
      user: {
        id: user?.id,
        email: user?.email,
      },
      role: row.role,
    };
    API.post(
      ApiUri.PROFILE + "/" + row.profile.id + ApiUri.RBAC,
      payloadObject
    ).then(
      (response) => {
        if (response.data?.id) {
          API.get(ApiUri.USER_ME).then((response) => {
            setUser(response.data);
            stopGlobalLoader();
            initSnackbarSuccess("Profile Succesfully created");

            const baseURL = window.location.origin;
            const dashboardURL = baseURL + ApiUri.DASHBOARD;
            window.location.href = dashboardURL;

            // history.push("/dashboard");
          });
        }
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <Grid
      container
      item
      component={Paper}
      xs={11}
      md={7}
      marginTop={1}
      paddingY={2}
      paddingX={3}
      elevation={5}
      spacing={3}
    >
      <Grid item xs={12} md={12}>
        <h1>Choose and accept an invitation</h1>
      </Grid>
      <Grid item xs={12} md={12}>
        <Table>
          <TableHead style={{ borderBottom: "2px solid" }}>
            <TableRow>
              <TableCell>
                <b>Profile Name</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Created At</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations &&
              invitations.map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell>
                      {row.profile.profile_information.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{formatDate(row.created_at)}</TableCell>
                    <TableCell align="right">
                      <ActionButtonSmall onClick={() => handleAccept(row)}>
                        Accept
                      </ActionButtonSmall>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default ChooseInvitationPage;
