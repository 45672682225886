import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewPaymentGatewayModal = ({
  paymentGateway,
  modalOpened,
  handleClose,
}) => {
  const classes = useStyles();
  const closeViewPaymentGatewayModal = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeViewPaymentGatewayModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              View Products Payment Provider
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeViewPaymentGatewayModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={10} marginX={20}>
          <Stack spacing={2}>
            <TextField
              value={paymentGateway.name}
              name="name"
              label="Name"
              disabled
            />
            <TextField
              value={paymentGateway.payment_type.name}
              disabled
              name="paymentType"
              label="paymentType"
            />
            {paymentGateway.payment_type.id === 1 && (
              <React.Fragment>
                <TextField
                  value={paymentGateway.provider_params.client_id}
                  name="clientID"
                  label="Client ID"
                  disabled
                />
                <TextField
                  value={paymentGateway.provider_params.secret}
                  disabled
                  name="secret"
                  label="Secret"
                />
              </React.Fragment>
            )}
            {paymentGateway.payment_type.id === 2 && (
              <React.Fragment>
                <TextField
                  value={paymentGateway.provider_params.publishable_key}
                  name="publishableKey"
                  label="Publishable Key"
                  disabled
                />
                <TextField
                  value={paymentGateway.provider_params.secret_key}
                  disabled
                  name="secretKey"
                  label="Secret Key"
                />
              </React.Fragment>
            )}
          </Stack>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default NewPaymentGatewayModal;
