import API from "../../api";
import ApiUri from "../../api-uri";

export const postFile = async ({ formData, params }) => {
  try {
    const response = await API.post(ApiUri.FILE, formData, {
      params,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
