import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import H2 from "../../../../components/layout/titles/h2";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
  }),
);

export default function ProfileUsersCard({ profile }) {
  const classes = useStyles();

  return profile ? (
    <Grid item className={classes.gridItem}>
      <Paper elevation={3}>
        <Box p={2}>
          <H2>Users</H2>
          <List>
            {profile.profile_rbac.map((profileRbac) => {
              return (
                <ListItem key={profileRbac.id} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      alt={profileRbac.user.user_profile.first_name}
                      src="#"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${profileRbac.user.user_profile.first_name} ${profileRbac.user.user_profile.last_name}`}
                    secondary={profileRbac.role.name}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
