import React, { useContext, useState } from "react";
import { Composition } from "../../../../../models/composition/composition";
import {
  Autocomplete,
  Badge,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import useLanguages from "../../../../../hooks/language/languages.hook";
import useCustomLanguages from "../../../../../hooks/language/custom-languages.hook";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import { Info } from "@mui/icons-material";
import { Language } from "../../../../../models/language/language";
import { CompositionLanguage } from "../../../../../models/language/composition-language";

interface FormStepOneProps {
  composition: Composition;
  handleSave: (step: number, currentComposition: Composition) => void;
}

const FormStepOne: React.FC<FormStepOneProps> = ({
  composition,
  handleSave,
}) => {
  const [currentComposition, setCurrentComposition] = useState(composition);

  const languages = useLanguages();
  const custom_languages = useCustomLanguages();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  const handleFieldChange = (event: any) => {
    let { name, value } = event.target;

    if (name === "is_instrumental") {
      value = value === "Yes";
    }

    setCurrentComposition((previousState) => ({
      ...previousState,
      [name]: value,
    }));
  };

  const handleLanguagesFieldChange = (value: Language[]) => {
    if (value.length > 0) {
      const compositionLanguages: CompositionLanguage[] = value.map(
        (lang, index) => ({
          language: lang,
          alternative: index === 0 ? 0 : 1,
        })
      );

      setCurrentComposition((previousState) => ({
        ...previousState,
        composition_languages: compositionLanguages,
      }));
    } else {
      setCurrentComposition((previousState) => ({
        ...previousState,
        composition_languages: [],
      }));
    }
  };

  const handleTitleLanguageFieldChange = (value) => {
    setCurrentComposition((previousState) => ({
      ...previousState,
      title_language: value,
    }));
  };

  const handleCustomLanguagesFieldChange = (value) => {
    const lastAddedCustomLanguage = value[value.length - 1];
    if (typeof lastAddedCustomLanguage === "string") {
      const trimmedLastAddedCustomLanguage = lastAddedCustomLanguage.trim();
      const hasCharacters = trimmedLastAddedCustomLanguage.length > 0;
      if (hasCharacters) {
        const newCustomLanguageObject = {
          name: trimmedLastAddedCustomLanguage,
        };

        startGlobalLoader();

        API.post(ApiUri.CUSTOM_LANGUAGE, newCustomLanguageObject).then(
          (response) => {
            value[value.length - 1] = response.data;
            stopGlobalLoader();
            initSnackbarSuccess("New custom language saved");
          },
          (err) => {
            stopGlobalLoader();
            initSnackbarError(err);
          }
        );

        setCurrentComposition((previousState) => ({
          ...previousState,
          custom_languages: value,
        }));
      }
    } else {
      setCurrentComposition((previousState) => ({
        ...previousState,
        custom_languages: value,
      }));
    }
  };

  const handleCustomLanguagesFieldBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const newCustomLanguageString = event.target.value;
    const oldCustomLanguages = currentComposition.custom_languages;
    const newCustomLanguages = oldCustomLanguages
      ? [...oldCustomLanguages, newCustomLanguageString]
      : [newCustomLanguageString];
    handleCustomLanguagesFieldChange(newCustomLanguages);
  };

  const selectedLanguages = currentComposition.composition_languages
    ? currentComposition.composition_languages.map(
        (compLang) => compLang.language
      )
    : [];

  const formIsValid =
    currentComposition.title &&
    (currentComposition.is_instrumental ||
      (currentComposition.lyrics &&
        currentComposition?.composition_languages?.length > 0)) &&
    currentComposition.title_language;

  return (
    <Stack spacing={4}>
      <Grid container spacing={4} alignContent="center" alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Tooltip title="The title of your song" placement="top-end">
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              required
              label="Title"
              name="title"
              value={currentComposition.title || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Tooltip
              title="If you have registered the song previously at PRS in the UK, please provide the Tunecode that they have allocated to the song. Leave blank if you don't have a Tunecode."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              label="Tunecode"
              name="tunecode"
              value={currentComposition.tunecode || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Tooltip
              title="ISWC = International Standard Musical Work Code. If you have registered the song previously at any PRO and it has been allocated an ISWC, please provide that here. ISWC's begin with a T followed by 9 digits and a check digit, e.g. T-123.456.789-1. Leave blank if you don't have an existing ISWC."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <TextField
              label="ISWC"
              name="iswc"
              value={currentComposition.iswc || ""}
              onChange={handleFieldChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Tooltip
              title="Select whether the composition is instrumental only or not."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <InputLabel required>Is instrumental only?</InputLabel>
            <Select
              value={currentComposition.is_instrumental ? "Yes" : "No"}
              onChange={handleFieldChange}
              name="is_instrumental"
              label="Is instrumental only?"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {!currentComposition.is_instrumental ? (
          <React.Fragment>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  focused
                  label="Lyrics"
                  placeholder="Provide the full lyrics to your song along with an English translation if the lyrics are in a different language..."
                  name="lyrics"
                  variant="outlined"
                  multiline
                  rows={10}
                  value={currentComposition.lyrics ?? ""}
                  onChange={handleFieldChange}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Tooltip
                  title="Enter the main languages of your lyrics. Multiple languages are accepted."
                  placement="top-end"
                >
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={<Info color="disabled" />}
                  />
                </Tooltip>
                <Autocomplete
                  multiple
                  value={selectedLanguages}
                  onChange={(event, value) => handleLanguagesFieldChange(value)}
                  options={languages}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Lyrics language(s)"
                      required
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </React.Fragment>
        ) : null}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Tooltip
              title="The language your title is written in. This may be different to the language of your lyrics."
              placement="top-end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={<Info color="disabled" />}
              />
            </Tooltip>
            <Autocomplete
              value={currentComposition.title_language ?? null}
              onChange={(event, value) => handleTitleLanguageFieldChange(value)}
              options={languages}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField required {...params} label="Title language" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              multiple
              clearOnBlur
              value={currentComposition.custom_languages ?? []}
              onChange={(event, value) =>
                handleCustomLanguagesFieldChange(value)
              }
              onBlur={handleCustomLanguagesFieldBlur}
              options={custom_languages}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              renderInput={(params) => (
                <TextField {...params} label="Custom Language" />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <ActionButtonSmall
        onClick={() => handleSave(0, currentComposition)}
        disabled={!formIsValid}
      >
        Next
      </ActionButtonSmall>
    </Stack>
  );
};

export default FormStepOne;
