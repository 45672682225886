import {
  Dialog,
  AppBar,
  Typography,
  Grid,
  Slide,
  Theme,
  TextField,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TransitionProps } from "@mui/material/transitions";
import React, { useContext, useState } from "react";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { useHistory } from "react-router-dom";
import { CompositionContext } from "../../../../../context/composition-context";
import CustomToolbar from "../../../../../components/layout/titles/CustomToolbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: "300px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  // made children required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IUnsubmissionDialog {
  dialogOpened: boolean;
  onCloseDialog: Function;
}

export default function UnsubmissionDialog({
  dialogOpened,
  onCloseDialog,
}: IUnsubmissionDialog) {
  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState();
  const { composition } = useContext(CompositionContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const closeDialog = () => {
    onCloseDialog();
  };

  const confirmUnsubmission = () => {
    startGlobalLoader();

    const data = {
      message: message,
    };

    API.post(
      ApiUri.COMPOSITION + "/" + composition.id + ApiUri.UNSUBMIT,
      data,
    ).then(
      (response) => {
        stopGlobalLoader();
        history.push("/songs/manage");
        initSnackbarSuccess("Song successfully unsubmitted");
        onCloseDialog();
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      },
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogOpened}
      onClose={closeDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <AppBar className={classes.appBar}>
        <CustomToolbar>
          <Typography variant="h6" className={classes.title}>
            Unsubmit Song
          </Typography>
        </CustomToolbar>
      </AppBar>

      <Grid container spacing={2} style={{ padding: 20 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            rows={10}
            multiline
            variant="outlined"
            placeholder="Type in your unsubmission reason..."
            label="Unsubmission reason"
            value={message}
            onChange={onMessageChange}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        style={{ padding: 20 }}
      >
        <Grid item>
          <ActionButtonSmall onClick={closeDialog}>Cancel</ActionButtonSmall>
        </Grid>
        <Grid item>
          <ActionButtonSmall onClick={confirmUnsubmission}>
            Confirm
          </ActionButtonSmall>
        </Grid>
      </Grid>
    </Dialog>
  );
}
