export const darkenColor = (hexColor, percent) => {
    const color = hexColor.replace("#", "");
    const num = parseInt(color, 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) - amt;
    const G = ((num >> 8) & 0x00ff) - amt;
    const B = (num & 0x0000ff) - amt;
    const newColor =
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1);
    return newColor;
  }

export const getContrastColor = (color) => {
    // Convert the color to an RGB array
    let hex = color.replace("#", "");
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
  
    // Check if the color is too bright
    if (r*0.299 + g*0.587 + b*0.114 > 186) {
      return "#000"; // Return black if the color is too bright
    } else {
      return "#fff"; // Return white if the color is not too bright
    }
  }
  