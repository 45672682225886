import {
  Badge,
  Box,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Info, Save } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import H2 from "../../../components/layout/titles/h2";
import useLastAccessedProfile from "../../../hooks/profile/last-accessed-profile.hook";
import { PlatformIpiNumber } from "../../../models/profile/platform-ipi-number";
import { AxiosResponse } from "axios";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { TwoFactorAuthenticationModal } from "../../../components/TwoFactorAuthenticationModal";
import { AuthContext } from "../../../context/auth";
import { useHistory } from "react-router-dom";
import RoleTypes from "../../../enums/role-types";
import ProfileTypes from "../../../enums/profile-types";

export default function Platforms() {
  const history = useHistory();
  const { user, userRole } = useContext(AuthContext);
  const profile = useLastAccessedProfile();
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [authorized, setAuthorized] = useState(true);
  const [openInitial2FA, setOpenInitial2FA] = useState(false);
  const [openSave2FA, setOpenSave2FA] = useState(false);
  const [platforms, setPlatforms] = useState<PlatformIpiNumber[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<any>(null);
  const [editedData, setEditedData] = useState({
    ipi_number: "",
    agreetment_number: "",
    sender_code: "",
    receiver_code: "",
    ftp_username: "",
    ftp_password: "",
    specification: "",
    client_name: "",
    password: "",
  });
  const [showPlatformsPage, setShowPlatformsPage] = useState(false);

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name !== ProfileTypes.SONGWRITER;

  const handlePlatformChange = (event) => {
    const platformId = event.target.value;
    const selectedPlatform = platforms.find(
      (platform) => platform.platform.id === platformId
    );
    const selectedPlatformName = selectedPlatform?.platform.name;
    setSelectedPlatform(selectedPlatform);
    setEditedData({
      ipi_number: selectedPlatform?.ipi_number || "",
      agreetment_number: selectedPlatform?.agreetment_number || "",
      sender_code:
        selectedPlatformName !== "LyricFind"
          ? selectedPlatform?.sender_code || ""
          : "",
      receiver_code:
        selectedPlatformName !== "LyricFind"
          ? selectedPlatform?.receiver_code || ""
          : "",
      ftp_username:
        selectedPlatformName !== "LyricFind"
          ? selectedPlatform?.ftp_username || ""
          : "",
      ftp_password:
        selectedPlatformName !== "LyricFind"
          ? selectedPlatform?.ftp_password || ""
          : "",
      specification: selectedPlatform?.specification || "",
      client_name: selectedPlatform?.client_name || "",
      password: selectedPlatform?.password || "",
    });
  };

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowPlatformsPage(true);
      startGlobalLoader();
      API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
        (response) => {
          const profile = response.data;
          const userRole = profile?.profile_rbac?.find(
            (profile) => profile.user.id === user?.id
          )?.role.name;
          setCurrentUserRole(userRole);
          // change this in case we want to show the page in read only mode
          if (
            userRole !== RoleTypes.OWNER &&
            userRole !== RoleTypes.ADMIN &&
            !user?.is_mmpz_service_admin
          ) {
            stopGlobalLoader();
            initSnackbarError("You don't have permission to access this page.");
            history.push("/dashboard");
            return;
          } else {
            stopGlobalLoader();
          }
        },
        (error) => {
          initSnackbarError(error);
          stopGlobalLoader();
        }
      );
      if (user?.twoFactorAuthenticationEnabled) {
        setAuthorized(false);
        setOpenInitial2FA(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!profile) {
      return;
    }

    setPlatforms(profile?.platform_ipi_numbers);
  }, [profile]); // eslint-disable-line

  // const handleIPIChange = (
  //   event: any,
  //   platformIpiNumber: PlatformIpiNumber
  // ) => {
  //   const { value } = event.target;

  //   const data = platforms.map((item: PlatformIpiNumber) => {
  //     if (item.id === platformIpiNumber.id) {
  //       item.ipi_number = value;
  //     }

  //     return item;
  //   });

  //   setPlatforms(data);
  // };

  // const handleAgreetmentChange = (
  //   event: any,
  //   platformIpiNumber: PlatformIpiNumber
  // ) => {
  //   const { value } = event.target;

  //   const data = platforms.map((item: PlatformIpiNumber) => {
  //     if (item.id === platformIpiNumber.id) {
  //       item.agreetment_number = value;
  //     }

  //     return item;
  //   });

  //   setPlatforms(data);
  // };

  // const handleOnClickIPI = (platformIpiNumber: PlatformIpiNumber) => {
  //   if (
  //     !platformIpiNumber.ipi_number ||
  //     platformIpiNumber.ipi_number.length === 0
  //   ) {
  //     return;
  //   }

  //   const data = {
  //     agreetment_number: platformIpiNumber.agreetment_number,
  //     ipi_number: platformIpiNumber.ipi_number,
  //   };

  //   startGlobalLoader();

  //   API.put(
  //     ApiUri.PROFILE +
  //       "/" +
  //       profile?.id +
  //       ApiUri.PLATFORM_IPI_NUMBER +
  //       "/" +
  //       platformIpiNumber.id,
  //     data
  //   ).then(
  //     (response: AxiosResponse) => {
  //       stopGlobalLoader();
  //       initSnackbarSuccess("IPI Number successfully updated");
  //     },
  //     (err) => {
  //       stopGlobalLoader();
  //       initSnackbarError(err);
  //     }
  //   );
  // };

  // const handleOnClickAgreetment = (platformIpiNumber: PlatformIpiNumber) => {
  //   if (
  //     !platformIpiNumber.agreetment_number ||
  //     platformIpiNumber.agreetment_number.length === 0
  //   ) {
  //     return;
  //   }

  //   const data = {
  //     agreetment_number: platformIpiNumber.agreetment_number,
  //     ipi_number: platformIpiNumber.ipi_number,
  //   };

  //   startGlobalLoader();

  //   API.put(
  //     ApiUri.PROFILE +
  //       "/" +
  //       profile?.id +
  //       ApiUri.PLATFORM_IPI_NUMBER +
  //       "/" +
  //       platformIpiNumber.id,
  //     data
  //   ).then(
  //     (response: AxiosResponse) => {
  //       stopGlobalLoader();
  //       initSnackbarSuccess("Agreetment Number successfully updated");
  //     },
  //     (err) => {
  //       stopGlobalLoader();
  //       initSnackbarError(err);
  //     }
  //   );
  // };

  const handleFieldChange = (fieldName, value) => {
    setEditedData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  const handleSave = () => {
    if (
      editedData.sender_code.length > 3 ||
      editedData.receiver_code.length > 3
    ) {
      initSnackbarError(
        "The sender code and the receiver code can only be up to 3 characters long."
      );
    } else {
      const data = {
        agreetment_number: editedData.agreetment_number,
        ipi_number: editedData.ipi_number,
        sender_code: editedData.sender_code,
        receiver_code: editedData.receiver_code,
        ftp_username: editedData.ftp_username,
        ftp_password: editedData.ftp_password,
        specification: editedData.specification,
        client_name: editedData.client_name,
        password: editedData.password,
      };

      startGlobalLoader();

      API.put(
        ApiUri.PROFILE +
          "/" +
          profile?.id +
          ApiUri.PLATFORM_IPI_NUMBER +
          "/" +
          selectedPlatform.id,
        data
      ).then(
        (response: AxiosResponse) => {
          const updatedPlatforms = platforms.map((platform) =>
            platform.platform.id === selectedPlatform.platform.id
              ? { ...platform, ...editedData }
              : platform
          );
          setPlatforms(updatedPlatforms);
          stopGlobalLoader();
          initSnackbarSuccess("Platform successfully updated");
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    }
  };

  const handleSaveClick = () => {
    if (user?.twoFactorAuthenticationEnabled) {
      setOpenSave2FA(true);
    } else {
      handleSave();
    }
  };

  let initial2FAApproved = false;

  const handleCloseInitial2FASuccess = () => {
    initial2FAApproved = true;
    setAuthorized(true);
    console.log("approved");
  };

  const handleCloseInitial2FAError = () => {
    if (!initial2FAApproved) {
      console.log("not approved, redirecting to dashboard");
      initSnackbarError(
        "Please complete Two Factor Authentication to access the Platforms page"
      );
      history.push("/dashboard");
    } else {
      setOpenInitial2FA(false);
    }
  };

  let save2FAApproved = false;

  const handleCloseSave2FASuccess = () => {
    save2FAApproved = true;
    console.log("approved");
    handleSave();
  };

  const handleCloseSave2FAError = () => {
    if (!save2FAApproved) {
      console.log("not approved");
      initSnackbarError("Not Approved. Please check the code.");
    }
    setOpenSave2FA(false);
  };

  return (
    showPlatformsPage && (
      <React.Fragment>
        <TwoFactorAuthenticationModal
          modalOpened={openInitial2FA}
          handleClose={handleCloseInitial2FAError}
          onSuccess={handleCloseInitial2FASuccess}
        />

        <TwoFactorAuthenticationModal
          modalOpened={openSave2FA}
          handleClose={handleCloseSave2FAError}
          onSuccess={handleCloseSave2FASuccess}
        />

        {platforms && authorized ? (
          <React.Fragment>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip
                title="Please add IPI numbers which should be used for each platform listed. IPI number will be used in CWR files, spreadsheets etc. which will be generated for each platform."
                placement="top-end"
              >
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={<Info color="disabled" />}
                />
              </Tooltip>
            </Box>

            <Stack spacing={4}>
              <FormControl fullWidth>
                <InputLabel>Select Platform</InputLabel>
                <Select
                  labelId="platform-select-label"
                  value={selectedPlatform ? selectedPlatform.platform.id : ""}
                  onChange={handlePlatformChange}
                  label="Select Platform"
                >
                  {platforms.map((platform) => (
                    <MenuItem key={platform.id} value={platform.platform.id}>
                      {platform.platform.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedPlatform && (
                <Stack spacing={2}>
                  <TextField
                    label="IPI Number"
                    fullWidth
                    value={editedData.ipi_number}
                    onChange={(e) =>
                      handleFieldChange("ipi_number", e.target.value)
                    }
                    disabled={
                      currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      !user?.is_mmpz_service_admin
                    }
                  />
                  {/* <TextField
                    label="Agreement Number"
                    fullWidth
                    value={editedData.agreetment_number}
                    onChange={(e) =>
                      handleFieldChange("agreetment_number", e.target.value)
                    }
                    disabled={
                      currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      !user?.is_mmpz_service_admin
                    }
                  /> */}
                  {user?.last_accessed_profile.profile_type.name !==
                    ProfileTypes.PUBLISHER &&
                    selectedPlatform?.platform?.name !== "LyricFind" && (
                      <>
                        <TextField
                          label="Sender Code"
                          fullWidth
                          value={editedData.sender_code}
                          onChange={(e) =>
                            handleFieldChange("sender_code", e.target.value)
                          }
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                        <TextField
                          label="Receiver Code"
                          fullWidth
                          value={editedData.receiver_code}
                          onChange={(e) =>
                            handleFieldChange("receiver_code", e.target.value)
                          }
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                        <TextField
                          label="FTP Username"
                          fullWidth
                          value={editedData.ftp_username}
                          onChange={(e) =>
                            handleFieldChange("ftp_username", e.target.value)
                          }
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                        <TextField
                          label="FTP Password"
                          fullWidth
                          value={editedData.ftp_password}
                          onChange={(e) =>
                            handleFieldChange("ftp_password", e.target.value)
                          }
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                      </>
                    )}

                  {user?.last_accessed_profile.profile_type.name !==
                    ProfileTypes.PUBLISHER &&
                    selectedPlatform?.platform?.name === "LyricFind" && (
                      <>
                        <TextField
                          label="Specification"
                          fullWidth
                          value={editedData.specification}
                          onChange={(e) =>
                            handleFieldChange("specification", e.target.value)
                          }
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                        <TextField
                          label="Client Name"
                          fullWidth
                          value={editedData.client_name}
                          onChange={(e) =>
                            handleFieldChange("client_name", e.target.value)
                          }
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                        <TextField
                          label="Password"
                          fullWidth
                          value={editedData.password}
                          onChange={(e) =>
                            handleFieldChange("password", e.target.value)
                          }
                          disabled={
                            currentUserRole !== RoleTypes.OWNER &&
                            currentUserRole !== RoleTypes.ADMIN &&
                            !user?.is_mmpz_service_admin
                          }
                        />
                      </>
                    )}

                  <ActionButtonSmall
                    onClick={handleSaveClick}
                    disabled={
                      currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      !user?.is_mmpz_service_admin
                    }
                  >
                    Save Platform
                  </ActionButtonSmall>
                </Stack>
              )}

              {/* <H2>Platform IPI Numbers</H2>

          <Paper elevation={4} style={{ padding: 20, marginBottom: 40 }}>
            <Grid container spacing={4}>
              {platforms.map((platformIpiNumber: PlatformIpiNumber, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    fullWidth
                    name="ipi_number"
                    label={platformIpiNumber.platform.name}
                    value={platformIpiNumber.ipi_number || ""}
                    onChange={(event) =>
                      handleIPIChange(event, platformIpiNumber)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Save
                            onClick={() => handleOnClickIPI(platformIpiNumber)}
                            style={{ cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>

          <Divider />

          <H2>Platform Agreetment Numbers</H2>

          <Paper elevation={4} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={4}>
              {platforms.map((platform: PlatformIpiNumber, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    fullWidth
                    name="agreetment_number"
                    label={platform.platform.name}
                    value={platform.agreetment_number || ""}
                    onChange={(event) =>
                      handleAgreetmentChange(event, platform)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Save
                            onClick={() =>
                              handleOnClickAgreetment(platform)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper> */}
            </Stack>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  );
}
