import {
  Dialog,
  AppBar,
  Typography,
  Grid,
  Slide,
  Theme,
  List,
  Checkbox,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TransitionProps } from "@mui/material/transitions";
import React, { useContext } from "react";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { CompositionContext } from "../../../../../context/composition-context";
import usePlatforms from "../../../../../hooks/publishing-platform/platforms.hook";
import { Platform } from "../../../../../models/publishing/platform";
import { CompositionPlatform } from "../../../../../models/composition/composition-platform";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import { Close } from "@mui/icons-material";
import CustomToolbar from "../../../../../components/layout/titles/CustomToolbar";
import { Composition } from "../../../../../models/composition/composition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: "300px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made children required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IPlatformsDialog {
  dialogOpened: boolean;
  onCloseDialog: Function;
}

export default function PlatformsDialog({
  dialogOpened,
  onCloseDialog,
}: IPlatformsDialog) {
  const classes = useStyles();
  const platforms = usePlatforms();
  const { composition, setComposition } = useContext(CompositionContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const platformSelected = (platform: Platform) => {
    return Boolean(
      composition.composition_platforms.find(
        (compositionPlatform: CompositionPlatform) =>
          compositionPlatform.platform.id === platform.id
      )
    );
  };

  const togglePlatform = (platform: Platform) => {
    if (!platformSelected(platform)) {
      addPlatform(platform);
    } else {
      removePlatform(platform);
    }
  };

  const addPlatform = (platform: Platform) => {
    startGlobalLoader();

    API.post(
      ApiUri.COMPOSITION +
        "/" +
        composition.id +
        ApiUri.PLATFORM +
        "/" +
        platform.id
    ).then(
      (response) => {
        stopGlobalLoader();
        setComposition(response.data);
        initSnackbarSuccess("Platform selected");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const removePlatform = (platform: Platform) => {
    startGlobalLoader();
    API.delete(
      ApiUri.COMPOSITION +
        "/" +
        composition.id +
        ApiUri.PLATFORM +
        "/" +
        platform.id
    ).then(
      (response) => {
        stopGlobalLoader();
        setComposition(response.data);
        initSnackbarSuccess("Platform removed");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const closeDialog = () => {
    onCloseDialog();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogOpened}
      onClose={closeDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <AppBar className={classes.appBar}>
        <CustomToolbar>
          <Typography variant="h6" className={classes.title}>
            {`Select Platforms - ${composition?.title}`}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
            size="large"
          >
            <Close />
          </IconButton>
        </CustomToolbar>
      </AppBar>

      <Grid container spacing={2} style={{ padding: 20 }}>
        <Grid item xs={12}>
          <List>
            {platforms.map((platform: Platform, index) => (
              <ListItem key={index}>
                <ListItemText>{platform.name}</ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    checked={platformSelected(platform)}
                    onClick={() => togglePlatform(platform)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Dialog>
  );
}
