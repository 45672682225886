import {
  Dialog,
  AppBar,
  Typography,
  Grid,
  Slide,
  Theme,
  List,
  Checkbox,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TransitionProps } from "@mui/material/transitions";
import React, { useContext, useEffect, useState } from "react";
import usePlatforms from "../../../../hooks/publishing-platform/platforms.hook";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import { Platform } from "../../../../models/publishing/platform";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { CompositionPlatform } from "../../../../models/composition/composition-platform";
import CustomToolbar from "../../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import { Composition } from "../../../../models/composition/composition";
import { Song } from "../../../../models/song/song";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: "300px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made children required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IPlatformsDialog {
  dialogOpened: boolean;
  onCloseDialog: Function;
  compositionId: number;
  setSongs: any;
}

export default function PlatformsDialog({
  dialogOpened,
  onCloseDialog,
  compositionId,
  setSongs,
}: IPlatformsDialog) {
  const classes = useStyles();
  const platforms = usePlatforms();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [composition, setComposition] = useState<any>(null);

  useEffect(() => {
    if (dialogOpened) {
      startGlobalLoader();
      API.get(ApiUri.COMPOSITION + "/" + compositionId).then(
        (response) => {
          setComposition(response.data);
          stopGlobalLoader();
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    }
  }, [dialogOpened]);

  const platformSelected = (platform: Platform) => {
    return Boolean(
      composition
        ? composition.composition_platforms.find(
            (compositionPlatform) =>
              compositionPlatform.platform.id === platform.id
          )
        : false
    );
  };

  function formatDateAndTime(inputString) {
    const date = new Date(inputString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const platformDate = (platform: Platform) => {
    if (composition) {
      const foundPlatform = composition.composition_platforms.find(
        (compositionPlatform) => compositionPlatform.platform.id === platform.id
      );
      if (foundPlatform) {
        return `Delivered at ${formatDateAndTime(foundPlatform.updated_at)}`;
      } else {
        return "";
      }
    }
  };

  const togglePlatform = (platform: Platform) => {
    if (!platformSelected(platform)) {
      addPlatform(platform);
    }
  };

  const addPlatform = (platform: Platform) => {
    startGlobalLoader();

    API.post(
      ApiUri.COMPOSITION +
        "/" +
        compositionId +
        ApiUri.PLATFORM +
        "/" +
        platform.id
    ).then(
      (response) => {
        stopGlobalLoader();
        setComposition(response.data);
        initSnackbarSuccess("Platform selected");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const closeDialog = () => {
    onCloseDialog();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogOpened}
      onClose={closeDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <AppBar className={classes.appBar}>
        <CustomToolbar>
          <Typography variant="h6" className={classes.title}>
            {`Select Platforms - ${composition?.title}`}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
            size="large"
          >
            <Close />
          </IconButton>
        </CustomToolbar>
      </AppBar>
      {composition?.composition_platforms ? (
        <Grid container spacing={2} style={{ padding: 20 }}>
          <Grid item xs={12}>
            <List>
              {platforms.map((platform: Platform, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText>{platform.name}</ListItemText>
                    {platformDate(platform) ? (
                      <ListItemSecondaryAction>
                        {platformDate(platform)}
                      </ListItemSecondaryAction>
                    ) : (
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          checked={platformSelected(platform)}
                          onClick={() => togglePlatform(platform)}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                  {platforms.length - 1 > index && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ height: "500px" }}></Grid>
      )}
    </Dialog>
  );
}
