import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import ProfileTypes from "../../enums/profile-types";

export default function useSongwriterRbac() {
  const { user } = useContext(AuthContext);
  const [canCreateSongwriter, setCanCreateSongwriter] = useState(false);

  useEffect(() => {
    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE
    ) {
      setCanCreateSongwriter(false);
    }

    if (
      user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR
    ) {
      setCanCreateSongwriter(true);
    }

    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.PUBLISHER
    ) {
      setCanCreateSongwriter(true);
    }

    if (
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SONGWRITER
    ) {
      setCanCreateSongwriter(false);
    }

    return () => {
      setCanCreateSongwriter(false);
    };
  }, []); // eslint-disable-line
  return canCreateSongwriter;
}
