import {
  AppBar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import CustomToolbar from "./layout/titles/CustomToolbar";
import ActionButtonSmall from "./layout/buttons/action-button-small";
import { contentTypesLabels } from "../pages/settings/contracts/types";
import { GlobalLoaderContext } from "../context/global-loader";
import API from "../api/api";
import ApiUri from "../api/api-uri";
import { AuthContext } from "../context/auth";
import { SnackbarContext } from "../context/snackbar";
import parse from "html-react-parser";

// const testSite =
//   window.location.href.includes("test") ||
//   window.location.href.includes("localhost");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContractModal = ({
  modalOpened,
  handleClose,
  contractContent,
  requireSignature,
}) => {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const [signature, setSignature] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setSignature(value);
  };

  const handleClick = () => {
    const nameFromUser =
      user?.user_profile.first_name + " " + user?.user_profile.last_name;
    if (nameFromUser.trim() === signature.trim()) {
      // if (testSite) {
      startGlobalLoader();
      API.post(ApiUri.COMPOSITION + ApiUri.SIGNATURE).then(
        (response) => {
          const signatureIdFromEndpoint = response.data.id;
          handleClose(true, signatureIdFromEndpoint);
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
      // } else {
      //   handleClose(true);
      // }
    } else {
      initSnackbarError("The signature name must match your user's name");
    }
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        open={modalOpened}
        onClose={() => handleClose(false)}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="xl"
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              {/* change this when adding new contract types */}
              {contentTypesLabels[0]}
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => handleClose(false)}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={5} paddingX={20}>
          <Stack p={4} sx={{ backgroundColor: "#E7EAEB" }}>
            {parse(contractContent)}
          </Stack>
          {requireSignature && (
            <React.Fragment>
              <Divider />
              <Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <p>I, </p>
                  <TextField
                    sx={{ p: "10px" }}
                    value={signature}
                    onChange={handleChange}
                  />
                  <p>,</p>
                </Stack>
                <p>
                  confirm that I have read and accept the Publishing Terms and
                  Conditions
                </p>
              </Stack>
              <ActionButtonSmall onClick={handleClick}>
                Accept
              </ActionButtonSmall>
            </React.Fragment>
          )}
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default ContractModal;
