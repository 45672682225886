import {
  Badge,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { InitialSongwriter } from "./NewSongwriterForm";
import { Info } from "@mui/icons-material";

interface ShareFieldProps {
  newSongwriter: SongWriter | InitialSongwriter;
  setNewSongwriter:
    | React.Dispatch<React.SetStateAction<SongWriter>>
    | React.Dispatch<React.SetStateAction<InitialSongwriter>>;
}

const ShareField: React.FC<ShareFieldProps> = ({
  newSongwriter,
  setNewSongwriter,
}) => {
  const handleChange = (event: any) => {
    let { value } = event.target;
    value = value.replace(/,/g, ".").replace(/^\.|,$/g, "");

    // Validate the input using a regular expression
    const isValidInput = /^(?!-)\d*(\.\d{0,2})?$/.test(value);

    if (isValidInput) {
      setNewSongwriter((previousState) => ({
        ...previousState,
        share: value ?? 0,
      }));
    }
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Tooltip
          title="The percentage share of the copyright in this song that this songwriter controls. If there is more than one songwriter, the total percentage must equal 100% before you can proceed."
          placement="top-end"
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={<Info color="disabled" />}
          />
        </Tooltip>
        <TextField
          required={true}
          value={newSongwriter.share || ""}
          onChange={handleChange}
          label="Share"
          InputProps={{
            inputProps: {
              min: 0,
              max: 100,
              inputMode: "numeric",
            },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </FormControl>
    </Grid>
  );
};

export default ShareField;
