import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import MmpzProfilesAutocomplete from "../../../../../components/profile/mmpz-profiles-autocomplete";
import ProfileTypes from "../../../../../enums/profile-types";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { CompositionContext } from "../../../../../context/composition-context";

interface IConnectExternalSongwriter {
  songWriter: SongWriter;
}

export default function ConnectExternalSongwriter({
  songWriter,
}: IConnectExternalSongwriter) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [profile, setProfile] = useState<any>();
  const { composition, setComposition } = useContext(CompositionContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  const onChange = (profile) => {
    if (profile?.id) {
      setProfile(profile);
      setShowConfirmationDialog(true);
    }
  };

  const handleCancel = () => {
    setShowConfirmationDialog(false);
  };

  const handleOk = () => {
    setShowConfirmationDialog(false);

    const data = {
      profile_id: profile?.id,
      action: "link",
    };

    startGlobalLoader();

    API.post(
      ApiUri.SONG_WRITER + "/" + songWriter.id + ApiUri.CONNECT_PROFILE,
      data
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Songwriter successfully connected");

        const songWriters = composition.song_writers.map((sw, index) => {
          if (sw.id === songWriter.id) {
            return response.data;
          }
          return sw;
        });

        setComposition((previousState) => ({
          ...previousState,
          song_writers: songWriters,
        }));
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputLabel style={{ marginBottom: 4 }}>
          Connect with existing MMPZ Profile?
        </InputLabel>
        <MmpzProfilesAutocomplete
          autocompleteOnChange={onChange}
          profileType={ProfileTypes.SONGWRITER}
        />
      </Grid>
      <Grid item xs={12}>
        <Dialog
          disableEscapeKeyDown
          open={showConfirmationDialog}
          onClose={() => setShowConfirmationDialog(false)}
          fullWidth
        >
          <DialogTitle>
            <Typography>
              <b>Please confirm this action</b>
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box padding={2} display="flex" justifyContent="center">
              <Typography>
                Are you sure you want to connect external songwriter to this
                MMPZ profile?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ marginBottom: "10px" }}>
            <Grid container justifyContent="space-evenly">
              <ActionButtonSmall onClick={handleCancel}>
                Cancel
              </ActionButtonSmall>
              <ActionButtonSmall color="primary" onClick={handleOk}>
                Ok
              </ActionButtonSmall>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
