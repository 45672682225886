import React, { useContext } from "react";
import { Box, Divider, Menu, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AuthContext } from "../context/auth";
import Colors from "../enums/colors";
import SmallAvatar from "../components/avatars/small-avatar";
import { CustomisationContext } from "../context/customisation";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

const useStyles = makeStyles(() => {
  return {
    userProfileDropdownBox: {
      marginRight: 20,
      "&:hover": {
        cursor: "pointer",
      },
    },
    link: {
      textDecoration: "none",
    },
  };
});

export default function UserProfileDropdown() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { customisation } = useContext(CustomisationContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.userProfileDropdownBox}
        onClick={handleClick}
      >
        <SmallAvatar
          alt={user?.user_profile.first_name}
          src={user?.user_profile?.picture_url}
          hideBorder={true}
        />
        {/* <h5 color={Colors.white} style={{ marginLeft: 5 }}> */}
        <Typography sx={{ marginLeft: 1, color: customisation?.textColor }}>
          {user?.user_profile.first_name} {user?.user_profile.last_name}
        </Typography>
        {/* </h5> */}
        <ArrowDropDownIcon sx={{ color: customisation?.textColor }} />
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ top: 5 }}
      >
        <MenuItem onClick={handleClose}>
          <a
            className={classes.link}
            href={
              `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
              "/dashboard"
            }
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <AccountCircleRoundedIcon color="primary" />
              <b style={{ fontSize: 12, color: Colors.black }}>My Account</b>
            </Box>
          </a>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleClose}>
          <a
            className={classes.link}
            href={
              `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
              "/logout"
            }
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <ExitToAppIcon color="primary" />
              <b style={{ fontSize: 12, color: Colors.black }}>Logout</b>
            </Box>
          </a>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
