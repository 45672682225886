import React, { useContext, useEffect, useState } from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { Grid, Paper, Stack } from "@mui/material";
import ContributionsCheckboxes from "./ContributionsCheckboxes";
import PublisherInfoFields from "./PublisherInfoFields";
import SongwriterCaeNumberField from "./SongwriterCaeNumberField";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import { ProAffiliation } from "../../../../../models/composition/pro-affiliation";
import ProAfilitiationField from "./ProAfilitiationField";
import ShareField from "./ShareField";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import ConfirmationDialogActivator from "../../../../../components/confirmation-dialog/confirmation-dialog-activator";
import { Delete } from "@mui/icons-material";

interface SongWriterFormProps {
  songWriter: SongWriter;
  saveSongwriterCallback: (
    newSongwriter: SongWriter,
    oldSongwriter: SongWriter
  ) => void;
  showLyricsContribution: boolean;
  index: number;
  deleteSongwriterCallback: (index: number) => void;
}

const SongwriterForm: React.FC<SongWriterFormProps> = ({
  songWriter,
  saveSongwriterCallback,
  showLyricsContribution,
  index,
  deleteSongwriterCallback,
}) => {
  const [newSongwriter, setNewSongwriter] = useState(songWriter);
  const [proAffiliations, setProAffiliations] = useState<Array<ProAffiliation>>(
    []
  );
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    API.get(ApiUri.PRO_AFFILIATION).then((response) => {
      const unsortedArray = response.data.collection;
      const sortedArray = unsortedArray.sort((a, b) => {
        if (a.name_code === "NS") return -1; // "NS" comes first
        if (b.name_code === "NS") return 1; // "NS" comes first

        // For other cases, sort alphabetically based on name_code
        return a.name_code.localeCompare(b.name_code);
      });

      setProAffiliations(sortedArray);
    });
  }, []); // eslint-disable-line

  const handleSave = () => {
    startGlobalLoader();
    const shareString = newSongwriter.share;
    const convertedShare = parseFloat(parseFloat(shareString).toFixed(2));
    newSongwriter.share = convertedShare;
    API.put(ApiUri.SONG_WRITER + "/" + songWriter.id, newSongwriter).then(
      (response) => {
        saveSongwriterCallback(newSongwriter, songWriter);
        stopGlobalLoader();
        // initSnackbarSuccess("Songwriter data successfully stored");
      },
      (err) => {
        setNewSongwriter((previousState) => ({
          ...previousState,
          share: 0,
        }));
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  };

  const isFormValid =
    (newSongwriter.music_contribution || newSongwriter.lyrics_contribution) &&
    (newSongwriter.pro_affiliation || !newSongwriter.has_cae_number) &&
    (newSongwriter.songwriter_cae_number || !newSongwriter.has_cae_number) &&
    // newSongwriter.publisher_cae_number &&
    newSongwriter.share;
  // && newSongwriter.publisher_name;

  return (
    <React.Fragment>
      <Paper elevation={6} sx={{ p: 4 }}>
        {index > 0 && (
          <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
            <ConfirmationDialogActivator
              onClick={() => deleteSongwriterCallback(index)}
              type="fab"
              icon={<Delete />}
              actionName="Delete Songwriter"
              content="Are you sure you want to delete this songwriter? This action can't be reversed."
              style={{ marginLeft: 10 }}
            />
          </Stack>
        )}
        <Grid container spacing={4}>
          <ContributionsCheckboxes
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
            showLyricsContribution={showLyricsContribution}
          />
          <PublisherInfoFields
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
          />
          <SongwriterCaeNumberField
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
          />
          <ProAfilitiationField
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
            proAffiliations={proAffiliations}
          />
          <ShareField
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
          />
          <Grid item xs={12}>
            <ActionButtonSmall disabled={!isFormValid} onClick={handleSave}>
              Save Songwriter
            </ActionButtonSmall>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default SongwriterForm;
