import { Info } from "@mui/icons-material";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { InitialSongwriter } from "./NewSongwriterForm";

interface ContributionsCheckboxes {
  newSongwriter: SongWriter | InitialSongwriter;
  setNewSongwriter:
    | React.Dispatch<React.SetStateAction<SongWriter>>
    | React.Dispatch<React.SetStateAction<InitialSongwriter>>;
  showLyricsContribution: boolean;
}

const ContributionsCheckboxes: React.FC<ContributionsCheckboxes> = ({
  newSongwriter,
  setNewSongwriter,
  showLyricsContribution,
}) => {
  const handleChange = (event) => {
    const { name } = event.target;

    switch (name) {
      case "music_contribution":
        setNewSongwriter((previousState) => ({
          ...previousState,
          music_contribution: !newSongwriter.music_contribution,
        }));
        break;
      case "lyrics_contribution":
        setNewSongwriter((previousState) => ({
          ...previousState,
          lyrics_contribution: !newSongwriter.lyrics_contribution,
        }));
        break;
    }
  };

  return (
    <Grid item xs={12}>
      <Stack direction={"row"} alignItems={"center"}>
        <InputLabel style={{ fontSize: 12, marginRight: 12 }} required>
          Contributions
        </InputLabel>
        <Tooltip
          title="Select whether the songwriter contributed to the music, lyrics or both"
          placement="top-end"
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={<Info color="disabled" />}
          />
        </Tooltip>
      </Stack>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={newSongwriter.music_contribution || false}
              onChange={handleChange}
              name="music_contribution"
              color="primary"
            />
          }
          label="Music"
        />
        {showLyricsContribution && (
          <FormControlLabel
            control={
              <Checkbox
                checked={newSongwriter.lyrics_contribution || false}
                onChange={handleChange}
                name="lyrics_contribution"
                color="primary"
              />
            }
            label="Lyrics"
          />
        )}
      </FormGroup>
    </Grid>
  );
};

export default ContributionsCheckboxes;
