import { FormControl, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { InitialSongwriter } from "./NewSongwriterForm";

interface ExternalSongwriterInfoProps {
  newSongwriter: InitialSongwriter;
  setNewSongwriter: React.Dispatch<React.SetStateAction<InitialSongwriter>>;
  usingExistingSongwriter: boolean;
}

const ExternalSongwriterInfo: React.FC<ExternalSongwriterInfoProps> = ({
  newSongwriter,
  setNewSongwriter,
  usingExistingSongwriter,
}) => {
  const [name, setName] = useState({ firstName: "", lastName: "" });
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "external_song_writer.first_name") {
      setName((previousName) => ({ ...previousName, firstName: value }));
      const externalSongWriter = newSongwriter.external_song_writer;
      externalSongWriter.first_name = value;
      setNewSongwriter((previousState) => ({
        ...previousState,
        external_song_writer: externalSongWriter,
      }));
      return;
    }

    if (name === "external_song_writer.last_name") {
      setName((previousName) => ({ ...previousName, lastName: value }));
      const externalSongWriter = newSongwriter.external_song_writer;
      externalSongWriter.last_name = value;
      setNewSongwriter((previousState) => ({
        ...previousState,
        external_song_writer: externalSongWriter,
      }));
      return;
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            name="external_song_writer.first_name"
            value={name.firstName || ""}
            onChange={handleChange}
            required={true}
            disabled={usingExistingSongwriter}
            type="text"
            label="First Name"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            name="external_song_writer.last_name"
            value={name.lastName || ""}
            onChange={handleChange}
            required={true}
            disabled={usingExistingSongwriter}
            type="text"
            label="Last Name"
          />
        </FormControl>
      </Grid>
    </React.Fragment>
  );
};

export default ExternalSongwriterInfo;
