import React, { useContext } from "react";
import {
  Breadcrumbs as MaterialUIBreadcrumbs,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NavigationContext, INavigationContext } from "../context/navigation";
import Colors from "../enums/colors";

const useStyles = makeStyles(() => ({
  breadcrums: {
    marginBottom: 40,
  },
  breadcrumbItem: {
    fontSize: "14px",
    fontWeight: 700,
  },
}));

export default function Breadcrumbs() {
  const classes = useStyles();
  const { navigation } = useContext<INavigationContext>(NavigationContext);
  const openedPage = navigation.filter(
    (navigationPage: any) => navigationPage.open,
  )[0];
  console.log("🖥️ Active page", openedPage);

  const activeChild =
    openedPage && openedPage.children.length > 0
      ? openedPage.children.filter((child: any) => child.active)[0]
      : null;

  return openedPage && openedPage.name !== "Dashboard" ? (
    <MaterialUIBreadcrumbs separator="›" className={classes.breadcrums}>
      <Typography className={classes.breadcrumbItem}>
        {openedPage.name}
      </Typography>
      {activeChild ? (
        <Typography className={classes.breadcrumbItem}>
          {activeChild.name}
        </Typography>
      ) : null}
    </MaterialUIBreadcrumbs>
  ) : (
    <React.Fragment />
  );
}
