import React, { useContext, useEffect, useState } from "react";
import GeneralSettings from "./general-settings";
import { useParams } from "react-router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { Profile } from "../../../../models/profile/profile";
import ManageUsers from "./manage-users";
import H2 from "../../../../components/layout/titles/h2";
import SocialMedia from "./social-media";
import CommunicationSettings from "./communication-settings";
import { AuthContext } from "../../../../context/auth";
import Notes from "./notes";
import ChangeParent from "./change-parent";
import RoyaltySplits from "./royalty-splits";
import ProfileTypes from "../../../../enums/profile-types";
import RoleTypes from "../../../../enums/role-types";

export default function EditSubProfile() {
  const { id } = useParams();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const { user, userRole } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    let isMounted = true;
    if (profile && profile.id) {
      return;
    }

    startGlobalLoader();

    API.get(ApiUri.PROFILE + "/" + id).then(
      (response) => {
        if (isMounted) {
          setProfile(response.data);
          stopGlobalLoader();
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );

    return () => {
      isMounted = false;
    };
  }, [profile]); // eslint-disable-line

  const reload = () => {
    setProfile(null);
  };

  return profile ? (
    <React.Fragment>
      <H2>
        <Chip label="Profile" size="small" style={{ marginRight: "15px" }} />
        <b>{profile.profile_information.name}</b>
      </H2>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">General Settings</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <GeneralSettings profile={profile} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Payment Settings</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails></AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Manage Users</Typography>
        </AccordionSummary>
        <Divider />
        <ManageUsers profile={profile} reload={reload} />
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Communication Settings</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <CommunicationSettings profile={profile} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Social Media</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <SocialMedia profile={profile} />
        </AccordionDetails>
      </Accordion>

      {user?.is_mmpz_service_admin && (
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Notes</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Notes profile={profile} />
          </AccordionDetails>
        </Accordion>
      )}

      {user?.is_mmpz_service_admin && (
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Change Parent</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <ChangeParent profile={profile} />
          </AccordionDetails>
        </Accordion>
      )}

      {user?.is_mmpz_service_admin && (
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Splits</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <RoyaltySplits profile={profile} setProfile={setProfile} />
          </AccordionDetails>
        </Accordion>
      )}
    </React.Fragment>
  ) : null;
}
